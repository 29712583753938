import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const Label = styled.span`
  ${({ theme }) => theme.fontFamily.secondaryFont.smallBold};
  line-height: ${({ theme }) => theme.spacing(10)};
`;

const AvailabilityInfo = ({ label }) => {
  return (
    <Container>
      <Label>{label}</Label>
    </Container>
  );
};

AvailabilityInfo.propTypes = {
  label: PropTypes.string.isRequired,
};

export default AvailabilityInfo;
