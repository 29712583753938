import { defineMessages } from 'react-intl';

export default defineMessages({
  label: {
    id: 'app.needChange.inputs.needChangeInput.label',
    defaultMessage: 'Ingresá el monto',
  },
  helpText: {
    id: 'app.needChange.inputs.needChangeInput.helpText',
    defaultMessage: 'Opcional',
  },
  errorMessage: {
    id: 'app.needChange.inputs.needChangeInput.errorMessage',
    defaultMessage: 'El monto ingresado es inferior al valor del pedido',
  },
});
