import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card } from '@appscore/web-components';

const StyledCard = styled(Card)`
  margin-bottom: ${({ theme }) => `${theme.spacing(8)}`};
  padding: ${({ theme }) => `${theme.spacing(10)}`};
`;

const ListContainer = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  li:not(:last-child) {
    border-bottom: ${({ theme }) => theme.colors.platinum + ' 1px solid'};
    padding-bottom: ${({ theme }) => theme.spacing(10)};
    margin-bottom: ${({ theme }) => theme.spacing(10)};
  }
`;

function UserInstrumentsCard({ children }) {
  return (
    <StyledCard direction="column">
      <ListContainer>{children}</ListContainer>
    </StyledCard>
  );
}

UserInstrumentsCard.propTypes = {
  children: PropTypes.node,
};

export default UserInstrumentsCard;
