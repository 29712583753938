import { useMutation } from 'react-query';
import saveChallenge from '../saveChallenge';
import { createTokenForCard } from '../cardTokenization';
import addNewPaymentInstrument from '../addNewPaymentInstrument';
import deleteUserInstrument from '@app/services/deleteUserInstrument';
import saveChallengeByRequestId from '../saveChallengeByRequestId';
export const MUTATIONS_NAMES = {
  SAVE_CHALLENGE: 'SAVE_CHALLENGE',
  SAVE_CHALLENGE_BY_REQUEST_ID: 'SAVE_CHALLENGE_BY_REQUEST_ID',
  NEW_CARD_TOKENIZATION: 'NEW_CARD_TOKENIZATION',
  EXISTING_CARD_TOKENIZATION: 'EXISTING_CARD_TOKENIZATION',
  SUBMIT_NEW_CARD_FORM: 'SUBMIT_NEW_CARD_FORM',
  SUBMIT_NEW_CARD_EXTERNAL_FORM: 'SUBMIT_NEW_CARD_EXTERNAL_FORM',
  DELETE_INSTRUMENT: 'DELETE_INSTRUMENT',
};

export const useSaveChallenge = (challengeType) =>
  useMutation([MUTATIONS_NAMES.SAVE_CHALLENGE, challengeType], (challengeData) =>
    saveChallenge(challengeType, challengeData),
  );

export const useSaveChallengeByRequestId = (challengeType, requestId) =>
  useMutation([MUTATIONS_NAMES.SAVE_CHALLENGE_BY_REQUEST_ID, challengeType, requestId], () =>
    saveChallengeByRequestId(challengeType, requestId),
  );

export const useNewCardTokenization = (vaultProvider) =>
  useMutation([MUTATIONS_NAMES.NEW_CARD_TOKENIZATION, vaultProvider], (tokenizationPayload) =>
    createTokenForCard(vaultProvider, tokenizationPayload),
  );

export const useSubmitNewDebitOrCreditCard = (vaultProvider) =>
  useMutation([MUTATIONS_NAMES.SUBMIT_NEW_CARD_FORM, vaultProvider], (newCardFormData) =>
    addNewPaymentInstrument(vaultProvider, newCardFormData),
  );

export const useSubmitNewCardExternal = () =>
  useMutation(
    [MUTATIONS_NAMES.SUBMIT_NEW_CARD_EXTERNAL_FORM],
    ({ vaultProvider, newCardFormData }) => addNewPaymentInstrument(vaultProvider, newCardFormData),
  );

export const useDeleteUserInstrument = (instrumentId) =>
  useMutation([MUTATIONS_NAMES.DELETE_INSTRUMENT, instrumentId], (instrumentId) =>
    deleteUserInstrument(instrumentId),
  );
