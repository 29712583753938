import { sessionCache, KEYS as CACHE_KEYS } from '../../../services/cache';
import { ACTION_TYPE } from '../actionsType';
import { STATE_KEYS } from '../constants';

export const updateSecurityCode = (dispatch, { challengeId, instrumentId }) => {
  sessionCache.set(CACHE_KEYS.SECURITY_CODE, { challengeId, instrumentId });
  dispatch({
    type: ACTION_TYPE.UPDATE_SECURITY_CODE,
    payload: { [STATE_KEYS.SECURITY_CODE]: { challengeId, instrumentId } },
  });
};
