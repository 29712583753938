import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { goBack } from '../providers/mainContext/actions/goBack';
import { useAppCallbacks } from '../providers/mainContext/selectors/useAppCallbacks';
import { useAppDispatch } from '../providers/mainContext/selectors/useAppDispatch';
import { useCallbackNavigation } from './useCallbackNavigation';
import { useIsEntryPointPage } from './useIsEntryPointPage';
import { useConsumerIsMicrosite } from './useMicrositeConsumer';

export const useGoBack = () => {
  const navigate = useNavigate();
  const { callbackNavigate } = useCallbackNavigation();
  const isEntryPointPage = useIsEntryPointPage();
  const { cancelCallback } = useAppCallbacks();
  const dispatch = useAppDispatch();
  const isConsumedByMicrosite = useConsumerIsMicrosite();

  const goBackCallback = useCallback(() => {
    goBack(
      navigate,
      callbackNavigate,
      cancelCallback,
      dispatch,
      isEntryPointPage,
      isConsumedByMicrosite,
    );
  }, [
    navigate,
    callbackNavigate,
    cancelCallback,
    dispatch,
    isEntryPointPage,
    isConsumedByMicrosite,
  ]);

  return { goBack: goBackCallback };
};
