import { defineMessages } from 'react-intl';

export default defineMessages({
  submitErrorTitle: {
    id: 'app.newCardExternalSuccessCallback.submitError.title',
    defaultMessage: 'No pudimos agregar tu tarjeta',
  },
  submitErrorDescription: {
    id: 'app.newCardExternalSuccessCallback.submitError.description',
    defaultMessage: 'Inténtalo nuevamente.',
  },
  submitErrorActionLabel: {
    id: 'app.newCardExternalSuccessCallback.submitError.actionLabel',
    defaultMessage: 'Agregar tarjeta',
  },
});
