import logger from '@app/logger';

const iOSMessage = {
  command: 'webview-event',
  event: 'changeKeyboardBehavior',
};

const publishiOS = () =>
  window.webkit?.messageHandlers?.ListenEventsInterface?.postMessage({ ...iOSMessage });

export const publishAndroid = () => {
  if (window.LoaderInterface?.changeKeyboardBehaviorEvents)
    window.LoaderInterface?.changeKeyboardBehaviorEvents();
};

export const publishEventsOnDevice = () => {
  logger.debug('[PUBLISH_EVENTS_ON_DEVICE]', 'Publishing change keyboard behavior events');
  publishiOS();
};

export const publishChangeKeyboardBehaviorEvents = () => {
  logger.debug('[PUBLISH_CHANGE_KEYBOARD_BEHAVIOR_EVENTS]');
  publishiOS();
  publishAndroid();
};
