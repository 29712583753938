import BackendDrivenPage from '@app/components/BackendDrivenPage';
import { BrandErrorContainer } from '@app/components/Error';
import Spinner from '@app/components/Spinner';
import Text from '@app/components/Text/Text';
import { useGoBack } from '@app/hooks/useGoBack';
import logger from '@app/logger';
import { updateNeedChange } from '@app/providers/mainContext/actions/updateNeedChange';
import { useAppDispatch } from '@app/providers/mainContext/selectors/useAppDispatch';
import { useAppNeedChange } from '@app/providers/mainContext/selectors/useAppNeedChange';
import { useNeedChangeScreen } from '@app/services/queries';
import { useQueryParamValue } from '@app/utils/domRouter';
import { SimpleButton } from '@appscore/web-components';
import { useBFFActionsExecutor } from '@checkout-ui/backend-driven';
import { QUERY_PARAM } from '@commons/constants';
import React, { useState } from 'react';
import NeedChangeInput from './components/NeedChangeInput';

const DEFAULT_CURRENCY_SYMBOL = '$';

const NeedChange = () => {
  const dispatch = useAppDispatch();
  const { amountValue: amountValueFromContext } = useAppNeedChange();
  const [amountValue, setAmountValue] = useState(amountValueFromContext);
  const [amountValueError, setAmountValueError] = useState(false);
  const totalAmount = useQueryParamValue(QUERY_PARAM.TOTAL_AMOUNT_NEED_CHANGE);
  const currencySymbol = useQueryParamValue(QUERY_PARAM.CURRENCY_SYMBOL_NEED_CHANGE);
  const { data, error, isLoading, refetch } = useNeedChangeScreen(totalAmount, currencySymbol);

  const { goBack } = useGoBack();

  const onSubmit = () => {
    updateNeedChange(dispatch, { amountValue, currencySymbol });
    logger.debug(
      '[NEED_CHANGE][AMOUNT_SAVE_SUCCESS]',
      `Need change amount saved successfully: ${
        amountValue !== ''
          ? `${currencySymbol || DEFAULT_CURRENCY_SYMBOL}${amountValue}`
          : 'No amount defined'
      }`,
    );
    goBack();
  };

  const isInvalidAmount = (value, total) => parseFloat(value) < parseFloat(total);

  const onChangeAmountValue = (value) => {
    if (value) setAmountValueError(isInvalidAmount(value, totalAmount));
    else setAmountValueError(false);
    setAmountValue(value);
  };

  const actionDefinitions = {
    SAVE_CHANGE_VALUE: onSubmit,
  };
  const { executeBFFActions } = useBFFActionsExecutor(actionDefinitions);

  const componentResolvers = {
    TEXT: ({ data: { value, style } }) => ({
      component: Text,
      props: {
        value,
        style,
      },
    }),
    NEED_CHANGE_INPUT: ({ data: { style, prefix } }) => ({
      component: NeedChangeInput,
      props: {
        value: amountValue,
        onChange: (value) => onChangeAmountValue(value),
        error: amountValueError,
        prefix,
        ...style,
      },
    }),

    // TODO: if there is more than one button in the footer
    // we need a way to identify them in order to apply different behaviour
    BUTTON: ({ data: { style, text, actions } }) => ({
      component: SimpleButton,
      props: {
        disabled: amountValueError,
        size: 'full',
        label: text,
        color: style.toLowerCase(),
        onClick: () => executeBFFActions(actions),
      },
    }),
  };

  if (isLoading) return <Spinner />;
  if (error) {
    return (
      <BrandErrorContainer
        primaryLabel="Intentar nuevamente"
        onPrimaryAction={refetch}
        secondaryLabel="Volver"
        onSecondaryAction={() => goBack()}
        errorCode={error.response?.status}
      />
    );
  }

  const header = { ...data?.bar, isOnlyAction: true };
  const components = data?.components;
  const footer = { ...data?.footer?.data };

  return (
    <BackendDrivenPage
      fenixLayout={false}
      componentResolvers={componentResolvers}
      header={header}
      components={components}
      footer={footer}
    />
  );
};

export default NeedChange;
