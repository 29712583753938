import styled from 'styled-components';
import { Image, RadioButton } from '@appscore/web-components';

export const InstrumentItemContainer = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledRadioButton = styled(RadioButton)`
  margin-right: ${({ theme }) => theme.spacing(8)};
`;

export const InstrumentLogo = styled(Image)`
  border-radius: 6px;
  border: ${({ theme }) => `1px solid ${theme.colors.platinum}`};
  margin-right: ${({ theme }) => theme.spacing(6)};
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  ${({ theme }) => theme.fontFamily.secondaryFont.mediumRegular}
  color: ${({ theme }) => theme.colors.night_blue_1};
`;

export const Subtitle = styled.span`
  ${({ theme }) => theme.fontFamily.secondaryFont.xSmallRegular}
  color: ${({ theme }) => theme.colors.night_blue_2};
`;

export const InfoInstrumentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoInstrumentMessage = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const StyledDescription = styled(Description)`
  justify-content: center;
`;

export const StyledTitle = styled(Title)`
  line-height: ${({ theme }) => theme.spacing(10)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const StyledSubtitle = styled(Subtitle)`
  line-height: ${({ theme }) => theme.spacing(8)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const Aside = styled.div`
  display: flex;
  align-items: center;
  opacity: ${({ enabled }) => (enabled ? 1 : 0.5)};
`;

export { default as Promo } from './Promo';
export { default as AvailabilityInfo } from './AvailabilityInfo';
export { default as ActionContainer } from './ActionContainer';
export { default as InfoInstrument } from './InfoInstrument';
export { default as DeleteActionContainer } from './DeleteActionContainer';
