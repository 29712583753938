import BackendDrivenPage from '@app/components/BackendDrivenPage';
import { useBFFActionsExecutor, useBFFNavigate } from '@checkout-ui/backend-driven';
import stringify from '@commons/utils/object/stringify';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { QUERY_PARAM } from '@commons/constants';
import ChangeBodyBackgroundColor from '@app/components/ChangeBodyBackgroundColor';
import { BrandErrorContainer } from '@app/components/Error';
import { useGoBack } from '@app/hooks/useGoBack';
import { useShowDialog } from '@app/hooks/useShowDialog';
import logger from '@app/logger';
import { BFF_ACTION_TYPES } from '@app/providers/mainContext/constants';
import { useSaveChallenge } from '@app/services/mutations';
import { useChallengeScreen } from '@app/services/queries';
import { useQueryParamValue } from '@app/utils/domRouter';
import TigoOTPInput from './components/TigoOTPInput';
import Button from '@pedidosya/web-fenix/atoms/Button';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import messages from './messages';
import { useDispatchDeviceEvents } from '@app/hooks/useDispatchDeviceEvents';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
import { CHALLENGE_TYPES } from '../../constants';
import { ERROR_CODE_TRACKING, EVENT_NAME_TRACKING, actionTracking } from '@app/utils/tracking';
import useOnce from '@app/hooks/useOnce';
import { publishChangeKeyboardBehaviorEvents } from '@app/utils/changeKeyboardBehaviorEvents';

const CHALLENGE_TYPE_TIGO_OTP = 'TIGO_OTP';
const OTP_LENGTH = 6;
const Text = ({ value }) => <Typography token="font-label-midcontrast-medium">{value}</Typography>;

const TigoOTPChallenge = () => {
  const { navigate } = useBFFNavigate();
  const { formatMessage } = useIntl();
  const [otpValue, setOtpValue] = useState('');
  const challengeId = useQueryParamValue(QUERY_PARAM.CHALLENGE_ID);
  const { data, error, isLoading, refetch } = useChallengeScreen(
    CHALLENGE_TYPE_TIGO_OTP,
    challengeId,
  );
  const {
    isLoading: submittingChallenge,
    isSuccess: submitSuccessChallenge,
    reset: resetSubmitChallenge,
    mutate: submitChallenge,
  } = useSaveChallenge(CHALLENGE_TYPE_TIGO_OTP);
  const { goBack } = useGoBack();
  const { showDialog: showErrorDialog } = useShowDialog();

  // COMMENT: Keep this useEffect at the beginning to avoid scrolling in iOS devices.
  useOnce(() => publishChangeKeyboardBehaviorEvents());

  const onSubmitError = () => {
    resetSubmitChallenge();
    const dismissErrorDialogAction = {
      type: BFF_ACTION_TYPES.DISMISS_DIALOG,
    };
    showErrorDialog({
      fenixLayout: true,
      title: formatMessage(messages.submitErrorTitle),
      description: formatMessage(messages.submitErrorDescription),
      primary_action: {
        label: formatMessage(messages.submitErrorActionLabel),
        actions: [dismissErrorDialogAction],
      },
    });
  };

  const onSubmit = (payload) => {
    logger.info('[TIGO_OTP_CHALLENGE][OTP_SAVE_INTENT]', 'Trying to save OTP', stringify(payload));
    submitChallenge(
      { otp_value: otpValue, ...payload },
      {
        onSuccess: (data) => {
          const actions = data?.actions;
          if (actions?.length) {
            logger.info(
              '[TIGO_OTP_CHALLENGE][OTP_SAVE_SUCCESS]',
              'OTP was saved successfully',
              stringify(actions),
            );
            executeBFFActions(actions);
          } else {
            executeBFFActions(
              actionTracking(EVENT_NAME_TRACKING.CHALLENGE_FAILED, {
                challengeType: CHALLENGE_TYPES.TIGO_OTP.toLowerCase(),
                errorCode: ERROR_CODE_TRACKING.SUBMIT_ERROR,
              }),
            );
            logger.error(
              '[TIGO_OTP_CHALLENGE][OTP_SAVE_NO_ACTION]',
              'Submit Tigo OTP response do not have actions',
            );
            onSubmitError();
          }
        },
        onError: (error) => {
          executeBFFActions(
            actionTracking(EVENT_NAME_TRACKING.CHALLENGE_FAILED, {
              challengeType: CHALLENGE_TYPES.TIGO_OTP.toLowerCase(),
              errorCode: ERROR_CODE_TRACKING.SUBMIT_ERROR,
            }),
          );
          logger.error('[TIGO_OTP_CHALLENGE][OTP_SAVE_ERROR]', 'Fail saving OTP:', error.message);
          onSubmitError();
        },
      },
    );
  };

  const actionDefinitions = {
    SUBMIT_TIGO_OTP_CHALLENGE: onSubmit,
    [BFF_ACTION_TYPES.NAVIGATE]: (payload) => {
      // NOTE: replace navigation in order to prevent user going back to previous submitted challenges
      navigate(payload, { replace: true });
    },
  };
  const { executeBFFActions } = useBFFActionsExecutor(actionDefinitions);

  const componentResolvers = {
    TEXT: ({ data: { value } }) => ({
      component: Text,
      props: {
        value,
      },
    }),
    TIGO_OTP_INPUT: ({ data: { style } }) => ({
      component: TigoOTPInput,
      props: {
        value: otpValue,
        totalNumberOfInputs: OTP_LENGTH,
        onChange: (v) => setOtpValue(v),
        ...style,
      },
    }),

    // TODO: if there is more than one button in the footer
    // we need a way to identify them in order to apply different behaviour
    BUTTON: ({ data: { text, actions } }) => {
      const isDisabled =
        otpValue.length !== OTP_LENGTH || submittingChallenge || submitSuccessChallenge;
      return {
        component: Button,
        props: {
          state: isDisabled ? 'disabled' : 'enabled',
          disabled: isDisabled,
          size: 'large',
          label: text,
          fullWidth: true,
          onClick: () => executeBFFActions(actions),
        },
      };
    },
  };

  useDispatchDeviceEvents();

  if (isLoading) return <PeyaLoader position="center" />;
  if (error) {
    return (
      <BrandErrorContainer
        fenixLayout
        onPrimaryAction={refetch}
        secondaryLabel="Volver"
        onSecondaryAction={() => goBack()}
        errorCode={error.response?.status}
      />
    );
  }

  const onAppearPageActions = data?.on_appear?.actions;
  const header = {
    ...data?.bar,
    isOnlyAction: true,
  };
  const components = data?.components;
  const footer = { ...data?.footer?.data };

  return (
    <>
      <ChangeBodyBackgroundColor color="white" />
      <BackendDrivenPage
        onAppearActions={onAppearPageActions}
        componentResolvers={componentResolvers}
        components={components}
        header={header}
        footer={footer}
      />
    </>
  );
};

export default TigoOTPChallenge;
