import { useLocation } from 'react-router-dom';
import querystring from 'query-string';

export function usePathname() {
  return useLocation().pathname;
}

export function useQuery() {
  return useLocation().search;
}

export const useQueryParamValue = (key) => {
  const search = useQuery();
  return new URLSearchParams(search).get(key);
};

export const getQueryParam = (location, key) => {
  const search = location.search;
  return new URLSearchParams(search).get(key);
};

export function getQuery(location) {
  return location.search;
}

export function getQueryAsObject(location) {
  return querystring.parse(getQuery(location));
}

export function useHash() {
  const { hash } = useLocation();
  const containsHashChar = hash.charAt(0) === '#';
  return containsHashChar ? hash.slice(1, hash.length) : hash;
}

export function useState() {
  return useLocation().state || {};
}
