import { defineMessages } from 'react-intl';

export default defineMessages({
  label: {
    id: 'app.paymentMethod.inputs.cardExpirationInput.label',
    defaultMessage: 'Expiración (MM/AA)',
  },
  errorMessage: {
    id: 'app.paymentMethod.inputs.cardExpirationInput.errorMessage',
    defaultMessage: 'La fecha ingresada no es válida',
  },
});
