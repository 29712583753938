import { BackendDrivenComponent, useBFFActionsExecutor } from '@checkout-ui/backend-driven';
import PropTypes from 'prop-types';
import React from 'react';
import Link from '../Link';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

const Paragraph = ({ children }) => <Typography component="span">{children}</Typography>;

const Span = ({ value, token, color }) => (
  <Typography component="span" token={token} color={color}>
    {value}
  </Typography>
);

const BlockText = ({ components, style: { color: blockColor } }) => {
  const { executeBFFActions } = useBFFActionsExecutor();

  const componentResolvers = {
    TEXT: ({
      data: {
        value,
        style: { token, color },
      },
    }) => ({
      component: Span,
      props: {
        value,
        color,
        token,
      },
    }),
    LINK: ({ data: { value, style, actions } }) => ({
      component: Link,
      props: {
        value,
        style,
        onClick: () => executeBFFActions(actions),
      },
    }),
  };

  return (
    <Paragraph color={blockColor}>
      <BackendDrivenComponent componentResolvers={componentResolvers} components={components} />
    </Paragraph>
  );
};

BlockText.defaultProps = {
  style: {
    color: 'inherit',
  },
};

BlockText.propTypes = {
  components: PropTypes.arrayOf(PropTypes.object).isRequired,
  style: PropTypes.shape({
    color: PropTypes.string,
  }),
};

export default BlockText;
