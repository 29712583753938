import { BFF_INSTRUMENT_TYPES } from '@app/constants/bffComponents';
import { BFF_ACTION_TYPES } from '@app/providers/mainContext/constants';
import logger from '@app/logger';
import { getQueryAsObject } from '@app/utils/domRouter';

export const isInstrumentSelected = (selectedInstruments, id) =>
  selectedInstruments?.some(({ id: selectedId }) => selectedId === id) || false;

export const isInstrumentTypeSelected = (selectedInstruments, types) =>
  selectedInstruments?.some(({ type }) => types.includes(type)) || false;

export const filterInstrumentsByType = (selectedInstruments, types = []) =>
  selectedInstruments?.filter(({ type: selectedType }) => !types.includes(selectedType));

export const isInstrumentTypeDuplicated = (instruments) => {
  const types = instruments.map((item) => item.type);
  return types.filter((item, index) => types.indexOf(item) !== index).length > 0;
};

export const isOfflineInstrumentSelected = (instruments) =>
  instruments?.some(({ type }) => type === BFF_INSTRUMENT_TYPES.OFFLINE_INSTRUMENT) || false;

export const isPartialWalletSelected = (instruments) =>
  instruments?.some(({ type }) => type === BFF_INSTRUMENT_TYPES.PARTIAL_WALLET) || false;

export const isOnlineInstrumentSelected = (instruments) =>
  instruments?.some(({ type }) => type === BFF_INSTRUMENT_TYPES.ONLINE_INSTRUMENT) || false;

export const isDisableInstrumentSelected = (instruments) =>
  instruments?.some(({ enabled }) => typeof enabled !== 'undefined' && !enabled) || false;

export const isValidInstrumentSelection = (instruments) => {
  const isDisableSelected = isDisableInstrumentSelected(instruments);
  const isOnlineSelected = isOnlineInstrumentSelected(instruments);
  const isOfflineSelected = isOfflineInstrumentSelected(instruments);
  const isWalletSelected = isPartialWalletSelected(instruments);
  // 1. enabled: false -> INVALID
  if (isDisableSelected) return false;
  // 2. Online + Offline -> INVALID
  if (isOnlineSelected && isOfflineSelected) return false;
  // 3. Wallet + Offline -> INVALID
  if (isWalletSelected && isOfflineSelected) return false;
  // 4. Two or more same type -> INVALID
  if (isInstrumentTypeDuplicated(instruments)) return false;
  return true;
};

/**
 Due to we don't have instrument's type within 'data' field (online,offline, wallet),
 we get this info from BFF_COMPONENT_TYPES (ONLINE_INSTRUMENT, OFFLINE_INSTRUMENT, PARTIAL_WALLET),
 to do this we iterate recursively the BFF response.
 **/
export const getInstrumentsFromBFF = ({ componentsToFilter = [] }) =>
  componentsToFilter.reduce((accum, { type, data }) => {
    if (data.components) {
      const result = getInstrumentsFromBFF({ componentsToFilter: data.components });
      return [...accum, ...result];
    }

    if (Object.keys(BFF_INSTRUMENT_TYPES).includes(type)) {
      return [...accum, { id: data.id, type: BFF_INSTRUMENT_TYPES[type], enabled: data.enabled }];
    }
    return accum;
  }, []);

export const parseInstrumentsFromQuery = (instrumentsFromQuery) => {
  try {
    const selectedInstrumentsQuery = JSON.parse(decodeURI(instrumentsFromQuery));
    if (selectedInstrumentsQuery) {
      return selectedInstrumentsQuery;
    }
  } catch (e) {
    logger.error(
      '[PARSE_INSTRUMENTS_FROM_QUERY]',
      `Fail decoding/parsing instrument from query: ${instrumentsFromQuery}`,
      e.message,
    );
  }

  return [];
};

export const addTypesToInstruments = ({ instrumentsFromBFF, instrumentsWithoutType }) =>
  instrumentsWithoutType
    .map(({ id: selectedInstrumentId }) =>
      instrumentsFromBFF.find(({ id }) => selectedInstrumentId === id),
    )
    .filter((instrument) => instrument !== undefined);

export const isSelectedInstrumentDeleted = ({ instrumentsFromBFF, instrumentsFromQuery }) => {
  const filterOnlineInstruments = (instruments) =>
    instruments.reduce((prev, { type, id }) => {
      if (type !== BFF_INSTRUMENT_TYPES.ONLINE_INSTRUMENT) return prev;
      return [...prev, id];
    }, []);

  const onlineAvailableInstrumentsIds = filterOnlineInstruments(instrumentsFromBFF);

  const onlineInstrumentsFromQueryIds = filterOnlineInstruments(instrumentsFromQuery);

  const isAnyInstrumentDeleted = onlineInstrumentsFromQueryIds.some(
    (id) => !onlineAvailableInstrumentsIds.includes(id),
  );
  return isAnyInstrumentDeleted;
};

const domainsRegex = {
  DONATIONS: /https:\/\/web-apps\.pedidosya\.com/g,
  WEB_RESTAURANT: /https:\/\/www\.pedidosya\.com/g,
  LOYALTY: /https:\/\/loyalty\.pedidosya\.com/g,
};

const origins = {
  DONATIONS: 'donations',
  CHECKOUT: 'checkout',
  LOYALTY_SUB: 'loyalty_subscription',
  LOYALTY_CHANGE_INS: 'loyalty_change_instrument',
  NOT_MAPPED: 'not_mapped',
};

/**
 * This function determines the origin of the current view
 * @returns {String} origin
 */
export const getOrigin = () => {
  const domain = window?.location?.origin;
  const queryParams = getQueryAsObject(window?.location);

  if (domainsRegex.DONATIONS.test(domain)) {
    return origins.DONATIONS;
  }
  if (domainsRegex.WEB_RESTAURANT.test(domain)) {
    // Check purchaseId param format
    if (/^PUR-/g.test(queryParams?.purchaseId)) return origins.CHECKOUT;
    return origins.LOYALTY_SUB;
  }
  if (domainsRegex.LOYALTY.test(domain)) {
    // Check action query param
    if (queryParams.action === 'CHANGE_PAYMENT_INSTRUMENT') return origins.LOYALTY_CHANGE_INS;
    if (!queryParams.action) return origins.LOYALTY_SUB;
  }
  return origins.NOT_MAPPED;
};

/**
 * This function adds the origin attribute to the given eventName
 * @returns {Array} modifiedActions
 */
export const addOriginToTrackingEvent = (actions, eventName) => {
  const origin = getOrigin();
  if (Array.isArray(actions) && eventName) {
    return actions.map((action) => {
      if (action?.type === BFF_ACTION_TYPES.TRACKING && action?.data?.event === eventName)
        return {
          ...action,
          data: {
            ...action.data,
            attributes: {
              ...action.data.attributes,
              origin: origin,
            },
          },
        };
      return action;
    });
  }

  return actions;
};
