import { defineMessages } from 'react-intl';

export default defineMessages({
  submitErrorTitle: {
    id: 'app.paymentMethod.cvvChallenge.submitError.title',
    defaultMessage: 'No pudimos procesar tu pago',
  },
  submitErrorDescription: {
    id: 'app.paymentMethod.cvvChallenge.submitError.description',
    defaultMessage: 'Volvé a intentarlo para terminar tu compra.',
  },
  submitErrorActionLabel: {
    id: 'app.paymentMethod.cvvChallenge.submitError.actionLabel',
    defaultMessage: 'Reintentar',
  },
  dismissErrorActionLabel: {
    id: 'app.paymentMethod.cvvChallenge.dismissError.actionLabel',
    defaultMessage: 'Volver',
  },
});
