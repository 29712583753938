import { ArrowRight, Card, Image } from '@appscore/web-components';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Text = styled.p`
  ${({ theme }) => theme.fontFamily.secondaryFont.mediumRegular}
  color: ${({ theme }) => theme.colors.night_blue_1};
  line-height: 20px;
  margin: 0px;
`;

const Description = styled.p`
  ${({ theme }) => theme.fontFamily.secondaryFont.xSmallRegular}
  color: ${({ theme }) => theme.colors.night_blue_2};
  line-height: 16px;
  margin: 4px 0px 0px 0px;
`;

const Logo = styled(Image)`
  max-width: ${({ width }) => `${width}px`};
  border-radius: 6px;
  border: ${({ theme }) => `1px solid ${theme.colors.platinum}`};
  margin-top: 5px;
`;

const StyledArrowRight = styled(ArrowRight)`
  min-width: 24px;
  margin: 0px 20px 0px auto;
`;

const StyledCard = styled(Card)`
  align-items: center;
  min-height: 56px;
  filter: ${({ enabled }) => `grayscale(${enabled ? 0 : 0.9})`};
  opacity: ${({ enabled }) => (enabled ? 1 : 0.5)};
`;

const TextCard = styled(Card)`
  height: 100%;
  align-items: start;
  justify-content: center;
`;

const PaymentMethodCard = ({ className, text, leftIcon, enabled, description, onClick }) => {
  return (
    <StyledCard
      enabled={enabled}
      className={className}
      direction="row"
      onClick={enabled ? onClick : undefined}
    >
      <Logo alt="card-logo" src={leftIcon} height={32} width={48} />
      <TextCard direction="column" transparent={true}>
        <Text>{text}</Text>
        {description ? <Description>{description}</Description> : null}
      </TextCard>
      <StyledArrowRight size="medium" />
    </StyledCard>
  );
};

PaymentMethodCard.propTypes = {
  text: PropTypes.string.isRequired,
  leftIcon: PropTypes.string.isRequired,
  enabled: PropTypes.bool,
  description: PropTypes.string,
  onClick: PropTypes.func,
};

PaymentMethodCard.defaultProps = {
  enabled: true,
};

export default PaymentMethodCard;
