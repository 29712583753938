import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useGoBack } from '@app/hooks/useGoBack';
import ChevronLeft from '@pedidosya/web-fenix/icons/ChevronLeft';
import FixedHeader from '../../components/FixedHeader';
import { useTermsAndConditionsDetail } from '@app/services/queries';
import messages from './messages';
import { BFF_ACTION_TYPES } from '@app/providers/mainContext/constants';
import { useShowDialog } from '@app/hooks/useShowDialog';
import { useQueryParamValue } from '@app/utils/domRouter';
import { QUERY_PARAM } from '@commons/constants';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';

const Iframe = styled.iframe`
  width: 100%;
  height: 100vh;
  border: unset;
`;

function TermsAndConditionsDetail() {
  const { formatMessage } = useIntl();
  const { goBack } = useGoBack();
  const url = useQueryParamValue(QUERY_PARAM.URL);
  const { showDialog: showErrorDialog } = useShowDialog();

  const { data, isLoading, isError } = useTermsAndConditionsDetail(url);
  const [iframeLoading, setIframeLoading] = useState(isLoading);

  useEffect(() => {
    if (isError) {
      setIframeLoading(false);
      const dismissErrorDialogAction = {
        type: BFF_ACTION_TYPES.DISMISS_DIALOG,
      };
      const goBackAction = {
        type: BFF_ACTION_TYPES.NAVIGATE_BACK,
      };
      showErrorDialog({
        fenixLayout: true,
        title: formatMessage(messages.errorTitle),
        description: formatMessage(messages.errorSubtitle),
        primary_action: {
          label: formatMessage(messages.errorActionLabel),
          actions: [dismissErrorDialogAction, goBackAction],
        },
      });
    }
  }, [isError]);

  return (
    <>
      <FixedHeader
        action={
          <span role="button" tabIndex={0} title="close" onClick={() => goBack()}>
            <ChevronLeft size="medium" />
          </span>
        }
      >
        {formatMessage(messages.header)}
      </FixedHeader>
      {data && !isError ? (
        <Iframe
          title="Terms And Conditions Detail"
          src={url}
          onLoadCapture={() => setIframeLoading(false)}
        />
      ) : null}
      {(isLoading && !isError) || iframeLoading ? <PeyaLoader position="center" /> : null}
    </>
  );
}

export default TermsAndConditionsDetail;
