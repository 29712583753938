import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { InputText } from '@appscore/web-components';
import messages from './messages';
import formatCardSecurityCode from '../utils/formatters/cardSecurityCode';
import { SecurityCodeHelp } from '@app/components/SecurityCodeHelp';

const InputContainer = styled.div`
  display: flex;
  align-items: baseline;
  width: ${({ width }) => width};
`;

const StyledInputText = styled(InputText)`
  flex-grow: 1;
`;

const CardSecurityCodeInput = ({ value, onChange, error = false, disabled = false, ...rest }) => {
  const isValid = () => {
    if (!value) return true;

    return !error;
  };

  const handleChange = (event) => {
    const inputValue = event?.target?.value;
    onChange(formatCardSecurityCode(inputValue));
  };

  const intl = useIntl();
  const { formatMessage } = intl;
  const { width } = rest;

  const errorMessage = isValid() ? '' : formatMessage(messages.errorMessage);
  return (
    <InputContainer width={width}>
      <StyledInputText
        {...rest}
        type="tel"
        label={formatMessage(messages.label)}
        onChange={handleChange}
        value={value}
        helpText={errorMessage}
        error={Boolean(errorMessage)}
        disabled={disabled}
        inputProps={{
          'data-testid': rest?.name || 'card-security-code-input',
        }}
      />
      {!disabled ? <SecurityCodeHelp /> : null}
    </InputContainer>
  );
};

CardSecurityCodeInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default CardSecurityCodeInput;
