import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import InformativeRowList from '@pedidosya/web-fenix/molecules/InformativeRowList';
import FenixIcon from '@pedidosya/web-fenix/icons/FenixIcon';

const Container = styled.div`
  align-self: flex-start;
`;

const RowList = ({ icon, title, subtitle }) => {
  return (
    <InformativeRowList primaryLeftText={title} secondaryLeftText={subtitle}>
      <Container>
        <FenixIcon color="default" size={icon?.size} token={icon?.token} />
      </Container>
    </InformativeRowList>
  );
};

RowList.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.shape({
    size: PropTypes.string,
    token: PropTypes.string,
  }),
};

export default RowList;
