import { KEYS as CACHE_KEYS, sessionCache } from '../../services/cache';

export const STATE_KEYS = {
  SUCCESS_CALLBACK: 'successCallback',
  CANCEL_CALLBACK: 'cancelCallback',
  CHECKOUT_ID: 'checkoutId',
  TRACKER_CONTEXT: 'trackerContext',
  DIALOG_CONTEXT: 'dialogContext',
  TOOLTIP_CONTEXT: 'tooltipContext',
  ENTRY_POINT_PAGE: 'entryPointPage',
  TERMS_AND_CONDITIONS_STATUS: 'areTermsAccepted',
  SELECTED_INSTRUMENTS: 'selectedInstruments',
  AMOUNT_NEED_CHANGE: 'amountValue',
  CURRENCY_SYMBOL_NEED_CHANGE: 'currencySymbol',
  CHALLENGE: 'challenge',
  SECURITY_CODE: 'securityCode',
};

export const INITIAL_STATE = {
  [STATE_KEYS.SUCCESS_CALLBACK]: sessionCache.get(CACHE_KEYS.SUCCESS_CALLBACK),
  [STATE_KEYS.CANCEL_CALLBACK]: sessionCache.get(CACHE_KEYS.CANCEL_CALLBACK),
  [STATE_KEYS.CHECKOUT_ID]: '',
  [STATE_KEYS.DIALOG_CONTEXT]: { open: false },
  [STATE_KEYS.TOOLTIP_CONTEXT]: { show: false },
  [STATE_KEYS.ENTRY_POINT_PAGE]: sessionCache.get(CACHE_KEYS.ENTRY_POINT_PAGE),
  [STATE_KEYS.TERMS_AND_CONDITIONS_STATUS]: false,
  [STATE_KEYS.SELECTED_INSTRUMENTS]: [],
  [STATE_KEYS.AMOUNT_NEED_CHANGE]: '',
  [STATE_KEYS.CURRENCY_SYMBOL_NEED_CHANGE]: undefined,
  [STATE_KEYS.CHALLENGE]: sessionCache.get(CACHE_KEYS.CHALLENGE),
  [STATE_KEYS.SECURITY_CODE]: sessionCache.get(CACHE_KEYS.SECURITY_CODE) ?? {},
};

export const BFF_ACTION_TYPES = {
  NAVIGATE: 'NAVIGATE',
  NAVIGATE_BACK: 'NAVIGATE_BACK',
  TRACKING: 'TRACKING',
  OPEN_DIALOG: 'OPEN_DIALOG',
  DISMISS_DIALOG: 'DISMISS',
  SHOW_TOOLTIP: 'SHOW_TOOLTIP',
  HIDE_TOOLTIP: 'HIDE_TOOLTIP',
  SELECT_INSTRUMENT: 'SELECT_INSTRUMENT',
  FINISH_CHALLENGES_FLOW: 'FINISH_CHALLENGES_FLOW',
  CANCEL_FLOW: 'CANCEL_FLOW',
};
