const STRING_ASCII_REGEX = /^[\x20-\x5A]{1,26}$/;
const STRING_WITHOUT_STRANGE_CHARCTER = /^[^\x21-\x40]{1,26}$/;
const CARD_HOLDER_MAX_LENGTH = 26;
const CARD_HOLDER_MIN_WORDS = 2;

/**
 * Validates if the input is a valid card holder name.
 *
 * @param {string | undefined | null} input Alphanumeric and non-alphanumeric characters.
 * @returns {boolean} true or false whether the input is a valid card holder name or not.
 */
export default function isCardHolderValid(input) {
  const value = String(input);
  const numberOfWords = value.trim().split(/\s+/);

  return (
    STRING_ASCII_REGEX.test(value) &&
    STRING_WITHOUT_STRANGE_CHARCTER.test(value) &&
    value.length <= CARD_HOLDER_MAX_LENGTH &&
    numberOfWords.length >= CARD_HOLDER_MIN_WORDS
  );
}
