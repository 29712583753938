import { useCallback } from 'react';
import { invalidateSessionCache } from '../providers/mainContext/actions/invalidateSessionCache';
import { useAppCallbacks } from '../providers/mainContext/selectors/useAppCallbacks';
import { useAppDispatch } from '../providers/mainContext/selectors/useAppDispatch';
import { useCallbackNavigation } from './useCallbackNavigation';

export const useGoCancelCallback = () => {
  const { cancelCallback } = useAppCallbacks();
  const dispatch = useAppDispatch();
  const { callbackNavigate } = useCallbackNavigation();

  const goCancelCallback = useCallback(
    ({ queryParams = {}, replace = false }) => {
      invalidateSessionCache(dispatch);
      callbackNavigate(cancelCallback, queryParams, replace);
    },
    [dispatch, callbackNavigate, cancelCallback],
  );

  return { goCancelCallback };
};
