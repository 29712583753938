import { useOnAppearActions } from '@checkout-ui/backend-driven';
import React from 'react';
import { BrandErrorContainer } from '@app/components/Error';
import { useGoBack } from '@app/hooks/useGoBack';
import { useChallenges } from '@app/services/queries';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';

const Challenges = () => {
  const { goBack } = useGoBack();
  const { isFetching, error, data, refetch } = useChallenges();

  const actions = isFetching ? [] : data?.on_appear?.actions;
  useOnAppearActions(actions);

  if (error) {
    return (
      <BrandErrorContainer
        fenixLayout
        onPrimaryAction={refetch}
        secondaryLabel="Volver"
        onSecondaryAction={() => goBack()}
        errorCode={error.response?.status}
      />
    );
  }

  return <PeyaLoader position="center" />;
};

export default Challenges;
