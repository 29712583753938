import React from 'react';
import PropTypes from 'prop-types';
import logger from '../../logger';
import sentryHubInstance from '../../monitoring/sentry/initialize';

class ErrorBoundary extends React.Component {
  state = { showErrorBoundarySubtree: true };

  isChunkLoadingError(error) {
    return (
      error?.message &&
      (error?.message.indexOf('Loading chunk') !== -1 ||
        error?.message.indexOf('Loading CSS chunk') !== -1)
    );
  }

  componentDidCatch(error) {
    if (!this.isChunkLoadingError(error)) {
      this.setState({
        showErrorBoundarySubtree: false,
      });

      this.log(error);
    }
  }

  log(error) {
    logger.fatal(error, {
      description: '[ERROR_BOUNDARY]',
      message: error?.message,
      stack: error?.stack,
    });
  }

  render() {
    const { showErrorBoundarySubtree } = this.state;
    const { children, fallback } = this.props;
    return showErrorBoundarySubtree ? children : fallback || null;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node,
};

export default ErrorBoundary;
