import { useAppCallbacks } from '@app/providers/mainContext/selectors/useAppCallbacks';
import { useCallback } from 'react';
import { useCallbackNavigation } from './useCallbackNavigation';
import { useGoBack } from './useGoBack';

export const useCancelChallenge = () => {
  const { cancelCallback } = useAppCallbacks();
  const { callbackNavigate } = useCallbackNavigation();
  const { goBack } = useGoBack();

  const cancelChallenge = useCallback(
    (queryParams) => {
      if (cancelCallback) callbackNavigate(cancelCallback, queryParams);
      else goBack();
    },
    [callbackNavigate, cancelCallback, goBack],
  );

  return { cancelChallenge };
};
