import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

const Link = ({ value, style: { color, token }, onClick }) => {
  return (
    <Typography component="a" onClick={onClick} color={color} token={token}>
      {value}
    </Typography>
  );
};

Link.propTypes = {
  value: PropTypes.string,
  url: PropTypes.string,
  style: PropTypes.shape({
    color: PropTypes.string,
  }),
};

export default Link;
