import React from 'react';
import PropTypes from 'prop-types';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useIsLocal } from '../../utils/environment';
import { initializeReactQueryClient } from './client';

const client = initializeReactQueryClient();

const ReactQueryProvider = ({ children }) => {
  return (
    <QueryClientProvider client={client}>
      {children}
      {useIsLocal() ? <ReactQueryDevtools /> : null}
    </QueryClientProvider>
  );
};

ReactQueryProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ReactQueryProvider;
