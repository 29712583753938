import format from '../../commons/utils/string/format';
import { URLS } from './constants';
import getAxiosInstance from './axios';
import serviceErrorWrapper from './serviceErrorWrapper';

async function saveChallenge(challengeType, challengeData = {}, axios = getAxiosInstance()) {
  return serviceErrorWrapper('saveChallenge', async () => {
    const url = format(URLS.SAVE_CHALLENGE, challengeType);
    const { data } = await axios.post(url, challengeData);
    return data;
  });
}

export default saveChallenge;
