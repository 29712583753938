import React, { useEffect, useState } from 'react';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
import PropTypes from 'prop-types';

import logger from '@app/logger';
import { usePublicEnv } from '../PublicEnv';
import { initializeInstance as initializeFWF } from './instance';

const FwfFlagsContext = React.createContext();

export const FwfFlagsProvider = ({ fwfPromise, children }) => {
  const [fwf, setFwf] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { userId } = usePublicEnv();

  useEffect(() => {
    if (fwf) {
      setIsLoading(false);
      return;
    }
    initializeFWF(fwfPromise, { userId: `${userId}` })
      .then((data) => {
        logger.info(
          '[FWF_INITIALIZATION][SUCCESS]',
          `fwfContext: ${JSON.stringify(data)} - userId: ${userId}`,
        );
        setFwf(data);
      })
      .catch((error) => {
        logger.error('[FWF_INITIALIZATION][ERROR]', error.message);
      })
      .finally(() => setIsLoading(false));
  }, [fwf, fwfPromise, userId]);

  if (isLoading) return <PeyaLoader position="center" />;

  return <FwfFlagsContext.Provider value={{ fwf }}>{children}</FwfFlagsContext.Provider>;
};

FwfFlagsProvider.propTypes = {
  fwfPromise: PropTypes.instanceOf(Promise),
  children: PropTypes.node.isRequired,
};

export default FwfFlagsContext;
