import React, { useState } from 'react';
import { Dialog } from '@appscore/web-components';
import { useIntl } from 'react-intl';
import SecurityCodeModalIcon from './components/ModalIcon';
import Container from './components/Container';
import Text from './components/Text';
import messages from './messages';

function SecurityCodeHelp() {
  const intl = useIntl();
  const { formatMessage } = intl;
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <Container onClick={() => setIsModalOpen(true)} data-testid="security-code-help">
        <Text>?</Text>
      </Container>
      <Dialog
        open={isModalOpen}
        title={formatMessage(messages.title)}
        text={formatMessage(messages.subtitle)}
        onFirstOptionClick={() => setIsModalOpen(false)}
        onClose={() => setIsModalOpen(false)}
        firstButtonText={formatMessage(messages.buttonLabel)}
      >
        <SecurityCodeModalIcon />
      </Dialog>
    </>
  );
}

export default SecurityCodeHelp;
