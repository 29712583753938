import React from 'react';
import { camelToKebab } from '@pedidosya/web-fenix/utils/tokens';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import styled from 'styled-components';

export const getRgbColorWithOpacity = (color, opacity) => {
  const rgb = hexToRgb(color);
  if (!rgb) return null;
  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`;
};

export const getRgbColor = (color) => {
  const rgb = hexToRgb(color);
  if (!rgb) return null;
  return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
};

const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

const EllipsisText = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 12px;
  word-break: ${({ breakAnywhere }) => (breakAnywhere ? 'break-all' : 'normal')};
`;

export const Text = ({
  label,
  component = 'span',
  withEllipsis = false,
  breakAnywhere = false,
  ...props
}) => {
  const { text, style } = label;
  let Component = null;

  if (withEllipsis) Component = EllipsisText;
  else Component = Typography;

  return (
    <Component
      data-testid="text-element"
      color={camelToKebab(style.color)}
      component={component}
      token={camelToKebab(style.typography)}
      breakAnywhere={breakAnywhere}
      {...props}
    >
      {text}
    </Component>
  );
};
