import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Delete } from '@appscore/web-components';

const ClearButtonStyled = styled.button`
  width: 16px;
  height: 16px;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin-right: 4px;
`;

const ClearButton = ({ handleOnClick }) => {
  return (
    <ClearButtonStyled type="reset" onClick={handleOnClick}>
      <Delete />
    </ClearButtonStyled>
  );
};

ClearButton.propTypes = {
  handleOnClick: PropTypes.func.isRequired,
};

export default ClearButton;
