import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import FenixIcon from '@pedidosya/web-fenix/icons/FenixIcon';
import { camelToKebab } from '@pedidosya/web-fenix/utils/tokens';
import pos from '@app/assets/images/pos.svg';

const StyledImg = styled.img`
  width: ${({ theme, size }) => theme.size(camelToKebab(size))};
  height: ${({ theme, size }) => theme.size(camelToKebab(size))};
`;

const IconCustom = (props) => {
  const iconsCustomMap = {
    pos: <StyledImg src={pos} size={props.size} alt="POS" />,
  };
  return iconsCustomMap[props.token] || null;
};

const FenixIconWrapper = ({ icon, color, size }) => {
  const [showIconCustom, setShowIconCustom] = useState(false);
  const commonProps = useMemo(
    () => ({
      color: camelToKebab(color),
      size: camelToKebab(size),
      token: camelToKebab(icon),
    }),
    [color, icon, size],
  );

  useEffect(() => {
    const fenixIcon = document.getElementById(commonProps.token);
    setShowIconCustom(Boolean(!fenixIcon));
  }, [commonProps.token]);

  return (
    <>
      <FenixIcon id={commonProps.token} {...commonProps} />
      {showIconCustom ? <IconCustom {...commonProps} /> : null}
    </>
  );
};

export default FenixIconWrapper;
