import getAxiosInstance from './axios';
import { URLS } from './constants';
import serviceErrorWrapper from './serviceErrorWrapper';

async function getPaymentMethods(axios = getAxiosInstance()) {
  return serviceErrorWrapper('getPaymentMethods', async () => {
    const { data } = await axios.get(URLS.PAYMENT_METHODS);
    return data;
  });
}

export default getPaymentMethods;
