import { STATE_KEYS } from '../constants';
import { useAppState } from '../context';

export const useAppCallbacks = () => {
  const [state] = useAppState();
  return {
    [STATE_KEYS.SUCCESS_CALLBACK]: state[STATE_KEYS.SUCCESS_CALLBACK],
    [STATE_KEYS.CANCEL_CALLBACK]: state[STATE_KEYS.CANCEL_CALLBACK],
  };
};
