import React from 'react';
import styled from 'styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import { camelToKebab } from '@pedidosya/web-fenix/utils/tokens';

import { Container } from './StyledComponents';

const CardBody = styled.div`
  position: relative;
  height: 64px;
  width: 100%;
  opacity: ${({ enabled }) => (enabled ? '1' : '0.5')};
`;

const TagContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 100%;
  background-color: ${({ theme, tagColor }) => theme.color(tagColor)};
`;

const CardContainer = ({ enabled, errorMessage, tagColor, children }) => {
  const { text, style } = errorMessage;
  return (
    <Container>
      <CardBody enabled={enabled}>{children}</CardBody>
      <TagContainer tagColor={camelToKebab(tagColor)}>
        <Typography
          color={camelToKebab(style.color)}
          component="span"
          token={camelToKebab(style.typography)}
        >
          {text}
        </Typography>
      </TagContainer>
    </Container>
  );
};

export default CardContainer;
