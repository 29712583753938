import React from 'react';
import Skeleton from '@pedidosya/web-fenix/atoms/Skeleton';
import { Container, StyledCard } from './StyledComponents';

const SkeletonLoader = ({ numberOfInstruments = 3 }) => {
  const skeletonsArray = Array.from({ length: numberOfInstruments }, (_, index) => index);

  return (
    <StyledCard direction="column" withShadow={false} withBorder={false}>
      {skeletonsArray.map((_, idx) => (
        <Container key={idx} border={false}>
          <Skeleton shape="square" />
        </Container>
      ))}
    </StyledCard>
  );
};

export default SkeletonLoader;
