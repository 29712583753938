import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.modules.checkout.tyc.detail.header',
    defaultMessage: 'Términos y condiciones',
  },
  errorTitle: {
    id: 'app.modules.checkout.tyc.detail.error.title',
    defaultMessage: 'Tuvimos un problema',
  },
  errorSubtitle: {
    id: 'app.modules.checkout.tyc.detail.error.subtitle',
    defaultMessage: 'No pudimos cargar los términos y condiciones. Por favor intentá nuevamente.',
  },
  errorActionLabel: {
    id: 'app.modules.checkout.tyc.detail.error.action.label',
    defaultMessage: 'Entendido',
  },
});
