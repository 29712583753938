import React from 'react';
import PropTypes from 'prop-types';
import { InputText } from '@appscore/web-components';
import { useIntl } from 'react-intl';
import messages from './messages';
import formatCardExpiration from '../utils/formatters/cardExpiration';

const CardExpirationInput = ({ value, onChange, error = false, disabled = false, ...rest }) => {
  const isValid = () => {
    if (!value) return true;

    return !error;
  };

  const handleChange = (event) => {
    const newValue = event?.target?.value;
    if (newValue.length < value.length) {
      // if user is deleting input don't format
      onChange(newValue);
    } else {
      // otherwise if user is typing
      onChange(formatCardExpiration(newValue));
    }
  };

  const intl = useIntl();
  const { formatMessage } = intl;
  const errorMessage = isValid() ? '' : formatMessage(messages.errorMessage);

  return (
    <InputText
      {...rest}
      type="tel"
      label={formatMessage(messages.label)}
      onChange={handleChange}
      value={value}
      helpText={errorMessage}
      error={Boolean(errorMessage)}
      disabled={disabled}
      inputProps={{
        'data-testid': rest?.name || 'card-expiration-input',
      }}
    />
  );
};

CardExpirationInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default CardExpirationInput;
