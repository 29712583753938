import React, { useEffect } from 'react';
import styled from 'styled-components';
import Card from '@appscore/web-components/Card';
import { useFormFields } from './useFormFields';
import logger from '@app/logger';

import { BackendDrivenComponent } from '@checkout-ui/backend-driven';
import CardNumberInput from '../../components/inputs/CardNumberInput';
import isCardHolderValid from '../../components/inputs/utils/validators/cardHolder';
import isCardNumberValid from '../../components/inputs/utils/validators/cardNumber';
import isCardSecurityCodeValid from '../../components/inputs/utils/validators/cardSecurityCode';
import isCardExpirationValid from '../../components/inputs/utils/validators/cardExpiration';
import { useCardNumberValidation } from '@app/services/queries';
import { parseMMYYtoMonthAndYear } from '@app/utils/date';
import CardHolderNameInput from '../../components/inputs/CardHolderNameInput';
import CardExpirationInput from '../../components/inputs/CardExpirationInput';
import CardSecurityCodeInput from '../../components/inputs/CardSecurityCodeInput';
import { PROVIDERS_NAMES } from '@app/services/cardTokenization/providers/constants';
import { INPUT_NAMES, BIN_LENGTH } from './constants';
import { isCVVNeeded } from '@app/pages/NewDebitOrCreditCard/components/NewCardForm/providersForms/SpreedlyForm/utils';
import useOnce from '@app/hooks/useOnce';

const StyledCard = styled(Card)`
  padding: ${({ theme }) => `${theme.spacing(10)} ${theme.spacing(8)} ${theme.spacing(24)}`};
  row-gap: ${({ theme }) => theme.spacing(16)};
  flex-wrap: wrap;
  justify-content: space-between;
`;

const StyledCardNumberInput = styled(CardNumberInput)`
  width: ${({ width }) => width};
  label {
    width: 100%;
  }
`;

const StyledCardHolderNameInput = styled(CardHolderNameInput)`
  width: ${({ width }) => width};
  label {
    width: 100%;
  }
`;

const StyledCardExpirationInput = styled(CardExpirationInput)`
  width: ${({ width }) => width};
  label {
    width: 100%;
  }
`;

const StyledCardSecurityCodeInput = styled(CardSecurityCodeInput)`
  width: ${({ width }) => width};
  label {
    width: 100%;
  }
`;

const SpreedlyFormBFF = ({ components, onValidatedCardChange, onFormData, onValidForm }) => {
  useOnce(() =>
    logger.error(
      '[SpreedlyFormBFF][ERROR]',
      `This form should not be showing. url: ${window.location.href}`,
    ),
  );

  const {
    values: formValues,
    error,
    updateField,
    updateState,
    validateAll,
  } = useFormFields(
    [
      { name: INPUT_NAMES.CARD_HOLDER, validator: (value) => isCardHolderValid(value) },
      {
        name: INPUT_NAMES.CARD_NUMBER,
        validator: (value, { validatedCard }) =>
          isCardNumberValid(
            value,
            validatedCard?.card_number_format,
            validatedCard?.lunh_validation,
          ),
      },
      {
        name: INPUT_NAMES.CARD_SECURITY_CODE,
        validator: (value, { validatedCard }) => {
          if (!isCVVNeeded(validatedCard)) return true;
          const regex = validatedCard?.csc_regex ? new RegExp(validatedCard?.csc_regex) : undefined;
          return isCardSecurityCodeValid(value, regex);
        },
      },
      { name: INPUT_NAMES.CARD_EXPIRATION, validator: (value) => isCardExpirationValid(value) },
    ],
    {
      validatedCard: null,
    },
  );

  const cardNumberValidation = useCardNumberValidation(
    PROVIDERS_NAMES.SPREEDLY,
    formValues.cardNumber.slice(0, BIN_LENGTH),
    {
      enabled: formValues.cardNumber.length >= BIN_LENGTH,
      onSuccess: (data) => {
        onValidatedCardChange(data?.validated_card);
        if (!needCVV) updateField(INPUT_NAMES.CARD_SECURITY_CODE)('');
        updateState({ validatedCard: data?.validated_card });
        validateAll();
      },
    },
  );
  const validatedCard = cardNumberValidation?.data?.validated_card;

  const needCVV = isCVVNeeded(validatedCard);

  const validForm =
    Object.values(error).length === 0 && validatedCard && !cardNumberValidation.isLoading;

  useEffect(() => {
    const [cardExpirationMonth, cardExpirationYear] = parseMMYYtoMonthAndYear(
      formValues[INPUT_NAMES.CARD_EXPIRATION],
    );
    const formData = {
      cardHolderName: formValues[INPUT_NAMES.CARD_HOLDER],
      cardNumber: formValues[INPUT_NAMES.CARD_NUMBER],
      cardSecurityCode: formValues[INPUT_NAMES.CARD_SECURITY_CODE],
      cardExpirationMonth,
      cardExpirationYear,
    };
    onFormData(formData);
    onValidForm(validForm);
  }, [validForm, formValues]);

  const componentResolvers = {
    CARD_NUMBER_INPUT: ({ data: { style } }) => ({
      component: StyledCardNumberInput,
      props: {
        value: formValues[INPUT_NAMES.CARD_NUMBER],
        onChange: updateField(INPUT_NAMES.CARD_NUMBER),
        error: error[INPUT_NAMES.CARD_NUMBER],
        cardNumberFormat: validatedCard?.card_number_format,
        verified: Boolean(validatedCard),
        name: INPUT_NAMES.CARD_NUMBER,
        ...style,
      },
    }),
    CARD_HOLDER_INPUT: ({ data: { style } }) => ({
      component: StyledCardHolderNameInput,
      props: {
        value: formValues[INPUT_NAMES.CARD_HOLDER],
        onChange: updateField(INPUT_NAMES.CARD_HOLDER),
        error: error[INPUT_NAMES.CARD_HOLDER],
        name: INPUT_NAMES.CARD_HOLDER,
        ...style,
      },
    }),
    CARD_EXPIRATION_INPUT: ({ data: { style } }) => ({
      component: StyledCardExpirationInput,
      props: {
        value: formValues[INPUT_NAMES.CARD_EXPIRATION],
        onChange: updateField(INPUT_NAMES.CARD_EXPIRATION),
        error: error[INPUT_NAMES.CARD_EXPIRATION],
        name: INPUT_NAMES.CARD_EXPIRATION,
        ...style,
      },
    }),
    CARD_SECURITY_CODE_INPUT: ({ data: { style } }) => ({
      component: StyledCardSecurityCodeInput,
      props: {
        value: formValues[INPUT_NAMES.CARD_SECURITY_CODE],
        onChange: updateField(INPUT_NAMES.CARD_SECURITY_CODE),
        error: error[INPUT_NAMES.CARD_SECURITY_CODE],
        name: INPUT_NAMES.CARD_SECURITY_CODE,
        disabled: !needCVV,
        ...style,
      },
    }),
  };

  return (
    <StyledCard direction="row">
      <BackendDrivenComponent componentResolvers={componentResolvers} components={components} />
    </StyledCard>
  );
};

export default SpreedlyFormBFF;
