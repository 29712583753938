import React, { useEffect } from 'react';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
import { useBFFActionsExecutor } from '@checkout-ui/backend-driven';

import { useQueryParamValue } from '@app/utils/domRouter';

import { QUERY_PARAM } from '@commons/constants';
import { PCI_PATHS, NEW_PCI_PATHS } from '@commons/services/routes.service';

import logger from '@app/logger';

import useOnce from '@app/hooks/useOnce';
import { useHardNavigate } from '@app/hooks/useHardNavigate';
import { useFlag } from '@app/hooks/funWithFlags';

import { useAppDispatch } from '@app/providers/mainContext/selectors/useAppDispatch';
import { updateChallenge } from '@app/providers/mainContext/actions/updateChallenge';
import { usePublicEnv } from '@app/providers/PublicEnv';
import { useAppCallbacks } from '@app/providers/mainContext/selectors/useAppCallbacks';
import { BOOLEAN_FLAGS } from '@app/providers/funWithFlags/instance';

import { EVENT_NAME_TRACKING, actionTracking } from '@app/utils/tracking';

const ProviderChallenge = () => {
  const { country } = usePublicEnv();
  const { cancelCallback } = useAppCallbacks();
  const dispatch = useAppDispatch();
  const hardNavigate = useHardNavigate();
  const { executeBFFActions } = useBFFActionsExecutor();

  const challengeId = useQueryParamValue(QUERY_PARAM.CHALLENGE.ID);
  const challengeType = useQueryParamValue(QUERY_PARAM.CHALLENGE.TYPE);
  const transactionToken = useQueryParamValue(QUERY_PARAM.CHALLENGE.TRANSACTION_TOKEN);

  useOnce(() => {
    executeBFFActions(
      actionTracking(EVENT_NAME_TRACKING.CHALLENGE_LOADED, {
        challengeType: challengeType?.toLowerCase(),
      }),
    );
    logger.info(
      '[INTENT_PROVIDER_CHALLENGE]',
      `challengeId: ${challengeId} - challengeType: ${challengeType}`,
    );
  });

  const { flagValue: navigationFlag, isLoading: isLoadingFlag } = useFlag(
    BOOLEAN_FLAGS.WEB_CDE_PE_NEW_FLOW_REDIRECT.KEY,
  );

  useEffect(() => {
    if (isLoadingFlag) return;

    updateChallenge(dispatch, { challengeId, challengeType });

    const pciOrigin = window.location.origin;

    const newPCIUrl = `${pciOrigin}${NEW_PCI_PATHS.PROVIDER_CHALLENGE}`;
    const oldPCIUrl = `${pciOrigin}${PCI_PATHS.PROVIDER_CHALLENGE}`;

    logger.info(
      `[PROVIDER_CHALLENGE][hardNavigate][${navigationFlag ? 'NEW_PCI' : 'OLD_PCI'}]`,
      `URL: ${navigationFlag ? newPCIUrl : oldPCIUrl}`,
    );

    hardNavigate(
      navigationFlag ? newPCIUrl : oldPCIUrl,
      {
        c: `.${country.shortName}`,
        t: transactionToken,
        cancelCallback,
      },
      { replace: true },
    );
  }, [
    hardNavigate,
    dispatch,
    challengeId,
    challengeType,
    transactionToken,
    country.shortName,
    cancelCallback,
    isLoadingFlag,
    navigationFlag,
  ]);

  return <PeyaLoader position="center" />;
};

export default ProviderChallenge;
