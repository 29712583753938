import PaymentsGlobalStyleSheet from '@app/theme/PaymentsGlobalStyleSheet';
import { BrandError, theme } from '@appscore/web-components';
import GlobalStyleSheet from '@appscore/web-components/theme/globalStyleSheet';
import PropTypes from 'prop-types';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { GO_BACK } from '../../constants/routes';
import logger from '../../logger';

const LoadAppError = ({ error }) => {
  const hasHistory = window?.history?.length > 1;
  const secondaryLabel = hasHistory ? 'Volver' : null;
  const onSecondaryAction = hasHistory
    ? () => {
        window.history.go(GO_BACK);
      }
    : null;

  logger.error('[LoadAppError] error: ', error?.message);

  return (
    <ThemeProvider theme={theme}>
      <>
        <PaymentsGlobalStyleSheet />
        <GlobalStyleSheet />
        <BrandError
          primaryLabel="Intentar nuevamente"
          onPrimaryAction={() => window.location.reload()}
          secondaryLabel={secondaryLabel}
          onSecondaryAction={onSecondaryAction}
        />
      </>
    </ThemeProvider>
  );
};

LoadAppError.propTypes = {
  error: PropTypes.instanceOf(Error),
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default LoadAppError;
