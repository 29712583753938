import React from 'react';
import { GO_BACK } from '@app/constants/routes';
import BrandErrorContainer from './BrandErrorContainer';

const BrandErrorBoundaryContainer = () => (
  <BrandErrorContainer
    fenixLayout
    onPrimaryAction={() => window.location.reload()}
    secondaryLabel="Volver"
    onSecondaryAction={() => window.history.go(GO_BACK)}
  />
);

export default BrandErrorBoundaryContainer;
