import { URLS } from './constants';
import getAxiosInstance from './axios';
import serviceErrorWrapper from './serviceErrorWrapper';

async function getDisabledUserInstruments(axios = getAxiosInstance()) {
  return serviceErrorWrapper('getDisabledUserInstruments', async () => {
    const { data } = await axios.get(URLS.DISABLED_PAYMENT_INSTRUMENTS);
    return data;
  });
}

export default getDisabledUserInstruments;
