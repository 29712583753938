import getAxiosInstance from './axios';
import { URLS } from './constants';
import serviceErrorWrapper from './serviceErrorWrapper';

async function getChallenges(axios = getAxiosInstance()) {
  return serviceErrorWrapper('getChallenges', async () => {
    const { data } = await axios.get(URLS.CHALLENGES);
    return data;
  });
}

export default getChallenges;
