import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Trash } from '@appscore/web-components/icons';
import GenericButton from '@app/components/GenericButton';

const StyledTrash = styled(Trash)`
  height: 20px;
  width: 18px;
`;

const DeleteButton = styled(GenericButton)`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

const DeleteActionContainer = ({ onDelete }) => {
  const handleDelete = (event) => {
    event.stopPropagation();
    onDelete();
  };

  return (
    <DeleteButton title="delete" onClick={handleDelete}>
      <StyledTrash size="medium" />
    </DeleteButton>
  );
};

DeleteActionContainer.propTypes = {
  onDelete: PropTypes.func.isRequired,
};

export default DeleteActionContainer;
