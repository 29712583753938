import { useReducer } from 'react';

const filterErrorObject = (error) =>
  Object.keys(error)
    .filter((key) => error[key])
    .reduce((carry, key) => ({ ...carry, [key]: true }), {});

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_FIELD': {
      const validator = action.payload.validator || (() => true);
      const hasError = !validator(action.payload.value, state);

      return {
        ...state,
        values: {
          ...state.values,
          [action.payload.name]: action.payload.value,
        },
        error: filterErrorObject({
          ...state.error,
          [action.payload.name]: hasError,
        }),
      };
    }

    case 'VALIDATE_ALL_FIELDS':
      return {
        ...state,
        error: filterErrorObject({
          ...state.error,
          ...Object.keys(action.payload).reduce(
            (carry, key) => ({
              ...carry,
              [key]: !action.payload[key](state.values[key], state),
            }),
            {},
          ),
        }),
      };

    case 'UPDATE_STATE':
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export const useFormFields = (fields, initialState = {}) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    values: fields.reduce(
      (carry, field) => ({ ...carry, [field.name]: field.defaultValue || '' }),
      {},
    ),
    error: {},
  });

  const updateField = (name) => (value) => {
    const field = fields.find(({ name: fieldName }) => fieldName === name) || {};

    dispatch({ type: 'UPDATE_FIELD', payload: { name, value, validator: field.validator } });
  };

  const validateAll = () => {
    dispatch({
      type: 'VALIDATE_ALL_FIELDS',
      payload: fields.reduce((carry, { validator, name }) => ({ ...carry, [name]: validator }), {}),
    });
  };

  return {
    ...state,
    updateField,
    validateAll,
    updateState: (payload) => dispatch({ type: 'UPDATE_STATE', payload }),
  };
};
