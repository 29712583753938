import { API_BASE_URI } from '@commons/constants';

export const URLS = {
  START_PAYMENT_METHODS: `${API_BASE_URI}/start`,
  PAYMENT_INSTRUMENTS: `${API_BASE_URI}/payment-instruments`,
  DISABLED_PAYMENT_INSTRUMENTS: `${API_BASE_URI}/payment-instruments/disabled`,
  NEED_CHANGE: `${API_BASE_URI}/payment-instruments/need-change`,
  NEW_PAYMENT_INSTRUMENT_FORM: `${API_BASE_URI}/payment-instruments/{0}/form`,
  SECURITY_CODE_VALIDATION: `${API_BASE_URI}/challenges/{0}/card-security-code`,
  VERIFY_PAYMENT_INSTRUMENT: `${API_BASE_URI}/payment-instruments/{0}/verify`,
  DELETE_PAYMENT_INSTRUMENT: `${API_BASE_URI}/payment-instruments/{0}`,
  PAYMENT_METHODS: `${API_BASE_URI}/payment-methods`,
  EXTERNAL_PROVIDERS: `${API_BASE_URI}/external-providers?url={0}&successCallback={1}`,
  CHALLENGES: `${API_BASE_URI}/challenges`,
  CHALLENGE_SCREEN: `${API_BASE_URI}/challenges/{0}/{1}`,
  SAVE_CHALLENGE: `${API_BASE_URI}/challenges/{0}`,
  SAVE_CHALLENGE_BY_REQUEST_ID: `${API_BASE_URI}/challenges/{0}/request-id/{1}`,
  VALIDATE_HTML: `${API_BASE_URI}/validate/html`,
  PAYMENT_METHODS_CARD_CONTEXT: `${API_BASE_URI}/payment-methods-card-context`,
  PAYLOAD_FOR_NEW_INSTRUMENT: `${API_BASE_URI}/payload-for-new-instrument/{0}`,
};

export const DEFAULT_VERSION = '1';

/**
 *
 * @param {String} prefix to add to all URLs paths;
 * @description use this method when the app shell that consumes
 * the microsite has a base path, so the API endpoints should be
 * prefixed with that path in the server
 */
export const addPrefixToUrls = (prefix) => {
  Object.keys(URLS).forEach((key) => {
    URLS[key] = `${prefix}${URLS[key]}`;
  });
};
