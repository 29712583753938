import logger from '../logger';
import parseAxiosError from '../../commons/utils/axios/parseAxiosError';

async function serviceErrorWrapper(serviceTAG, serviceCall) {
  try {
    return await serviceCall();
  } catch (error) {
    const { status, message } = parseAxiosError(error);
    logger.error(`[services][${serviceTAG}][ERROR]`, `status: ${status} - message: ${message}`);
    throw error;
  }
}

export default serviceErrorWrapper;
