import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card, Image } from '@appscore/web-components';

const EmptyStateContainer = styled(Card)`
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(8)};
  padding-bottom: ${({ theme }) => theme.spacing(10)};
`;

const Logo = styled(Image)`
  margin: ${({ theme }) => theme.spacing(12, 0)};
`;

const Title = styled.span`
  line-height: ${({ theme }) => theme.spacing(10)};
  ${({ theme }) => theme.fontFamily.secondaryFont.mediumBold}
  color: ${({ theme }) => theme.colors.night_blue_2};
  text-align: center;
`;

const Description = styled.p`
  line-height: ${({ theme }) => theme.spacing(10)};
  ${({ theme }) => theme.fontFamily.secondaryFont.smallRegular}
  color: ${({ theme }) => theme.colors.night_blue_2};
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing(4)};
`;

const EmptyState = ({ title, description, icon }) => {
  return (
    <EmptyStateContainer direction="column">
      <Logo alt="empty state image" src={icon} width={77} height={80} />
      <Title>{title}</Title>
      <Description>{description}</Description>
    </EmptyStateContainer>
  );
};

EmptyState.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.string,
};

export default EmptyState;
