/**
 * This function correctly formats event names.
 * The last part of the event (clicked, loaded, failed, etc.) should be preceded by a dot.
 * Check tests for use cases.
 *
 * @param {String} event A tracking event
 * @returns {String} A tracking event guaranteed to be in the correct format (payment_method.clicked)
 */

const formatEvent = (event) => event.replace(/_(?!.*(_|\.))/g, '.');

export default formatEvent;
