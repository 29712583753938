import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useGoBack } from '@app/hooks/useGoBack';
import { useQueryParamValue } from '@app/utils/domRouter';
import { QUERY_PARAM } from '@commons/constants';
import { withExternalLoading } from '@app/HOCs/withExternalLoading';
import FixedHeader from '@app/components/FixedHeader';
import Close from '@pedidosya/web-fenix/icons/Close';

import { ROUTES, URIS_FRONT } from '@commons/services/routes.service';
import format from '@commons/utils/string/format';
import { useNavigate } from 'react-router-dom';
import logger from '@app/logger';
import { addQueryParamsToUrlString } from '@app/utils/queryString';
import { useInvalidateQueriesAndRefetchInactive } from '@app/hooks/useInvalidateQueriesAndRefetchInactive';
import { QUERIES_NAMES } from '@app/services/queries';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';

const DEFAULT_PROVIDER_TITLE = 'Agregar medio de pago';

const Iframe = styled.iframe`
  border: unset;
  width: 100%;
  flex: 1;
`;

const IframeContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const ExternalProviderIframe = withExternalLoading(
  ({ iframeURL, title, handleHeaderActionClick }) => {
    const [iframeLoading, setIframeLoading] = useState(true);

    return (
      <IframeContainer>
        <FixedHeader
          action={
            <span role="button" title="close">
              <Close size="medium" />
            </span>
          }
          onActionClick={handleHeaderActionClick}
        >
          {title || DEFAULT_PROVIDER_TITLE}
        </FixedHeader>
        <Iframe
          title="External provider form"
          src={iframeURL}
          onLoadCapture={() => setIframeLoading(false)}
          onError={(error) => {
            logger.error('[EXTERNAL_PROVIDERS][IFRAME_ERROR]', error);
          }}
        />
        {iframeLoading ? <PeyaLoader position="center" /> : null}
      </IframeContainer>
    );
  },
);

const ExternalProviders = () => {
  const { goBack } = useGoBack();
  const navigate = useNavigate();
  const providerUrl = useQueryParamValue(QUERY_PARAM.URL);
  const providerName = useQueryParamValue(QUERY_PARAM.PROVIDER_NAME);
  const providerTitle = useQueryParamValue(QUERY_PARAM.PROVIDER_TITLE);
  const { invalidateQueriesAndRefetchInactive } = useInvalidateQueriesAndRefetchInactive();

  const goBackAndInvalidateQuery = () => {
    invalidateQueriesAndRefetchInactive([QUERIES_NAMES.PAYMENT_METHODS]);
    goBack();
  };

  const handleProviderMessage = ({ data }) => {
    const { providerOk, instrumentId } = data;
    switch (providerOk) {
      case true:
        // success handling
        const successCallbackURL = format(
          '{0}?{1}={2}&{3}={4}',
          ROUTES.CALLBACK_EXTERNAL_PROVIDER,
          QUERY_PARAM.PROVIDER_NAME,
          providerName,
          QUERY_PARAM.PAYMENT_INSTRUMENTS_SELECTED,
          instrumentId,
        );
        logger.info('[EXTERNAL_PROVIDERS][SUCCESS]', `instrumentId: ${instrumentId}`);
        navigate(successCallbackURL);
        break;
      case false:
        // error handling
        logger.error('[EXTERNAL_PROVIDERS][ERROR]');
        goBackAndInvalidateQuery();
        break;
      default:
        // Due to this listener get an event without these values
        return;
    }
  };

  useEffect(() => {
    logger.info('[EXTERNAL_PROVIDERS][NEW_INTENT]');
    window.addEventListener('message', handleProviderMessage, false);
    return () => window.removeEventListener('message', handleProviderMessage, false);
  }, []);

  const ORIGIN = location.origin;
  const successCallbackURL = new URL(URIS_FRONT.CALLBACK_EXTERNAL_PROVIDER, ORIGIN);
  successCallbackURL.searchParams.set(QUERY_PARAM.PROVIDER_NAME, providerName);
  const externalProviderURL = addQueryParamsToUrlString(providerUrl, {
    successCallback: successCallbackURL,
  });

  return (
    <ExternalProviderIframe
      iframeURL={externalProviderURL}
      title={providerTitle}
      handleHeaderActionClick={() => goBackAndInvalidateQuery()}
    />
  );
};

export default ExternalProviders;
