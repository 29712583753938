import { sessionCache, KEYS as CACHE_KEYS } from '../../../services/cache';
import { ACTION_TYPE } from '../actionsType';

export const invalidateSessionCache = (dispatch) => {
  sessionCache.remove(CACHE_KEYS.SUCCESS_CALLBACK);
  sessionCache.remove(CACHE_KEYS.CANCEL_CALLBACK);
  sessionCache.remove(CACHE_KEYS.ENTRY_POINT_PAGE);
  sessionCache.remove(CACHE_KEYS.SELECTED_INSTRUMENTS);
  sessionCache.remove(CACHE_KEYS.SECURITY_CODE);
  dispatch({ type: ACTION_TYPE.INVALIDATE_SESSION_CACHE });
};
