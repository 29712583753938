import { Severity } from '@sentry/browser';

let SENTRY;
let SENTRY_LOG_LEVEL;

const customLevels = {
  [Severity.Error]: 0,
  [Severity.Warning]: 1,
  [Severity.Info]: 2,
  [Severity.Debug]: 3,
};

export function setInstance(instance) {
  SENTRY = instance;
}

export default function getInstance() {
  return SENTRY;
}

export function setLogLevel(logLevel) {
  SENTRY_LOG_LEVEL = logLevel;
}

export function canLog(logLevel) {
  return customLevels[logLevel] <= customLevels[SENTRY_LOG_LEVEL];
}
