import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import messages from '@app/pages/UserInstruments/messages';
import {
  InfoInstrumentContainer,
  InfoInstrumentMessage,
  InstrumentLogo,
  StyledDescription,
  StyledTitle,
  StyledSubtitle,
  Promo,
  AvailabilityInfo,
} from './index';

const InfoInstrument = ({ title, icon, subtitle, promo, enabled }) => {
  const { formatMessage } = useIntl();
  return (
    <InfoInstrumentContainer>
      <InfoInstrumentMessage>
        <InstrumentLogo src={icon} alt="card-logo" height={32} width={48} />
        <StyledDescription>
          <StyledTitle>{title}</StyledTitle>
          {subtitle ? <StyledSubtitle>{subtitle}</StyledSubtitle> : null}
        </StyledDescription>
      </InfoInstrumentMessage>
      {promo && enabled ? <Promo {...promo} /> : null}
      {!enabled ? (
        <AvailabilityInfo label={formatMessage(messages.unavailablePaymentMethod)} />
      ) : null}
    </InfoInstrumentContainer>
  );
};

InfoInstrument.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  icon: PropTypes.string,
  promo: PropTypes.object,
};

export default InfoInstrument;
