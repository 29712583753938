import BackendDrivenPage from '@app/components/BackendDrivenPage';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import logger from '@app/logger';
import { SimpleButton, DIALOG_BUTTONS_SIZE } from '@appscore/web-components';
import { useBFFActionsExecutor } from '@checkout-ui/backend-driven';
import { BrandErrorContainer } from '../../components/Error';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
import { useGoBack } from '@app/hooks/useGoBack';
import { useSelectPaymentInstruments } from '@app/hooks/useSelectPaymentInstruments';
import { QUERIES_NAMES, useUserInstruments } from '@app/services/queries';
import Section from './components/Section';
import UserInstrumentsCard from './components/UserInstrumentsCard';
import { PartialWallet } from '@app/pages/UserInstruments/components/PartialWallet';
import { UserInstrumentItemBFF } from './components/UserInstrumentsList/components/UserInstrumentItem';
import { updateSelectedInstruments } from '@app/providers/mainContext/actions/updateSelectedInstruments';
import { useAppDispatch } from '@app/providers/mainContext/selectors/useAppDispatch';
import { usePaymentInstrumentsSelected } from '@app/hooks/usePaymentInstrumentsSelected';
import {
  addTypesToInstruments,
  filterInstrumentsByType,
  getInstrumentsFromBFF,
  isInstrumentSelected,
  isInstrumentTypeSelected,
  isOfflineInstrumentSelected,
  isPartialWalletSelected,
  isSelectedInstrumentDeleted,
  isValidInstrumentSelection,
  parseInstrumentsFromQuery,
  addOriginToTrackingEvent,
} from '@app/pages/UserInstruments/utils';
import { BFF_INSTRUMENT_TYPES } from '@app/constants/bffComponents';
import { useAppNeedChange } from '@app/providers/mainContext/selectors/useAppNeedChange';
import messages from './messages';
import { useShowDialog } from '@app/hooks/useShowDialog';
import { BFF_ACTION_TYPES } from '@app/providers/mainContext/constants';
import { useDeleteUserInstrument } from '@app/services/mutations';
import stringify from '@commons/utils/object/stringify';
import { useInvalidateQueriesAndRefetchInactive } from '@app/hooks/useInvalidateQueriesAndRefetchInactive';
import DeviceErrorIcon from '@app/components/DeviceErrorIcon';
import EmptyState from '@app/pages/UserInstruments/components/EmptyState';
import { useQueryParamValue } from '@app/utils/domRouter';
import { QUERY_PARAM } from '@commons/constants';
import { useGoCancelCallback } from '@app/hooks/useGoCancelCallback';

const DEFAULT_CURRENCY_SYMBOL = '$';
const EVENT_DELETE_INSTRUMENT_CLICKED = 'online_payment_card_deleted_clicked';
const EVENT_DELETE_INSTRUMENT_CONFIRMATION = 'online_payment_card_deleted';

const UserInstrumentsBFF = () => {
  const [isGoingBack, setIsGoingBack] = useState(false);
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const { isLoading, error, data, refetch, isFetching } = useUserInstruments();
  const { goBack } = useGoBack();
  const { goCancelCallback } = useGoCancelCallback();
  const { showDialog } = useShowDialog();
  const { invalidateQueriesAndRefetchInactive } = useInvalidateQueriesAndRefetchInactive();
  const bussinesContext = useQueryParamValue(QUERY_PARAM.BUSSINES_CONTEXT);
  const { selectPaymentInstruments } = useSelectPaymentInstruments({
    replaceNavigation: Boolean(bussinesContext),
  });
  const selectedInstruments = usePaymentInstrumentsSelected();
  const queryInstruments = useQueryParamValue(QUERY_PARAM.SELECTED_INSTRUMENTS);
  const [instrumentsFromQuery, setInstrumentsFromQuery] = useState([]);
  const {
    isLoading: deletingInstrument,
    reset: resetDeleteInstrument,
    mutate: deleteInstrument,
  } = useDeleteUserInstrument();
  const { amountValue: amountValueFromCtx, currencySymbol: currencySymbolFromCtx } =
    useAppNeedChange();
  const [availableWallet, updateAvailabilityWallet] = useState(true);
  const dismissErrorDialogAction = {
    type: BFF_ACTION_TYPES.DISMISS_DIALOG,
  };

  const onSubmitError = () => {
    resetDeleteInstrument();
    showDialog({
      fenixLayout: false,
      title: formatMessage(messages.submitErrorTitle),
      primary_action: {
        label: formatMessage(messages.submitErrorActionLabel),
        actions: [dismissErrorDialogAction],
      },
      children: <DeviceErrorIcon />,
      buttonsSize: DIALOG_BUTTONS_SIZE.LARGE,
    });
  };

  const onSubmitDelete = ({ instrumentId, trackingAction }) => {
    if (deletingInstrument) return;
    logger.info(
      '[USER_INSTRUMENT][DELETE_INSTRUMENT_INTENT]',
      `Trying to delete instrument ${instrumentId}`,
    );
    deleteInstrument(instrumentId, {
      onSuccess: (data) => {
        const actions = data?.actions;
        if (actions?.length) {
          logger.info(
            '[USER_INSTRUMENT][DELETE_INSTRUMENT_SUCCESS]',
            `Delete instrument ${instrumentId} successfully`,
            stringify(actions),
          );
          executeBFFActions([trackingAction, ...actions]);
        } else {
          logger.error(
            '[USER_INSTRUMENT][DELETE_INSTRUMENT_NO_SUBMIT_ACTION]',
            'Submit delete response does not have actions',
          );
          onSubmitError();
        }
      },
      onError: (error) => {
        logger.error(
          '[USER_INSTRUMENT][DELETE_INSTRUMENT_SUBMIT_ERROR]',
          'Failed deleting instrument:',
          error.message,
        );
        onSubmitError();
      },
    });
  };

  const goBackAllFlows = () => {
    const isBusinessContextFlow = Boolean(bussinesContext);
    if (isBusinessContextFlow) goCancelCallback({ replace: true });
    else goBack();
  };

  const actionDefinitions = {
    SELECT_INSTRUMENT: () => selectPaymentInstruments(selectedInstruments),
    REFRESH_CURRENT_PAGE: () =>
      invalidateQueriesAndRefetchInactive([QUERIES_NAMES.USER_INSTRUMENTS]),
    DELETE_INSTRUMENT: onSubmitDelete,
    [BFF_ACTION_TYPES.NAVIGATE_BACK]: () => {
      setIsGoingBack(true);
      try {
        const instrumentsFromBFF = getInstrumentsFromBFF({ componentsToFilter: data?.components });
        const shouldReturnEmptySelection = isSelectedInstrumentDeleted({
          instrumentsFromBFF,
          instrumentsFromQuery,
        });
        if (shouldReturnEmptySelection) selectPaymentInstruments([]);
        goBackAllFlows();
      } catch (error) {
        setIsGoingBack(false);
      }
    },
  };
  const { executeBFFActions } = useBFFActionsExecutor(actionDefinitions);

  const onSelectOnlineInstrument = ({ id, enabled }) => {
    if (isInstrumentSelected(selectedInstruments, id) || !enabled) return;
    const selectedFilterInstruments =
      filterInstrumentsByType(selectedInstruments, [
        BFF_INSTRUMENT_TYPES.OFFLINE_INSTRUMENT,
        BFF_INSTRUMENT_TYPES.ONLINE_INSTRUMENT,
      ]) || [];
    updateAvailabilityWallet(true);
    updateSelectedInstruments(dispatch, [
      ...selectedFilterInstruments,
      { id, type: BFF_INSTRUMENT_TYPES.ONLINE_INSTRUMENT },
    ]);
  };

  const onSelectOfflineInstrument = ({ id, enabled }) => {
    if (isInstrumentSelected(selectedInstruments, id) || !enabled) return;
    if (!isPartialWalletSelected(selectedInstruments)) {
      updateAvailabilityWallet(false);
      updateSelectedInstruments(dispatch, [{ id, type: BFF_INSTRUMENT_TYPES.OFFLINE_INSTRUMENT }]);
    }
    // TODO: show warning offline selection combined with wallet
  };

  const onSelectPartialWallet = ({ id }) => {
    if (isOfflineInstrumentSelected(selectedInstruments)) return;
    let selectedFilterInstruments;
    if (isInstrumentSelected(selectedInstruments, id)) {
      selectedFilterInstruments =
        filterInstrumentsByType(selectedInstruments, [
          BFF_INSTRUMENT_TYPES.PARTIAL_WALLET,
          BFF_INSTRUMENT_TYPES.OFFLINE_INSTRUMENT,
        ]) || [];
    } else {
      selectedFilterInstruments = [
        ...selectedInstruments,
        { id, type: BFF_INSTRUMENT_TYPES.PARTIAL_WALLET },
      ];
    }
    updateSelectedInstruments(dispatch, selectedFilterInstruments);
  };

  const handleDeleteInstrument = ({ instrumentId, trackingInfo = {} }) => {
    const trackingDeleteInstrumentAction = (eventName) => {
      return {
        type: 'TRACKING',
        data: {
          event: eventName,
          attributes: { instrumentId, ...trackingInfo },
        },
      };
    };
    showDialog({
      title: formatMessage(messages.deleteInstrumentTitle),
      description: formatMessage(messages.deleteInstrumentDescription),
      primary_action: {
        label: formatMessage(messages.deleteInstrumentActionLabel),
        actions: [
          trackingDeleteInstrumentAction(EVENT_DELETE_INSTRUMENT_CLICKED),
          {
            type: 'DELETE_INSTRUMENT',
            data: {
              instrumentId,
              trackingAction: trackingDeleteInstrumentAction(EVENT_DELETE_INSTRUMENT_CONFIRMATION),
            },
          },
        ],
      },
      secondary_action: {
        label: formatMessage(messages.deleteInstrumentSecondaryActionLabel),
        actions: [dismissErrorDialogAction],
      },
      buttonsSize: DIALOG_BUTTONS_SIZE.LARGE,
      actionDefinitions,
    });
  };

  const isDisabled =
    selectedInstruments?.length === 0 ||
    !isInstrumentTypeSelected(selectedInstruments, [
      BFF_INSTRUMENT_TYPES.ONLINE_INSTRUMENT,
      BFF_INSTRUMENT_TYPES.OFFLINE_INSTRUMENT,
    ]);

  /**
   * Because we don't have instrument's type on selected instruments that delivery us through query params,
   * we get this info from BFF instruments.
   */
  useEffect(() => {
    const instrumentsFromBFF = getInstrumentsFromBFF({ componentsToFilter: data?.components });

    const parsedQueryInstruments = parseInstrumentsFromQuery(queryInstruments);

    if (instrumentsFromBFF.length > 0 && parsedQueryInstruments?.length > 0) {
      const parsedQueryInstrumentsWithType = addTypesToInstruments({
        instrumentsFromBFF: [...instrumentsFromBFF, ...instrumentsFromQuery],
        instrumentsWithoutType: parsedQueryInstruments,
      });
      setInstrumentsFromQuery(parsedQueryInstrumentsWithType);
    }

    if (instrumentsFromBFF.length > 0 && selectedInstruments?.length > 0) {
      const selectedInstrumentsWithType = addTypesToInstruments({
        instrumentsFromBFF,
        instrumentsWithoutType: selectedInstruments,
      });

      if (!isValidInstrumentSelection(selectedInstrumentsWithType)) {
        updateSelectedInstruments(dispatch, []);
        return;
      }
      if (isOfflineInstrumentSelected(selectedInstrumentsWithType)) {
        updateAvailabilityWallet(false);
      }
      updateSelectedInstruments(dispatch, selectedInstrumentsWithType);
    }
  }, [data]);

  const componentResolvers = {
    SECTION: ({ data: { title, subtitle, components } }) => ({
      component: Section,
      props: {
        title,
        subtitle,
        components,
      },
    }),
    CARD: ({ data: { components: cardComponents } }) => ({
      component: UserInstrumentsCard,
      props: {
        components: cardComponents,
      },
    }),
    ONLINE_INSTRUMENT: ({
      data: {
        id,
        title,
        description,
        icon,
        promo,
        deletable,
        tracking_info: trackingInfo,
        enabled = true,
      },
    }) => ({
      component: UserInstrumentItemBFF,
      props: {
        id,
        title,
        subtitle: description,
        icon,
        promo,
        deletable,
        enabled,
        onDeleteInstrument: () => handleDeleteInstrument({ instrumentId: id, trackingInfo }),
        selected: isInstrumentSelected(selectedInstruments, id),
        onSelect: () => onSelectOnlineInstrument({ id, enabled }),
      },
    }),
    OFFLINE_INSTRUMENT: ({
      data: { id, title, currencySymbol: currencySymbolFromBFF, button, icon, enabled = true },
    }) => {
      if (amountValueFromCtx && !currencySymbolFromBFF && !currencySymbolFromCtx)
        logger.warn(
          '[UserInstrumentsBFF][DEFAULT_CURRENCY_SYMBOL]',
          `The DEFAULT_CURRENCY_SYMBOL: '${DEFAULT_CURRENCY_SYMBOL}' is being used`,
        );
      const offlineInstrumentSubtitle = amountValueFromCtx
        ? `${formatMessage(messages.subtitleOfflineInstrument, {
            currencySymbol:
              currencySymbolFromBFF || currencySymbolFromCtx || DEFAULT_CURRENCY_SYMBOL,
            amountValue: amountValueFromCtx,
          })}`
        : undefined;
      return {
        component: UserInstrumentItemBFF,
        props: {
          id,
          title,
          subtitle: offlineInstrumentSubtitle,
          icon,
          button: amountValueFromCtx
            ? {
                ...button,
                text: formatMessage(messages.editOfflineInstrumentTextButton),
              }
            : button,
          enabled,
          selected: isInstrumentSelected(selectedInstruments, id),
          onSelect: () => onSelectOfflineInstrument({ id, enabled }),
        },
      };
    },
    PARTIAL_WALLET: ({ data: { id, title, icon, description } }) => ({
      component: PartialWallet,
      props: {
        id,
        title,
        icon,
        description,
        disabled: !availableWallet,
        selected: isInstrumentSelected(selectedInstruments, id),
        onSelect: () => onSelectPartialWallet({ id }),
      },
    }),
    EMPTY_STATE: ({ data: { title, description, icon } }) => ({
      component: EmptyState,
      props: { title, description, icon },
    }),
    BUTTON: ({ data: { style, text, actions } }) => ({
      component: SimpleButton,
      props: {
        size: style === 'PRIMARY' ? 'full' : 'medium',
        label: text,
        color: style.toLowerCase(),
        disabled: style === 'PRIMARY' && isDisabled,
        onClick: () => executeBFFActions(actions),
      },
    }),
  };

  if (isLoading || isFetching || deletingInstrument || isGoingBack)
    return <PeyaLoader position="center" />;
  if (error) {
    return (
      <BrandErrorContainer
        fenixLayout
        onPrimaryAction={refetch}
        secondaryLabel="Volver"
        onSecondaryAction={() => goBackAllFlows()}
        errorCode={error.response?.status}
      />
    );
  }

  const onAppearPageActions = data?.on_appear?.actions;

  const updatedOnAppearActions = addOriginToTrackingEvent(
    onAppearPageActions,
    'payment_method.loaded',
  );

  const header = data?.bar;
  const components = data?.components;
  const footer = data?.footer?.data;

  return (
    <>
      <BackendDrivenPage
        fenixLayout={false}
        actionDefinitions={actionDefinitions}
        componentResolvers={componentResolvers}
        header={header}
        components={components}
        footer={footer}
        onAppearActions={updatedOnAppearActions}
      />
    </>
  );
};

export default UserInstrumentsBFF;
