import { useMemo } from 'react';
import logger from '../logger';
import { validateTracking } from '@pedidosya/tracking-validator';
import { dismissDialog } from '@app/providers/mainContext/actions/dismissDialog';
import { openDialog } from '@app/providers/mainContext/actions/openDialog';
import { BFF_ACTION_TYPES } from '@app/providers/mainContext/constants';
import { useAppDispatch } from '@app/providers/mainContext/selectors/useAppDispatch';
import { useAppTracker } from '@app/providers/mainContext/selectors/useAppTracker';
import { useCancelChallenge } from './useCancelChallenge';
import { useCompleteChallenges } from './useCompleteChallenges';
import { useGoBack } from './useGoBack';
import { useSelectPaymentInstruments } from './useSelectPaymentInstruments';
import formatEvent from '../hooks/tracking/formatEvent';

export const useBFFActionDefinitions = () => {
  const dispatch = useAppDispatch();
  const { tracker } = useAppTracker();
  const { goBack } = useGoBack();
  const { selectPaymentInstruments } = useSelectPaymentInstruments();
  const { completeChallenges } = useCompleteChallenges();
  const { cancelChallenge } = useCancelChallenge();

  const actionDefinitions = useMemo(
    () => ({
      [BFF_ACTION_TYPES.TRACKING]: ({ event, attributes }) => {
        try {
          // USE (https://github.com/pedidosya/groceries-javascript/tree/master/packages/tracking-validator)
          // enable -> __TRACKING_VALIDATOR__.enable()
          // disable -> __TRACKING_VALIDATOR__.disable()
          validateTracking({ event, payload: attributes });
        } catch (error) {
          logger.info('[useBFFActionDefinitions][VALIDATE_TRACKING][ERROR]');
        }
        tracker.track(formatEvent(event), attributes);
      },
      [BFF_ACTION_TYPES.NAVIGATE_BACK]: () => {
        goBack();
      },
      [BFF_ACTION_TYPES.OPEN_DIALOG]: (payload) => {
        openDialog(dispatch, payload);
      },
      [BFF_ACTION_TYPES.DISMISS_DIALOG]: () => {
        dismissDialog(dispatch);
      },
      [BFF_ACTION_TYPES.SELECT_INSTRUMENT]: (payload) => {
        selectPaymentInstruments([{ id: payload.instrument_id }]);
      },
      [BFF_ACTION_TYPES.FINISH_CHALLENGES_FLOW]: () => {
        completeChallenges();
      },
      [BFF_ACTION_TYPES.CANCEL_FLOW]: (payload) => {
        cancelChallenge(payload?.query_params);
      },
    }),
    [tracker, goBack, dispatch, selectPaymentInstruments, completeChallenges, cancelChallenge],
  );

  return { actionDefinitions };
};
