import format from '../../commons/utils/string/format';
import { URLS } from './constants';
import getAxiosInstance from './axios';
import serviceErrorWrapper from './serviceErrorWrapper';

async function saveChallengeByRequestId(challengeType, requestId, axios = getAxiosInstance()) {
  return serviceErrorWrapper('saveSecurityCodeChallenge', async () => {
    const url = format(URLS.SAVE_CHALLENGE_BY_REQUEST_ID, challengeType, requestId);
    const { data } = await axios.post(url, {});
    return data;
  });
}

export default saveChallengeByRequestId;
