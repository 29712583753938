import isCardHolderValid from '../validators/cardHolder';

const CHARACTER_NOT_ASCII_REGEX = '[^\x20-\x5A]';
const CHARCTER_STRANGE = '[\x21-\x40]';

export default function formatCardHolder(input) {
  let value = String(input).toUpperCase();

  if (!isCardHolderValid(value)) {
    value = value
      .replace(new RegExp(CHARACTER_NOT_ASCII_REGEX, 'g'), '')
      .replace(new RegExp(CHARCTER_STRANGE, 'g'), '');
  }

  return value.slice(0, 26);
}
