import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import InputNumber from '@app/components/InputNumber';
import ClearButton from '@app/components/ClearButton';

const MAX_DECIMAL = 2;

const NeedChangeInput = ({ onChange, value, error, prefix, ...props }) => {
  const { formatMessage } = useIntl();
  const inputRef = useRef(null);

  const handleChange = (event) => {
    const inputValue = event?.target?.value;

    let newValue = inputValue;
    const inputValueIndexOf = inputValue.indexOf('.');
    if (inputValueIndexOf !== -1) {
      newValue = inputValue.slice(0, inputValueIndexOf + MAX_DECIMAL + 1);
    }

    onChange(newValue);
  };

  const handleReset = () => {
    onChange('');
    inputRef.current.focus();
  };

  return (
    <InputNumber
      type="number"
      autoFocus
      label={formatMessage(messages.label)}
      onChange={handleChange}
      value={value}
      helpText={error ? formatMessage(messages.errorMessage) : formatMessage(messages.helpText)}
      error={error}
      prefix={prefix}
      suffix={value ? <ClearButton handleOnClick={handleReset} /> : null}
      inputRef={inputRef}
      {...props}
    />
  );
};

NeedChangeInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  prefix: PropTypes.string,
  error: PropTypes.bool,
};

NeedChangeInput.defaultProps = {
  error: false,
};

export default NeedChangeInput;
