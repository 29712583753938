const DEFAULT_CSC_REGEX = /^[0-9]{3}$/;
/**
 * Validates card security code input with the regex provided or 3 digits as default
 *
 * @param {String} input Tentative card expiration input with format MM/YY.
 * @returns {boolean} true or false whether the input parameter is a valid card expiration.
 */
function isCardSecurityCodeValid(input, cscRegex = DEFAULT_CSC_REGEX) {
  if (!input) return false;

  return cscRegex.test(input);
}

export default isCardSecurityCodeValid;
