import { sessionCache, KEYS as CACHE_KEYS } from '../../../services/cache';
import { ACTION_TYPE } from '../actionsType';
import { STATE_KEYS } from '../constants';

export const updateChallenge = (dispatch, { challengeId, challengeType }) => {
  sessionCache.set(CACHE_KEYS.CHALLENGE, { challengeId, challengeType });
  dispatch({
    type: ACTION_TYPE.UPDATE_CHALLENGE,
    payload: { [STATE_KEYS.CHALLENGE]: { challengeId, challengeType } },
  });
};
