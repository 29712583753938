import { useEffect } from 'react';
import { QUERY_PARAM } from '../../commons/constants';
import { updateCancelCallback } from '../providers/mainContext/actions/updateCancelCallback';
import { updateSuccessCallback } from '../providers/mainContext/actions/updateSuccessCallback';
import { useAppDispatch } from '../providers/mainContext/selectors/useAppDispatch';
import { useAppCallbacks } from '../providers/mainContext/selectors/useAppCallbacks';
import { useQueryParamValue } from '../utils/domRouter';

/**
 * This hook updates callbacks provided by
 * query params when there are new ones
 */
export const usePaymentMethodsCallbacksUpdate = () => {
  const { successCallback, cancelCallback } = useAppCallbacks();
  const dispatch = useAppDispatch();
  const successCallbackFromQuery = useQueryParamValue(QUERY_PARAM.CALLBACKS.SUCCESS);
  const cancelCallbackFromQuery = useQueryParamValue(QUERY_PARAM.CALLBACKS.CANCEL);
  useEffect(() => {
    if (successCallbackFromQuery && successCallbackFromQuery !== successCallback) {
      updateSuccessCallback(dispatch, successCallbackFromQuery);
    }

    if (cancelCallbackFromQuery && cancelCallbackFromQuery !== cancelCallback) {
      updateCancelCallback(dispatch, cancelCallbackFromQuery);
    }
  }, [
    successCallbackFromQuery,
    cancelCallbackFromQuery,
    successCallback,
    cancelCallback,
    dispatch,
  ]);
};
