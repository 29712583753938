import React, { useEffect } from 'react';
import { publishEventsOnDevice } from '@app/utils/loadingEvents';
import { useIsWebview } from '@app/hooks/useDeviceInfo';

let hasPublishedEvent = false;

export const withExternalLoading = (Component) => {
  return (props) => {
    const isWebview = useIsWebview();

    useEffect(() => {
      if (!hasPublishedEvent && isWebview) {
        hasPublishedEvent = true;
        publishEventsOnDevice();
      }
    }, [isWebview]);

    return <Component {...props} />;
  };
};
