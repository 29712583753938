export const pick = (fromObject, properties = []) =>
  fromObject &&
  properties.reduce((p, c) => {
    if (Object.prototype.hasOwnProperty.call(fromObject, c)) {
      // eslint-disable-next-line no-param-reassign
      p[c] = fromObject[c];
    }
    return p;
  }, {});

export function pickWithNoUndefinedValues(fromObject, properties = []) {
  return (
    fromObject &&
    Object.keys(fromObject).reduce((acc, k) => {
      if (properties.includes(k) && fromObject[k] !== undefined) {
        acc[k] = fromObject[k];
      }
      return acc;
    }, {})
  );
}

export function getNestedObject(nestedObj, pathArr, defaultValue) {
  return pathArr.reduce(
    (obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : defaultValue),
    nestedObj,
  );
}

export function ignore(map = {}, propsToFilter = []) {
  return Object.keys(map).reduce((acc, key) => {
    if (propsToFilter.indexOf(key) === -1) acc[key] = map[key];
    return acc;
  }, {});
}

export function iteratorToObject(it) {
  return [...it].reduce((acc, param) => {
    const [key, value] = param;
    acc[key] = value;
    return acc;
  }, {});
}

export function objectToQuery(obj) {
  return Object.keys(obj).reduce((q, k, i) => {
    if (i === 0) return `${k}=${obj[k]}`;
    return `${q}&${k}=${obj[k]}`;
  }, '');
}

export function isNullOrUndefined(o) {
  return o === null || o === undefined;
}

export function hasValue(o) {
  return !isNullOrUndefined(o) && o !== '';
}

export function isObject(o) {
  return typeof o === 'object' && !Array.isArray(o) && o !== null;
}

export default {
  pick,
  ignore,
  iteratorToObject,
  isNullOrUndefined,
};
