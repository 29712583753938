const typeKeys = {
  LOYALTY_SUBSCRIPTION: 'loyalty',
  BNPL_DEBT: 'bnpl',
};

const buildEventName = (suffix) => (checkoutType) => {
  const prefix =
    typeKeys[checkoutType] || (checkoutType && String(checkoutType).toLowerCase()) || '';

  return prefix ? `${prefix}_${suffix}` : suffix;
};

export const TRACKING_EVENTS = {
  PAYMENT_METHOD_CLOSED: buildEventName('payment_method.closed'),
  PAYMENT_METHOD_LOADED: buildEventName('payment_method.loaded'),
  // TODO: What name do we put to this event?
  PAYMENT_ADD_PAYMENT_METHOD_CLICK: buildEventName('online_payment_add_card.clicked'),
  PAYMENT_METHOD_CHOSEN: buildEventName('payment_method.chosen'),
  CODE_VALIDATION_LOADED: buildEventName('cvv_code.loaded'),
  CODE_VALIDATION_CLOSED: buildEventName('cvv_code.closed'),
  CODE_VALIDATION_ADDED: buildEventName('cvv_code.added'),
  ADD_CARD_LOADED: buildEventName('online_payment_add_card.loaded'),
  ADD_CARD_CLOSED: buildEventName('online_payment_add_card.closed'),
  ADD_CARD_ADDED: buildEventName('online_payment_add_card.added'),
  ADD_CARD_FAILED: buildEventName('online_payment_add_card.failed'),
};
