import { BrowserClient, Hub } from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import pkg from '../../../package.json';
import { PROD_ENVIRONMENT } from '@commons/constants/environments';
import { SENTRY_CONFIG } from './constants';

const newCardLegacyRegex = /\/new\/card\/(\w+)\/legacy/g;

let sentryHubInstance;

export const initSentry = ({ environment }) => {
  try {
    const sampleRate = environment && environment === PROD_ENVIRONMENT ? 0.1 : 1;
    const {
      location: { pathname },
    } = window;

    const client = new BrowserClient({
      dsn: SENTRY_CONFIG.dsn,
      integrations: [
        new Integrations.BrowserTracing({
          tracingOrigins: ['localhost', /^\//],
          beforeNavigate: (context) => ({
            ...context,
            name: pathname.replace(newCardLegacyRegex, '/new/card/:vaultProvider/legacy'),
          }),
        }),
      ],
      environment,
      release: `${pkg.name}@${pkg.version}`,
      tracesSampleRate: sampleRate,
      sampleRate,
    });
    sentryHubInstance = new Hub(client);
    return sentryHubInstance;
  } catch (error) {
    // Log to console.error because at this point the Sentry instance did not work
    console.error(error);
    throw error;
  }
};

export default sentryHubInstance;
