import getAxiosInstance from '@app/services/axios';
import format from '@commons/utils/string/format';
import { URLS } from '@app/services/constants';
import serviceErrorWrapper from './serviceErrorWrapper';

async function deleteUserInstrument(instrumentId, axios = getAxiosInstance()) {
  return serviceErrorWrapper('deleteUserInstrument', async () => {
    const url = format(URLS.DELETE_PAYMENT_INSTRUMENT, instrumentId);
    const { data } = await axios.delete(url);
    return data;
  });
}

export default deleteUserInstrument;
