import { useQuery } from 'react-query';
import getNewPaymentInstrumentForm from '../getNewPaymentInstrumentForm';
import getPaymentMethods from '../getPaymentMethods';
import getSecurityCodeValidation from '../getSecurityCodeValidation';
import getUserInstruments from '../getUserInstruments';
import verifyPaymentInstrument from '../verifyPaymentInstrument';
import getChallenges from '../getChallenges';
import getChallengeScreen from '../getChallengeScreen';
import validateHTML from '../html/validateHTML';
import getNeedChange from '../getNeedChange';
import getContextForPMCard from '../getContextForPMCard';
import getPayloadForNewInstrument from '../getPayloadForNewInstrument';
import getDisabledUserInstruments from '../getDisabledUserInstruments';

export const QUERIES_NAMES = {
  USER_INSTRUMENTS: 'USER_INSTRUMENTS',
  DISABLED_USER_INSTRUMENTS: 'DISABLED_USER_INSTRUMENTS',
  SECURITY_CODE_VALIDATION: 'SECURITY_CODE_VALIDATION',
  CARD_NUMBER_VALIDATION: 'CARD_NUMBER_VALIDATION',
  NEW_PAYMENT_INSTRUMENT_FORM: 'NEW_PAYMENT_INSTRUMENT_FORM',
  PAYMENT_METHODS: 'PAYMENT_METHODS',
  EXTERNAL_PROVIDERS: 'EXTERNAL_PROVIDERS',
  CHALLENGES: 'CHALLENGES',
  CHALLENGE_SCREEN: 'CHALLENGE_SCREEN',
  TERMS_AND_CONDITIONS_DETAILS: 'TERMS_AND_CONDITIONS_DETAIL',
  NEED_CHANGE: 'NEED_CHANGE',
  CONTEXT_FOR_PM_CARD: 'CONTEXT_FOR_PM_CARD',
  PAYLOAD_FOR_NEW_INSTRUMENT: 'PAYLOAD_FOR_NEW_INSTRUMENT',
};

const DEFAULT_OPTIONS = {
  retry: false,
};

export const useUserInstruments = () =>
  useQuery([QUERIES_NAMES.USER_INSTRUMENTS], () => getUserInstruments(), {
    ...DEFAULT_OPTIONS,
  });

export const useDisabledUserInstruments = () =>
  useQuery([QUERIES_NAMES.DISABLED_USER_INSTRUMENTS], () => getDisabledUserInstruments(), {
    ...DEFAULT_OPTIONS,
  });

export const useSecurityCodeValidation = (userInstrumentId) =>
  useQuery(
    [QUERIES_NAMES.SECURITY_CODE_VALIDATION, userInstrumentId],
    () => getSecurityCodeValidation(userInstrumentId),
    {
      ...DEFAULT_OPTIONS,
    },
  );

export const useCardNumberValidation = (vaultProvider, bin, options = {}) =>
  useQuery(
    [QUERIES_NAMES.CARD_NUMBER_VALIDATION, vaultProvider, bin],
    () => verifyPaymentInstrument(vaultProvider, bin),
    {
      ...DEFAULT_OPTIONS,
      ...options,
    },
  );

export const useNewPaymentInstrumentForm = (vault) => {
  return useQuery(
    [QUERIES_NAMES.NEW_PAYMENT_INSTRUMENT_FORM, vault],
    () => getNewPaymentInstrumentForm(vault),
    {
      ...DEFAULT_OPTIONS,
    },
  );
};

export const usePaymentMethods = () =>
  useQuery(QUERIES_NAMES.PAYMENT_METHODS, () => getPaymentMethods(), {
    ...DEFAULT_OPTIONS,
  });

export const useChallenges = () =>
  useQuery([QUERIES_NAMES.CHALLENGES], () => getChallenges(), {
    ...DEFAULT_OPTIONS,
  });

export const useChallengeScreen = (challengeType, challengeId) =>
  useQuery(
    [QUERIES_NAMES.CHALLENGE_SCREEN, challengeType, challengeId],
    () => getChallengeScreen(challengeType, challengeId),
    {
      ...DEFAULT_OPTIONS,
    },
  );

export const useTermsAndConditionsDetail = (url) =>
  useQuery([QUERIES_NAMES.TERMS_AND_CONDITIONS_DETAILS, url], () => validateHTML(url), {
    ...DEFAULT_OPTIONS,
  });

export const useNeedChangeScreen = (totalAmount, currencySymbol) =>
  useQuery(
    [QUERIES_NAMES.NEED_CHANGE, totalAmount, currencySymbol],
    () => getNeedChange(totalAmount, currencySymbol),
    {
      ...DEFAULT_OPTIONS,
    },
  );

export const useContextForPMCard = () =>
  useQuery([QUERIES_NAMES.CONTEXT_FOR_PM_CARD], () => getContextForPMCard(), {
    ...DEFAULT_OPTIONS,
  });

export const usePayloadForNewInstrument = (requestId) =>
  useQuery(
    [QUERIES_NAMES.PAYLOAD_FOR_NEW_INSTRUMENT, requestId],
    () => getPayloadForNewInstrument(requestId),
    { ...DEFAULT_OPTIONS },
  );
