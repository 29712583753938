import { invalidateSessionCache } from '@app/providers/mainContext/actions/invalidateSessionCache';
import { useAppCallbacks } from '@app/providers/mainContext/selectors/useAppCallbacks';
import { useAppDispatch } from '@app/providers/mainContext/selectors/useAppDispatch';
import { useCallback } from 'react';
import { useCallbackNavigation } from './useCallbackNavigation';

export const useCompleteChallenges = () => {
  const { successCallback } = useAppCallbacks();
  const dispatch = useAppDispatch();
  const { callbackNavigate } = useCallbackNavigation();

  const completeChallenges = useCallback(() => {
    invalidateSessionCache(dispatch);
    callbackNavigate(successCallback);
  }, [callbackNavigate, dispatch, successCallback]);

  return { completeChallenges };
};
