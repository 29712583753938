import { useBFFActionsExecutor } from '@checkout-ui/backend-driven';
import { useCallback } from 'react';
import { BFF_ACTION_TYPES } from '../providers/mainContext/constants';

export const useShowDialog = () => {
  const { executeBFFActions } = useBFFActionsExecutor();

  const showDialog = useCallback(
    (dialogProps = {}) => {
      const openDialogAction = {
        type: BFF_ACTION_TYPES.OPEN_DIALOG,
        data: dialogProps,
      };

      executeBFFActions([openDialogAction]);
    },
    [executeBFFActions],
  );

  return { showDialog };
};
