export const BIN_LENGTH = 6;

export const INPUT_NAMES = {
  CARD_HOLDER: 'cardHolderName',
  CARD_NUMBER: 'cardNumber',
  CARD_SECURITY_CODE: 'cardSecurityCode',
  CARD_EXPIRATION: 'cardExpiration',
};

export const EMPTY_PLACEHOLDER = ' ';

export const BEFORE_BUTTON_LABEL = 'Anterior';
export const NEXT_BUTTON_LABEL = 'Siguiente';

export const DELAY_TO_FOCUS_INPUT = 300;

export const WARNING_ICON_COLOR = 'negative';

export const NEED_CVV_DEFAULT = true;
