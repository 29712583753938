import React, { useEffect } from 'react';
import { QUERY_PARAM } from '@commons/constants';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
import { useQueryParamValue } from '@app/utils/domRouter';
import { useHardNavigate } from '@app/hooks/useHardNavigate';
import { useAppDispatch } from '@app/providers/mainContext/selectors/useAppDispatch';
import { updateChallenge } from '@app/providers/mainContext/actions/updateChallenge';
import logger from '@app/logger';
import useOnce from '@app/hooks/useOnce';
import { useBFFActionsExecutor } from '@checkout-ui/backend-driven';
import { EVENT_NAME_TRACKING, actionTracking } from '@app/utils/tracking';
import { CHALLENGE_TYPES } from '../../constants';

const ChinChinChallenge = () => {
  const dispatch = useAppDispatch();
  const hardNavigate = useHardNavigate();
  const { executeBFFActions } = useBFFActionsExecutor();
  const challengeId = useQueryParamValue(QUERY_PARAM.CHALLENGE.ID);
  const challengeType = useQueryParamValue(QUERY_PARAM.CHALLENGE.TYPE);
  const url = useQueryParamValue(QUERY_PARAM.URL);

  useOnce(() => {
    executeBFFActions(
      actionTracking(EVENT_NAME_TRACKING.CHALLENGE_LOADED, {
        challengeType: CHALLENGE_TYPES.CHIN_CHIN.toLowerCase(),
      }),
    );
    logger.info(
      '[INTENT_CHINCHIN_CHALLENGE]',
      `challengeId: ${challengeId} - url: ${url} - challengeType: ${challengeType}`,
    );
  });

  useEffect(() => {
    updateChallenge(dispatch, { challengeId, challengeType });
    hardNavigate(url, {}, { replace: true });
  }, [url, hardNavigate, dispatch, challengeId, challengeType]);

  return <PeyaLoader position="center" />;
};

export default ChinChinChallenge;
