import BackendDrivenPage from '@app/components/BackendDrivenPage';
import { SimpleButton } from '@appscore/web-components/index';
import { useBFFActionsExecutor } from '@checkout-ui/backend-driven';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { QUERY_PARAM } from '@commons/constants';
import stringify from '@commons/utils/object/stringify';
import CardErrorIcon from '../../components/CardErrorIcon';
import { BrandErrorContainer } from '../../components/Error';
import Spinner from '../../components/Spinner';
import Text from '../../components/Text/Text';
import { useGoBack } from '@app/hooks/useGoBack';
import { useShowDialog } from '@app/hooks/useShowDialog';
import logger from '../../logger';
import { BFF_ACTION_TYPES } from '@app/providers/mainContext/constants';
import { useNewCardTokenization, useSubmitNewDebitOrCreditCard } from '@app/services/mutations';
import { useNewPaymentInstrumentForm } from '@app/services/queries';
import { useQueryParamValue } from '@app/utils/domRouter';
import messages from '../NewDebitOrCreditCard/components/NewCardForm/providersForms/SpreedlyForm/messages';
import SupportedCards from './components/NewCardForm/components/SupportedCards';
import SpreedlyFormBFF from './components/NewCardForm/providersForms/SpreedlyForm/SpreedlyFormBFF';
import useOnce from '@app/hooks/useOnce';

const NewDebitOrCreditCardBFF = () => {
  const { goBack } = useGoBack();
  const vaultProvider = useQueryParamValue(QUERY_PARAM.VAULT_PROVIDER);
  const { formatMessage } = useIntl();
  const { data, isLoading, error, refetch } = useNewPaymentInstrumentForm(vaultProvider);
  const { mutateAsync: createTokenForCard } = useNewCardTokenization(vaultProvider);
  const { mutateAsync: addNewPaymentInstrument } = useSubmitNewDebitOrCreditCard(vaultProvider);

  const [validatedCard, setValidateCard] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [isValidForm, setValidForm] = useState(false);
  const [formData, setFormData] = useState({});

  const onAppearPageActions = data?.on_appear?.actions;
  const header = { ...data?.bar, isOnlyAction: true };
  const components = data?.components;
  const footer = { ...data?.footer?.data };

  const { showDialog: showErrorDialog } = useShowDialog();

  useOnce(() =>
    logger.error(
      '[NewDebitOrCreditCardBFF][ERROR]',
      `This form should not be showing. url: ${window.location.href}`,
    ),
  );

  const onSubmitError = () => {
    const dismissErrorDialogAction = {
      type: BFF_ACTION_TYPES.DISMISS_DIALOG,
    };
    showErrorDialog({
      fenixLayout: false,
      title: formatMessage(messages.submitErrorTitle),
      description: formatMessage(messages.submitErrorDescription),
      primary_action: {
        label: formatMessage(messages.submitErrorActionLabel),
        actions: [dismissErrorDialogAction],
      },
      children: <CardErrorIcon />,
    });
  };

  const onSubmit = async () => {
    if (!isValidForm) return;
    setSubmitting(true);
    try {
      const tokenizationResult = await createTokenForCard(formData);
      logger.debug(
        '[NewDebitOrCreditCardBFF][SPREEDLY_NEW_TOKENIZATION_BFF]',
        'New card tokenization result',
        stringify(tokenizationResult),
      );

      const spreedlyPaymentMethod = tokenizationResult?.data?.transaction?.payment_method;
      logger.info(
        '[NewDebitOrCreditCardBFF][SPREEDLY_CARD_BRAND_BFF]',
        `Card brand from backend: ${validatedCard?.brand}, card brand from spreedly ${spreedlyPaymentMethod?.card_type}`,
      );
      const vaultData = {
        fingerprint: spreedlyPaymentMethod?.fingerprint,
        card: {
          bin: spreedlyPaymentMethod?.first_six_digits,
          brand: validatedCard?.brand,
          four_last_digit: spreedlyPaymentMethod?.last_four_digits,
          vault: vaultProvider,
          vault_token: spreedlyPaymentMethod?.token,
          holder_name: spreedlyPaymentMethod?.full_name,
          operation_type: validatedCard?.type,
          expiration: {
            year: spreedlyPaymentMethod?.year,
            month: spreedlyPaymentMethod?.month,
          },
        },
      };

      const { actions } = await addNewPaymentInstrument(vaultData);
      logger.info(
        '[NewDebitOrCreditCardBFF][ADD_NEW_PAYMENT_INSTRUMENT_BFF]',
        `Card added successfully, next actions: ${stringify(actions)}`,
      );
      executeBFFActions(actions);
      setSubmitting(false);
    } catch (err) {
      logger.error(
        '[NewDebitOrCreditCardBFF][NEW_CARD_SUBMIT_ERROR]',
        'Fail submitting new card:',
        err.message,
      );
      onSubmitError();
      setSubmitting(false);
    }
  };

  const actionDefinitions = {
    ADD_INSTRUMENT: () => onSubmit(),
  };
  const { executeBFFActions } = useBFFActionsExecutor(actionDefinitions);

  const componentResolvers = {
    TEXT: ({ data: { value, style } }) => ({ component: Text, props: { value, style } }),
    SUPPORTED_CARDS: ({ data: { cards } }) => ({
      component: SupportedCards,
      props: { cards, selectedCard: validatedCard },
    }),
    FORM: ({ data: { components } }) => ({
      component: SpreedlyFormBFF,
      props: {
        components,
        onValidatedCardChange: (cardValidate) => setValidateCard(cardValidate),
        onValidForm: (isValidForm) => setValidForm(isValidForm),
        onFormData: (data) => setFormData(data),
      },
    }),
    BUTTON: ({ data: { style, text, actions } }) => ({
      component: SimpleButton,
      props: {
        disabled: !isValidForm || submitting,
        size: 'full',
        label: text,
        color: style.toLowerCase(),
        onClick: () => executeBFFActions(actions),
      },
    }),
  };

  if (isLoading) return <Spinner />;
  if (error) {
    return (
      <BrandErrorContainer
        primaryLabel="Intentar nuevamente"
        onPrimaryAction={refetch}
        secondaryLabel="Volver"
        onSecondaryAction={() => goBack()}
        errorCode={error.response?.status}
      />
    );
  }

  return (
    <>
      <BackendDrivenPage
        fenixLayout={false}
        componentResolvers={componentResolvers}
        header={header}
        components={components}
        footer={footer}
        onAppearActions={onAppearPageActions}
      />
    </>
  );
};

export default NewDebitOrCreditCardBFF;
