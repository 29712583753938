import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useBFFActionsExecutor, useBFFNavigate } from '@checkout-ui/backend-driven';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
import logger from '@app/logger';
import useOnce from '@app/hooks/useOnce';
import { useShowDialog } from '@app/hooks/useShowDialog';
import { useGoSuccessCallback } from '@app/hooks/useGoSuccessCallback';
import { BFF_ACTION_TYPES } from '@app/providers/mainContext/constants';
import { useAppSecurityCode } from '@app/providers/mainContext/selectors/useAppSecurityCode';
import { useSaveChallengeByRequestId } from '@app/services/mutations';
import { ERROR_CODE_TRACKING, EVENT_NAME_TRACKING, actionTracking } from '@app/utils/tracking';
import stringify from '@commons/utils/object/stringify';
import messages from './messages';
import { CHALLENGE_TYPES } from '../Challenges/constants';
import { useQueryParamValue } from '@app/utils/domRouter';
import { QUERY_PARAM } from '@commons/constants';
import { useGoCancelCallback } from '@app/hooks/useGoCancelCallback';

const CHALLENGE_TYPE_CVV = 'CVV_CHALLENGE';

const SecurityCodeExternalSuccessCallback = () => {
  const { formatMessage } = useIntl();
  const requestId = useQueryParamValue(QUERY_PARAM.REQUEST_ID);
  const securityCodeData = useAppSecurityCode();
  const challengeId = securityCodeData?.challengeId;
  const instrumentId = securityCodeData?.instrumentId;
  const { navigate } = useBFFNavigate();
  const { showDialog: showErrorDialog } = useShowDialog();
  const { goSuccessCallback } = useGoSuccessCallback();
  const { goCancelCallback } = useGoCancelCallback();
  const { reset: resetSubmitChallenge, mutate: submitChallenge } = useSaveChallengeByRequestId(
    CHALLENGE_TYPE_CVV,
    requestId,
  );
  const actionDefinitions = {
    [BFF_ACTION_TYPES.NAVIGATE]: (payload) => {
      // NOTE: replace navigation in order to prevent user going back to previous submitted challenges
      navigate(payload, { replace: true });
    },
  };
  const { executeBFFActions } = useBFFActionsExecutor(actionDefinitions);

  const onSubmitError = useCallback(
    (confirmChallenge) => {
      resetSubmitChallenge();
      const dismissErrorDialogAction = {
        type: BFF_ACTION_TYPES.DISMISS_DIALOG,
      };
      const retryAction = {
        type: 'SUBMIT_CVV',
      };
      const goCancelFlowAction = {
        type: BFF_ACTION_TYPES.CANCEL_FLOW,
      };

      showErrorDialog({
        fenixLayout: true,
        title: formatMessage(messages.submitErrorTitle),
        description: formatMessage(messages.submitErrorDescription),
        primary_action: {
          label: formatMessage(messages.submitErrorActionLabel),
          actions: [dismissErrorDialogAction, retryAction],
        },
        secondary_action: {
          label: formatMessage(messages.dismissErrorActionLabel),
          actions: [goCancelFlowAction],
        },
        actionDefinitions: {
          SUBMIT_CVV: confirmChallenge,
        },
      });
    },
    [formatMessage, resetSubmitChallenge, showErrorDialog],
  );

  const confirmChallenge = useCallback(() => {
    logger.info(
      '[SECURITY_CODE_EXTERNAL_SUCCESS_CALLBACK][CHALLENGE][CVV_SAVE_INTENT]',
      'Trying to save CVV challenge',
    );
    submitChallenge(
      {},
      {
        onSuccess: (data) => {
          const actions = data?.actions;
          if (actions?.length) {
            logger.info(
              '[SECURITY_CODE_EXTERNAL_SUCCESS_CALLBACK][CHALLENGE][CVV_SAVE_SUCCESS]',
              'CVV was saved successfully',
              stringify(actions),
            );
            executeBFFActions(actions);
          } else {
            executeBFFActions(
              actionTracking(EVENT_NAME_TRACKING.CHALLENGE_FAILED, {
                challengeType: CHALLENGE_TYPES.CVV.toLowerCase(),
                errorCode: ERROR_CODE_TRACKING.SUBMIT_ERROR,
              }),
            );
            logger.error(
              '[SECURITY_CODE_EXTERNAL_SUCCESS_CALLBACK][CHALLENGE][CVV_SAVE_NO_ACTION]',
              'Submit CVV Challenge response do not have actions',
            );
            onSubmitError(confirmChallenge);
          }
        },
        onError: (error) => {
          executeBFFActions(
            actionTracking(EVENT_NAME_TRACKING.CHALLENGE_FAILED, {
              challengeType: CHALLENGE_TYPES.CVV.toLowerCase(),
              errorCode: ERROR_CODE_TRACKING.SUBMIT_ERROR,
            }),
          );
          logger.error(
            '[SECURITY_CODE_EXTERNAL_SUCCESS_CALLBACK][CHALLENGE][CVV_SAVE_ERROR]',
            'Fail saving CVV:',
            error.message,
          );
          onSubmitError(confirmChallenge);
        },
      },
    );
  }, [executeBFFActions, onSubmitError, submitChallenge]);

  const handleSubmit = useCallback(() => {
    if (challengeId) return confirmChallenge();
    if (instrumentId) {
      // NOTE: this flow is only used by checkout-web microsite for loyalty-flow
      // the endgame should be always with challenge ID present
      logger.info(
        '[SECURITY_CODE_EXTERNAL_SUCCESS_CALLBACK][GO_SUCCESS_CALLBACK]',
        `Security code submitted successfully, navigating to callback. instrumentId: ${instrumentId}`,
      );
      return goSuccessCallback();
    }
    logger.error(
      '[SECURITY_CODE_EXTERNAL_SUCCESS_CALLBACK][ERROR]',
      `challengeId: ${challengeId} - instrumentId: ${instrumentId}`,
    );
    goCancelCallback();
  }, [challengeId, confirmChallenge, goCancelCallback, goSuccessCallback, instrumentId]);

  useOnce(() => {
    logger.info('[SECURITY_CODE_EXTERNAL_SUCCESS_CALLBACK]');
    handleSubmit();
  });

  return <PeyaLoader position="center" />;
};

export default SecurityCodeExternalSuccessCallback;
