import { TIME_STRING_OPTION } from './constants';

export function stringToDate(dateString) {
  if (!dateString) return null;
  return dateString ? new Date(dateString.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')) : null;
}

export const longMonthOptions = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
};

export function stringToTime(stringDate, { withSeconds = false } = {}) {
  const date = parseDate(stringDate);
  if (!date) return null;
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = '';

  if (minutes.toString().length === 1) minutes = `0${minutes}`;
  if (hours.toString().length === 1) hours = `0${hours}`;
  if (withSeconds) {
    seconds = date.getSeconds();
    if (seconds.toString().length === 1) seconds = `0${seconds}`;
    seconds = `:${seconds}`;
  }

  return `${hours}:${minutes}${seconds}`;
}

export function getCurrentMonth() {
  const today = new Date();
  return today.getMonth() + 1;
}

export function getCurrentYear() {
  const today = new Date();
  return today.getFullYear();
}

/**
 * This method should be used insted of new Date(isoStringDate)
 * to avoid date problems in iOS.
 * @param {string [new Date().toISOString()]} isoStringDate
 */
export function parseDate(isoStringDate) {
  if (!isoStringDate) return null;
  const dateParts = isoStringDate.split(/[^0-9]/);
  const [year, month, day, hour, minutes, seconds] = dateParts;
  const date = new Date(year, month - 1, day, hour, minutes, seconds);
  return date;
}

export const getLocalTime = () => {
  const date = new Date();
  return date.getHours() * 100 + date.getMinutes();
};

export function stringTimeToUnixStamp(stringDate) {
  const date = parseDate(stringDate);
  return date.getTime() / 1000;
}

export function getNowTimeToString(option) {
  const now = new Date();

  let nowHours = now.getHours();
  let nowMinutes = now.getMinutes();
  let nowSeconds = now.getSeconds();

  if (nowHours.toString().length === 1) nowHours = `0${nowHours}`;
  if (nowMinutes.toString().length === 1) nowMinutes = `0${nowMinutes}`;
  if (nowSeconds.toString().length === 1) nowSeconds = `0${nowMinutes}`;

  switch (option) {
    case TIME_STRING_OPTION.HOURS:
      return `${nowHours}`;

    case TIME_STRING_OPTION.MINUTES:
      return `${nowHours}:${nowMinutes}`;

    case TIME_STRING_OPTION.SECONDS:
      return `${nowHours}:${nowMinutes}:${nowSeconds}`;

    default:
      return ``;
  }
}

export function isDateLegalAge(stringDate, ageOfMajority = 18) {
  const now = new Date();
  const parsedDate = parseDate(stringDate);
  const date = parsedDate.setFullYear(parsedDate.getFullYear() + ageOfMajority);
  return date <= now;
}

export function isValidDate(date) {
  const [day, month, year] = date.split('/');
  if (day && month && year && Number(year) >= 1900) {
    const minDate = new Date(1900, 1, 1);
    const maxDate = new Date();
    const parsedDate = stringToDate(date);
    return !Number.isNaN(parsedDate.getTime()) && parsedDate >= minDate && parsedDate <= maxDate;
  }
  return false;
}

export function stringToIsoDate(stringDate) {
  return stringDate
    ? stringToDate(stringDate)
        .toISOString()
        .replace(/\.\d{3}/, '')
    : false;
}

export function getDateNowToLocaleStringWithoutTime(stringDate) {
  if (!stringDate) return '';
  const dateParts = stringDate.split(/[^0-9]/);
  const [year, month, day] = dateParts;
  const date = new Date(year, month - 1, day);
  return date.toLocaleDateString();
}

export function getDateNowToISOString() {
  return new Date().toISOString();
}

const EXPIRATION_FORMAT_MMYY_PARTS = 2;
const MONTH_AND_YEAR_SEPARATOR = '/';
const ONLY_TWO_DIGIT_REGEX = /^\d{2}$/;
/**
 *
 * @param {String} date receives MM/YY date format and returns month and full year
 * @returns {Array: [month, year]} returns month and year parsed or nullish fields if could not be parsed
 */
export function parseMMYYtoMonthAndYear(mmyy) {
  if (!mmyy) return [null, null];

  const dateParts = mmyy.split(MONTH_AND_YEAR_SEPARATOR);

  if (dateParts.length !== EXPIRATION_FORMAT_MMYY_PARTS) return [null, null];

  const [month, partialYear] = dateParts;

  if (!ONLY_TWO_DIGIT_REGEX.test(month) || !ONLY_TWO_DIGIT_REGEX.test(partialYear))
    return [null, null];

  const currentYear = getCurrentYear();
  return [Number(month), Number(currentYear - (currentYear % 100)) + Number(partialYear)];
}
