import { useCallback } from 'react';
import { useQueryParamValue } from '@app/utils/domRouter';
import { QUERY_PARAM } from '@commons/constants';
import logger from '@app/logger';
import messages from '../messages';
import { useIntl } from 'react-intl';

export const useWebpayHandler = () => {
  const { formatMessage } = useIntl();
  const { TBK_TOKEN, TBK_ID_SESION, TBK_ORDEN_COMPRA, TOKEN_WS } =
    QUERY_PARAM.CHALLENGE_CALLBACK.WEBPAY;
  const webPayTBKToken = useQueryParamValue(TBK_TOKEN) || '';
  const webPayTBKIdSesion = useQueryParamValue(TBK_ID_SESION) || '';
  const webPayTBKOrdenCompra = useQueryParamValue(TBK_ORDEN_COMPRA) || '';
  const webPayTokenWS = useQueryParamValue(TOKEN_WS) || '';

  const webpayHandler = useCallback(
    (onSuccess, onError, onCancel) => {
      // List of possible flows https://www.transbankdevelopers.cl/documentacion/webpay-plus#resumen-de-flujos
      const normalFlow = {
        loggerTag: 'SUCCESS_FLOW',
        validation: () => webPayTokenWS != '',
        action: onSuccess,
        isError: false,
      };
      const timeoutFlow = {
        loggerTag: 'TIMEOUT_FLOW',
        validation: () => webPayTBKIdSesion != '' && webPayTBKOrdenCompra != '',
        action: () => onError(formatMessage(messages.webpayTimeoutFlowMessage)),
        isError: true,
      };
      const abortFlow = {
        loggerTag: 'ABORT_FLOW',
        validation: () =>
          webPayTBKToken != '' && webPayTBKIdSesion != '' && webPayTBKOrdenCompra != '',
        action: onCancel,
        isError: false,
      };
      const errorFlow = {
        loggerTag: 'ERROR_FLOW',
        validation: () =>
          webPayTokenWS != '' &&
          webPayTBKToken != '' &&
          webPayTBKIdSesion != '' &&
          webPayTBKOrdenCompra != '',
        action: () => onError(formatMessage(messages.webpayErrorFlowMessage)),
        isError: true,
      };
      const unknownFlow = {
        loggerTag: 'UNKNOWN_FLOW',
        validation: () => true,
        action: () => onError(formatMessage(messages.webpayUnknownFlowMessage)),
        isError: true,
      };
      const possibleEndOfFlows = [errorFlow, normalFlow, abortFlow, timeoutFlow, unknownFlow];

      for (const flow of possibleEndOfFlows) {
        const { validation, isError, loggerTag, action } = flow;
        if (validation()) {
          const loggerType = isError ? logger.error : logger.info;
          loggerType(
            `[useProviderHandlerCallback][WEBPAY][${loggerTag}]`,
            `${TOKEN_WS}: ${webPayTokenWS} - ${TBK_TOKEN}: ${webPayTBKToken} - ${TBK_ID_SESION}: ${webPayTBKIdSesion} - ${TBK_ORDEN_COMPRA}: ${webPayTBKOrdenCompra}`,
          );
          action();
          return;
        }
      }
    },
    [
      TBK_ID_SESION,
      TBK_ORDEN_COMPRA,
      TBK_TOKEN,
      TOKEN_WS,
      formatMessage,
      webPayTBKIdSesion,
      webPayTBKOrdenCompra,
      webPayTBKToken,
      webPayTokenWS,
    ],
  );

  return { webpayHandler };
};
