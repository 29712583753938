import { isOnlyDigitString } from '@commons/utils/string/isOnlyDigitString';
const SECURITY_CODE_MAX_LENGTH = 4;

/**
 * Formats card security code, allowing only digits and max length
 *
 * @param {string} cardSecurityCode Alphanumeric and non-alphanumeric characters.
 * @param {number} cardSecurityLength Maximum length that the security code can have
 * @returns {string} A formatted card security code
 */
export default function formatCardSecurityCode(
  cardSecurityCode = '',
  cardSecurityLength = SECURITY_CODE_MAX_LENGTH,
) {
  // dismiss no digit characters
  if (!isOnlyDigitString(cardSecurityCode)) {
    return cardSecurityCode.slice(0, -1);
  }

  return cardSecurityCode.slice(0, parseInt(cardSecurityLength));
}
