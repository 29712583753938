import React, { useState } from 'react';
import styled from 'styled-components';
import CodeField from '@pedidosya/web-fenix/atoms/CodeField';
import CodeInput from '@pedidosya/web-fenix/molecules/CodeInput';
import PropTypes from 'prop-types';
import { isUserPressedBackspaceKey } from './utils/isUserPressedBackspaceKey';
import { createDefaultItems } from './utils/createDefaultItems';
import { codeInputValuesToString } from './utils/codeInputValuesToString';
import { isOnlyDigitString } from '@commons/utils/string/isOnlyDigitString';

const StyledCodeInput = styled(CodeInput)`
  margin-top: 24px;
  align-items: center;
  > div > div:last-child {
    padding-right: 0;
  }
`;

const TigoOTPInput = ({ onChange, totalNumberOfInputs }) => {
  const defaultItems = createDefaultItems(totalNumberOfInputs);
  const [codeInputValues, setCodeInputValues] = useState(defaultItems.items);

  const handleFocusPosition = (event) => {
    const {
      target: {
        value,
        maxLength,
        parentNode: { previousElementSibling, nextElementSibling },
      },
    } = event;

    if (isUserPressedBackspaceKey(event)) {
      if (previousElementSibling) {
        previousElementSibling.firstChild.focus();
      }
      return;
    }

    if (!isOnlyDigitString(value)) {
      event.target.value = '';
      return;
    }

    if (value.length === maxLength && nextElementSibling) {
      nextElementSibling.firstChild.focus();
    }
  };

  const handleChange = (event) => {
    const { value, name } = event.target;

    const data = {
      ...codeInputValues,
      [name]: isOnlyDigitString(value) ? value : '',
    };
    setCodeInputValues(data);
    onChange(codeInputValuesToString(data));
  };

  return (
    <StyledCodeInput state="active" data-testid="code-field">
      {defaultItems.fieldNames.map((fieldName, index) => {
        const isFirstCodeInput = index === 0;
        const inputState = codeInputValues[fieldName] || isFirstCodeInput ? 'active' : 'enabled';
        return (
          <CodeField
            key={fieldName}
            autoFocus={isFirstCodeInput}
            value={codeInputValues[fieldName]}
            name={fieldName}
            onKeyUp={handleFocusPosition}
            onFocus={(event) => event.target.select()}
            onChange={handleChange}
            autoComplete="off"
            pattern="[0-9]*"
            inputMode="numeric"
            state={inputState}
          />
        );
      })}
    </StyledCodeInput>
  );
};

TigoOTPInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  totalNumberOfInputs: PropTypes.number.isRequired,
};

export default TigoOTPInput;
