import { defineMessages } from 'react-intl';

export default defineMessages({
  editOfflineInstrumentTextButton: {
    id: 'app.userInstruments.editOfflineInstrumentButton.text',
    defaultMessage: 'Editar',
  },
  subtitleOfflineInstrument: {
    id: 'app.userInstruments.offlineInstrument.subtitle',
    defaultMessage: 'Pagás con {currencySymbol}{amountValue}',
  },
  deleteInstrumentTitle: {
    id: 'app.userInstruments.deleteInstrument.title',
    defaultMessage: '¿Quieres eliminar este medio de pago?',
  },
  deleteInstrumentDescription: {
    id: 'app.userInstruments.deleteInstrument.description',
    defaultMessage: 'Tendrás que agregarlo de nuevo para volver a usarlo.',
  },
  deleteInstrumentActionLabel: {
    id: 'app.userInstruments.deleteInstrument.actionLabel',
    defaultMessage: 'Sí, eliminarlo',
  },
  deleteInstrumentSecondaryActionLabel: {
    id: 'app.userInstruments.deleteInstrument.secondaryActionLabel',
    defaultMessage: 'No',
  },
  submitErrorTitle: {
    id: 'app.userInstruments.deleteInstrument.submitError.title',
    defaultMessage: 'No pudimos eliminar el medio de pago',
  },
  submitErrorActionLabel: {
    id: 'app.userInstruments.deleteInstrument.submitError.actionLabel',
    defaultMessage: 'Intentar de nuevo',
  },
  unavailablePaymentMethod: {
    id: 'app.userInstruments.unavailableInstrument.label',
    defaultMessage: 'NO DISPONIBLE',
  },
});
