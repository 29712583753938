import React from 'react';
import styled from 'styled-components';

import CardContainer from '@pedidosya/web-fenix/molecules/CardContainer';
import Divider from '@pedidosya/web-fenix/atoms/Divider';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import TermsAndConditions from '@checkout-ui/terms-and-conditions';

export const StyledTyC = styled(TermsAndConditions)`
  margin-top: ${({ theme }) => theme.space('spacing-08')};
`;

export const StyledCard = styled(CardContainer)`
  display: flex;
  flex-direction: ${({ direction }) => direction};
`;

const StyledDivide = styled(Divider)`
  order: unset;
`;
export const StyledDivider = () => <StyledDivide />;

export const TextFenix = ({ value, style: { token, color } }) => {
  return (
    <Typography component="span" token={token} color={color}>
      {value}
    </Typography>
  );
};
