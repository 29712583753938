import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.paymentMethod.securityCodeModal.title',
    defaultMessage: 'Código de seguridad',
  },
  subtitle: {
    id: 'app.paymentMethod.securityCodeModal.subtitle',
    defaultMessage:
      'Para la mayoría de las tarjetas, el código de seguridad es un número de 3 dígitos localizados en el dorso. En el caso de American Express, son 4 dígitos en el frente.',
  },
  buttonLabel: {
    id: 'app.paymentMethod.securityCodeModal.buttonLabel',
    defaultMessage: 'Cerrar',
  },
  bottomSheetButtonLabel: {
    id: 'app.paymentMethod.securityCodeBottomSheet.buttonLabel',
    defaultMessage: 'Entendido',
  },
});
