import { useEffect } from 'react';

const DEFAULT_SHELL_META_VIEWPORT_CONTENT = 'width=device-width, initial-scale=1.0';

export const useDisableUserScale = () => {
  useEffect(() => {
    var mvp = document.getElementById('checkout-shell-viewport');
    const mvpContent = mvp?.getAttribute('content');
    mvp?.setAttribute(
      'content',
      `${
        mvpContent ? mvpContent : DEFAULT_SHELL_META_VIEWPORT_CONTENT
      }, maximum-scale=1.0, user-scalable=no`,
    );
    return () => {
      mvp?.setAttribute('content', mvpContent ?? DEFAULT_SHELL_META_VIEWPORT_CONTENT);
    };
  }, []);
};
