const BACKSPACE_KEYS = {
  BACKSPACE: 'Backspace',
  DELETE: 'Delete',
};

/**
 * Validate if an user pressed backspace or delete key
 * @param {Object} event
 * @param {String} event.key value key pressed
 * @param {String} event.code value code pressed
 * @returns {Boolean}
 */
export function isUserPressedBackspaceKey({ key, code }) {
  return Boolean(
    key === BACKSPACE_KEYS.BACKSPACE ||
      key === BACKSPACE_KEYS.DELETE ||
      code === BACKSPACE_KEYS.BACKSPACE ||
      code === BACKSPACE_KEYS.DELETE,
  );
}
