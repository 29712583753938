import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Card, Switch, Image } from '@appscore/web-components';

const WalletContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const Container = styled(Card)`
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(8)};
`;
const Aside = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.span`
  line-height: ${({ theme }) => theme.spacing(10)};
  ${({ theme }) => theme.fontFamily.secondaryFont.smallRegular}
  color: ${({ theme }) => theme.colors.night_blue_1};
`;

const Description = styled.span`
  ${({ theme }) => theme.fontFamily.secondaryFont.smallRegular}
  color: ${({ theme }) => theme.colors.night_blue_1};
`;

const AnimationContainer = styled.div`
  ${({ show }) =>
    show
      ? css`
          line-height: 1;
          padding-top: ${({ theme }) => theme.spacing(8)};
          padding-bottom: ${({ theme }) => theme.spacing(6)};
        `
      : css`
          line-height: 0;
          opacity: 0;
          transform: translateY(-10px);
        `}
  transition: 500ms ease-out;
`;

const Logo = styled(Image)`
  padding-right: ${({ theme }) => theme.spacing(6)};
`;

const PartialWallet = ({ id, title, icon, description, disabled, selected, onSelect }) => {
  return (
    <WalletContainer role="switch" data-testid={id} aria-checked={selected.toString()}>
      <Container direction="row">
        <Aside>
          <Logo alt="wallet" src={icon} width={31} height={32} />
          <Title>{title}</Title>
        </Aside>
        <Switch
          ariaLabel="switchWallet"
          checked={selected}
          disabled={disabled}
          onPress={onSelect}
        />
      </Container>
      <AnimationContainer show={selected}>
        <Description>{description}</Description>
      </AnimationContainer>
    </WalletContainer>
  );
};

PartialWallet.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  description: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};
export default PartialWallet;
