import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DOMPurify from 'dompurify';

import Typography from '@pedidosya/web-fenix/atoms/Typography';

const StyledSection = ({ title, subtitle, children }) => {
  const Container = styled.div`
    display: flex;
    flex-direction: column;
  `;

  const TextContainer = styled.div`
    padding: ${({ theme }) => theme.space('spacing-08')};

    h1,
    h2 {
      margin: 0;
    }
    h2 {
      padding-top: ${({ theme }) => theme.space('spacing-06')};
    }
  `;

  return (
    <Container>
      <TextContainer>
        <Typography
          component="h1"
          token="text-title-medium"
          color="text-color-primary"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }}
        />
        <Typography token="text-base-large" color="text-color-secondary" component="h2">
          {subtitle}
        </Typography>
      </TextContainer>
      {children}
    </Container>
  );
};

StyledSection.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default StyledSection;
