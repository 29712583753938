export const headerKeys = {
  paymentMethods: 'mock-payment-methods',
  paymentInstruments: 'mock-payment-instruments',
  disabledInstruments: 'mock-disabled-instruments',
  paymentForms: 'mock-payment-forms',
  paymentInstrumentVerify: 'mock-payment-instrument-verify',
  paymentInstrumentDelete: 'mock-payment-instrument-delete',
  challenges: 'mock-challenges',
  challenge: 'mock-challenge',
  saveChallenge: 'mock-save-challenge',
  environment: 'mock-environment',
  needChange: 'mock-need-change',
  context: 'mock-context',
  transactionToken: 'mock-transaction-token',
};

export const NOT_FOUND_MOCK = {
  status: 404,
  data: {
    messages: ['NOT_FOUND'],
    code: 'NOT_FOUND',
  },
};

export const INTERNAL_SERVER_ERROR_MOCK = {
  status: 500,
  data: {
    messages: ['INTERNAL_SERVER_ERROR'],
    code: 'INTERNAL_SERVER_ERROR',
  },
};
