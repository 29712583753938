import { sessionCache, KEYS as CACHE_KEYS } from '../../../services/cache';
import { ACTION_TYPE } from '../actionsType';
import { STATE_KEYS } from '@app/providers/mainContext/constants';

export const updateSelectedInstruments = (dispatch, instruments) => {
  sessionCache.set(CACHE_KEYS.SELECTED_INSTRUMENTS, instruments);
  dispatch({
    type: ACTION_TYPE.SELECT_INSTRUMENTS,
    payload: { [STATE_KEYS.SELECTED_INSTRUMENTS]: instruments },
  });
};
