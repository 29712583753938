import BackendDrivenPage from '@app/components/BackendDrivenPage';
import BlockText from '@app/components/BlockText';
import { BrandErrorContainer } from '@app/components/Error';
import { useGoBack } from '@app/hooks/useGoBack';
import { useShowDialog } from '@app/hooks/useShowDialog';
import logger from '@app/logger';
import { updateTermsAndConditionsStatus } from '@app/providers/mainContext/actions/updateTermsAndConditionsStatus';
import { BFF_ACTION_TYPES } from '@app/providers/mainContext/constants';
import { useAppDispatch } from '@app/providers/mainContext/selectors/useAppDispatch';
import { useAppTermsAndConditionsStatus } from '@app/providers/mainContext/selectors/useAppTermsAndConditionsStatus';
import { useSaveChallenge } from '@app/services/mutations';
import { useChallengeScreen } from '@app/services/queries';
import { useQueryParamValue } from '@app/utils/domRouter';
import { useBFFActionsExecutor, useBFFNavigate } from '@checkout-ui/backend-driven';
import { QUERY_PARAM } from '@commons/constants';
import stringify from '@commons/utils/object/stringify';
import React from 'react';
import { useIntl } from 'react-intl';
import { StyledCard, StyledDivider, StyledTyC, TextFenix } from './components/index';
import messages from './messages';
import { default as styled } from 'styled-components';
import Button from '@pedidosya/web-fenix/atoms/Button';
import ChangeBodyBackgroundColor from '@app/components/ChangeBodyBackgroundColor';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
import StyledSection from './components/StyledSection';
import RowList from './components/RowList/RowList';
import PriceBoxRow from './components/PriceBoxRow/PriceBoxRow';

const CHALLENGE_TYPE_BNPL = 'BNPL';

const StyledBackendDrivenPage = styled(BackendDrivenPage)`
  position: relative;
  > div:first-child {
    margin-top: -${({ theme }) => theme.space('spacing-08')};
  }
  padding-top: 0;
`;

const BuyNowPayLaterChallenge = () => {
  const { navigate } = useBFFNavigate();
  const { formatMessage } = useIntl();
  const challengeId = useQueryParamValue(QUERY_PARAM.CHALLENGE_ID);
  const { data, error, isLoading, refetch } = useChallengeScreen(CHALLENGE_TYPE_BNPL, challengeId);
  const {
    isLoading: submittingChallenge,
    isSuccess: submitSuccessChallenge,
    reset: resetSubmitChallenge,
    mutate: submitChallenge,
  } = useSaveChallenge(CHALLENGE_TYPE_BNPL);
  const { goBack } = useGoBack();
  const { showDialog: showErrorDialog } = useShowDialog();
  const dispatch = useAppDispatch();
  const { areTermsAccepted } = useAppTermsAndConditionsStatus();

  const onSubmitError = () => {
    resetSubmitChallenge();
    const dismissErrorDialogAction = {
      type: BFF_ACTION_TYPES.DISMISS_DIALOG,
    };
    showErrorDialog({
      fenixLayout: true,
      title: formatMessage(messages.submitErrorTitle),
      description: formatMessage(messages.submitErrorDescription),
      primary_action: {
        label: formatMessage(messages.submitErrorActionLabel),
        actions: [dismissErrorDialogAction],
      },
    });
  };

  const onSubmit = (payload) => {
    if (submittingChallenge || submitSuccessChallenge) return;
    logger.info(
      '[BNPL_CHALLENGE][BNPL_SUBMIT_INTENT]',
      'Trying to submit BNPL',
      stringify(payload),
    );
    submitChallenge(
      { ...payload },
      {
        onSuccess: (data) => {
          const actions = data?.actions;
          if (actions?.length) {
            logger.info(
              '[BNPL_CHALLENGE][BNPL_SUBMIT_SUCCESS]',
              'BNPL was submitted successfully',
              stringify(actions),
            );
            executeBFFActions(actions);
          } else {
            logger.error(
              '[BNPL_CHALLENGE][BNPL_NO_SUBMIT_ACTION]',
              'Submit BNPL response does not have actions',
            );
            onSubmitError();
          }
        },
        onError: (error) => {
          logger.error(
            '[BNPL_CHALLENGE][BNPL_SUBMIT_ERROR]',
            'Failed submitting challenge:',
            error.message,
          );
          onSubmitError();
        },
      },
    );
  };

  const actionDefinitions = {
    SUBMIT_BNPL_CHALLENGE: onSubmit,
    [BFF_ACTION_TYPES.NAVIGATE]: (payload) => {
      // NOTE: replace navigation in order to prevent user going back to previous submitted challenges
      navigate(payload, { replace: true });
    },
  };

  const { executeBFFActions } = useBFFActionsExecutor(actionDefinitions);

  const componentResolvers = {
    SECTION: ({ data: { title, subtitle } }) => ({
      component: StyledSection,
      props: {
        title,
        subtitle,
      },
    }),
    ROW_LIST: ({ data: { icon, title, subtitle } }) => ({
      component: RowList,
      props: { icon, title, subtitle },
    }),
    BLOCK_TEXT: ({ data: { components, style } }) => ({
      component: BlockText,
      props: {
        components,
        style,
      },
    }),
    TEXT: ({ data: { value, style } }) => ({
      component: TextFenix,
      props: {
        value,
        style,
      },
    }),
    PRICE_BOX: () => ({
      component: ({ children }) => <>{children}</>,
    }),
    PRICE_BOX_ROW: ({ data: { label, tag, price } }) => ({
      component: PriceBoxRow,
      props: {
        label,
        tag,
        price,
      },
    }),
    TERMS_AND_CONDITIONS_CHECKBOX: () => ({
      component: StyledTyC,
      props: {
        isSelected: areTermsAccepted,
        onClick: () => updateTermsAndConditionsStatus(dispatch, !areTermsAccepted),
      },
    }),
    BUTTON: ({ data: { style, size, text, actions } }) => {
      const isDisabled =
        (style === 'PRIMARY' && !areTermsAccepted) || submittingChallenge || submitSuccessChallenge;
      return {
        component: Button,
        props: {
          state: isDisabled ? 'disabled' : 'enabled',
          disabled: isDisabled,
          size: size.toLowerCase(),
          fullWidth: true,
          label: text,
          hierarchy: style.toLowerCase(),
          onClick: () => executeBFFActions(actions),
        },
      };
    },
    CARD: ({ data: { direction, border = false } }) => ({
      component: StyledCard,
      props: { direction: direction.toLowerCase(), withShadow: true, withBorder: border },
    }),
    SEPARATOR: () => ({ component: StyledDivider }),
  };

  if (isLoading) return <PeyaLoader position="center" />;
  if (error) {
    return (
      <BrandErrorContainer
        fenixLayout
        onPrimaryAction={refetch}
        secondaryLabel="Volver"
        onSecondaryAction={() => goBack()}
        errorCode={error.response?.status}
      />
    );
  }

  const { title, background_img, ...dataBar } = data?.bar;
  const onAppearPageActions = data?.on_appear?.actions;

  const header = {
    ...dataBar,
    backgroundImg: background_img,
    titleFixedHeader: title,
    isOnlyAction: true,
    fontColor: 'icon-color-inverted',
    onlyActionHeight: '200px',
  };
  const components = data?.components;
  const footer = { ...data?.footer?.data, footerProps: { withShadow: false } };

  return (
    <>
      <ChangeBodyBackgroundColor color="white" />
      <StyledBackendDrivenPage
        componentResolvers={componentResolvers}
        header={header}
        components={components}
        footer={footer}
        onAppearActions={onAppearPageActions}
        actionDefinitions={actionDefinitions}
      />
    </>
  );
};

export default BuyNowPayLaterChallenge;
