import styled from 'styled-components';

export const ErrorContainer = styled.div`
  width: 100%;
  max-width: 300px;
  height: 400px;
  position: relative;
  margin: 50% auto 0;
  margin-top: 50%;
  & > section div div {
    width: 85%;
  }
`;
