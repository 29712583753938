import logger from '@app/logger';

const iOSMessage = {
  command: 'webview-event',
  event: 'newCardAnimatedFlow',
};

const publishiOS = () =>
  window.webkit?.messageHandlers?.ListenEventsInterface?.postMessage({ ...iOSMessage });

export const publishAndroid = () => {
  if (window.LoaderInterface?.newCardAnimatedFlow) window.LoaderInterface?.newCardAnimatedFlow();
};

export const publishEventsOnDevice = () => {
  logger.debug('[PUBLISH_EVENTS_ON_DEVICE]', 'Publishing new card animated flow events');
  publishiOS();
};

export const publishEventsOnDeviceBothPlatforms = () => {
  logger.debug(
    '[PUBLISH_EVENTS_ON_DEVICE_BOTH_PLATFORMS]',
    'Publishing new card animated flow events',
  );
  publishiOS();
  publishAndroid();
};
