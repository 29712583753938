import React, { useState } from 'react';
import styled from 'styled-components';
import Image from '@pedidosya/web-fenix/foundations/Image';
import { getRgbColorWithOpacity, Text } from './utils';

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background: ${({ firstColor }) => `linear-gradient(0deg, ${firstColor} 0%, ${firstColor} 100%)`};
`;

const CardInfo = styled.div`
  z-index: 1;
  display: flex;
  gap: ${({ theme }) => theme.space('spacing-component-medium')};
  width: 100%;
  padding: ${({ theme }) =>
    `${theme.space('spacing-component-medium')} ${theme.space('spacing-component-large')}`};
`;

const CardInfoLeft = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space('spacing-component-xsmall')};
  flex-direction: column;
  text-align: left;
  width: 100%;
  height: 100%;
`;

const CardInfoRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: ${({ theme }) => theme.space('spacing-component-xsmall')};
  min-width: 65px;
  max-width: 65px;
  height: 100%;
  text-align: right;
`;

const ImgContainer = styled.div`
  position: relative;
  width: 100%;
  height: 70%;
`;

const StyledImage = styled(Image)`
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100%;
  max-height: 100%;
`;

export const Shading = styled.div`
  position: absolute;
  width: 200px;
  height: 100%;
  right: 0px;
  clip-path: polygon(47% 0, 100% 0, 100% 100%, 0% 100%);
  background-image: ${(props) => getShadingBackground(props)};
`;

const getShadingBackground = ({ shadingCardColor }) => {
  return `linear-gradient(
        221deg, 
        ${getRgbColorWithOpacity(shadingCardColor, 0.7)} 0%,
        ${getRgbColorWithOpacity(shadingCardColor, 0.8)} 0.01%,
        ${getRgbColorWithOpacity(shadingCardColor, 0)} 93.49%
      );`;
};

const IssuerName = styled(Text)`
  letter-spacing: 0.14px;
  text-transform: uppercase;
`;

const CardInstrument = ({ issuerName, holderName, cardNumber, backgroundColor, brand }) => {
  const [showImage, setShowImage] = useState(true);
  const { image, operation_type: operationType } = brand;
  const { first_color: firstColor, second_color: shadingCardColor } = backgroundColor;

  const onImgError = () => {
    setShowImage(false);
  };

  return (
    <Container data-testid="card-instrument-container" firstColor={firstColor}>
      {shadingCardColor ? (
        <Shading data-testid="card-instrument-shading" shadingCardColor={shadingCardColor} />
      ) : null}
      <CardInfo>
        <CardInfoLeft>
          <IssuerName label={issuerName} component="span" withEllipsis={true} />
          <Text label={cardNumber} component="span" withEllipsis={true} />
          <Text label={holderName} component="span" withEllipsis={true} />
        </CardInfoLeft>
        <CardInfoRight>
          <ImgContainer>
            {showImage ? (
              <StyledImage src={image.url} alt={image.accessibilityHint} onError={onImgError} />
            ) : null}
          </ImgContainer>
          <Text label={operationType} component="span" withEllipsis={true} breakAnywhere={true} />
        </CardInfoRight>
      </CardInfo>
    </Container>
  );
};

export default CardInstrument;
