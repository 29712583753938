import logger from '@app/logger';

const iOSMessage = {
  command: 'webview-event',
  event: 'hideLoader',
};

const publishiOS = () =>
  window.webkit?.messageHandlers?.ListenEventsInterface?.postMessage({ ...iOSMessage });

const publishAndroid = () => {
  if (window.LoaderInterface?.hideLoader) window.LoaderInterface?.hideLoader();
};

export const publishEventsOnDevice = () => {
  try {
    logger.debug('[PUBLISH_EVENTS_ON_DEVICE]', 'Publishing hide loader');
    publishAndroid();
    publishiOS();
  } catch (error) {
    logger.error(
      '[publishEventsOnDevice][loadingEvents][ERROR]',
      `window: ${window} - publishiOS: ${window.webkit?.messageHandlers?.ListenEventsInterface?.postMessage} - publishAndroid: ${window.LoaderInterface?.hideLoader}`,
      error.message,
    );
  }
};
