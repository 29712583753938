import { css } from 'styled-components';

const FONT_FAMILIES = {
  MAIN: 'Segma',
  SECONDARY: 'Segma',
};

const FONT_WEIGHTS = {
  REGULAR: 400,
  SEMI_BOLD: 600,
  BOLD: 700,
  EXTRA_BOLD: 800,
};

const FONT_SIZES = {
  SMALL: 12,
  MEDIUM: 14,
  LARGE: 16,
};

const LINE_HEIGHTS = {
  SMALL: 16,
  MEDIUM: 20,
  LARGE: 24,
};

// TODO: for now the size and family is harcoded
const buildFont = (weight, size, family = 'SECONDARY') => css`
  font-family: ${FONT_FAMILIES[family]};
  font-size: ${FONT_SIZES[size] || FONT_SIZES.MEDIUM}px;
  font-weight: ${FONT_WEIGHTS[weight] || FONT_WEIGHTS.REGULAR};
  line-height: ${LINE_HEIGHTS[size] || LINE_HEIGHTS.MEDIUM}px;
`;

export default buildFont;
