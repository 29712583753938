export const OS = {
  ANDROID: 'Android',
  IOS: 'iOS',
  UNKNOWN: 'unknown',
};

export const webNavigationHeaderVersion = {
  ANDROID_VERSION: '5.24.0',
  IOS_VERSION: '10.24.0',
};

export const TIME_STRING_OPTION = {
  HOURS: 'HH',
  MINUTES: 'MM',
  SECONDS: 'SS',
};
