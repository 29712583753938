import React, { useEffect, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useBFFActionsExecutor, useBFFNavigate } from '@checkout-ui/backend-driven';
import messages from './messages';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
import { BrandErrorContainer } from '../../components/Error';
import CardErrorIcon from '@app/components/CardErrorIcon';
import { useGoBack } from '@app/hooks/useGoBack';
import { useShowDialog } from '@app/hooks/useShowDialog';
import { usePayloadForNewInstrument } from '@app/services/queries';
import { BFF_ACTION_TYPES } from '@app/providers/mainContext/constants';
import { useSubmitNewCardExternal } from '@app/services/mutations';
import logger from '@app/logger';
import stringify from '@commons/utils/object/stringify';
import { useQueryParamValue } from '@app/utils/domRouter';
import { QUERY_PARAM } from '@commons/constants';
import {
  ADD_CARD_OUTCOME,
  ATTRIBUTES_TRACKING,
  ERROR_CODE_TRACKING,
  EVENT_NAME_TRACKING,
  NOT_SET_VALUE,
  actionTracking,
} from '@app/utils/tracking';
import { useSelectPaymentInstruments } from '@app/hooks/useSelectPaymentInstruments';

const NewCardExternalSuccessCallback = () => {
  const requestId = useQueryParamValue(QUERY_PARAM.REQUEST_ID);
  const { goBack } = useGoBack();
  const { formatMessage } = useIntl();
  const {
    isSuccess: isSuccessPayloadForNewInstrument,
    error,
    data,
  } = usePayloadForNewInstrument(requestId);
  const { navigate } = useBFFNavigate();
  const { selectPaymentInstruments } = useSelectPaymentInstruments({
    replaceNavigation: true,
  });

  const actionDefinitions = {
    [BFF_ACTION_TYPES.NAVIGATE]: (payload) => {
      navigate(payload, { replace: true });
    },
    [BFF_ACTION_TYPES.SELECT_INSTRUMENT]: (payload) => {
      selectPaymentInstruments([{ id: payload.instrument_id }]);
    },
  };

  const { executeBFFActions } = useBFFActionsExecutor(actionDefinitions);
  const { isIdle: isIdleSubmitNewCardExternal, mutate: addNewPaymentInstrument } =
    useSubmitNewCardExternal();

  const { showDialog: showErrorDialog } = useShowDialog();

  const goExternalFormAction = useMemo(
    () => ({
      type: BFF_ACTION_TYPES.NAVIGATE,
      data: {
        native_page: {
          name: 'NEW_CARD_EXTERNAL_FORM',
        },
      },
    }),
    [],
  );

  const onSubmitError = useCallback(
    (error) => {
      logger.error(
        '[NewCardExternalSuccessCallback][NEW_CARD_SUBMIT_ERROR]',
        'Fail submitting new card:',
        error.message,
      );

      if (error.response?.data?.actions) {
        // If the error received already contains actions, execute them
        executeBFFActions(error.response.data.actions);
      } else {
        // Otherwise show default error message
        executeBFFActions(
          actionTracking(EVENT_NAME_TRACKING.NEW_CARD, {
            [ATTRIBUTES_TRACKING.ERROR_CODE]: ERROR_CODE_TRACKING.PAYMENT_ERROR,
            [ATTRIBUTES_TRACKING.ADD_CARD_OUTCOME]: ADD_CARD_OUTCOME.FAILED,
            [ATTRIBUTES_TRACKING.INSTRUMENT_ID]: NOT_SET_VALUE,
            [ATTRIBUTES_TRACKING.ORDER_PAYMENT_METHOD]: NOT_SET_VALUE,
            [ATTRIBUTES_TRACKING.SCREEN_TYPE]: NOT_SET_VALUE,
          }),
        );

        const dismissErrorDialogAction = {
          type: BFF_ACTION_TYPES.DISMISS_DIALOG,
        };

        showErrorDialog({
          fenixLayout: true,
          title: formatMessage(messages.submitErrorTitle),
          description: formatMessage(messages.submitErrorDescription),
          primary_action: {
            label: formatMessage(messages.submitErrorActionLabel),
            actions: [dismissErrorDialogAction, goExternalFormAction],
          },
          children: <CardErrorIcon />,
        });
      }
    },
    [executeBFFActions, formatMessage, goExternalFormAction, showErrorDialog],
  );

  const handleSubmit = useCallback(
    (payload) => {
      try {
        const { vaultProvider, tokenizationResult, validatedCard } = payload;
        const vaultData = {
          fingerprint: tokenizationResult.fingerprint,
          card: {
            bin: tokenizationResult.first_six_digits,
            brand: validatedCard.brand,
            four_last_digit: tokenizationResult.last_four_digits,
            vault: vaultProvider,
            vault_token: tokenizationResult.token,
            holder_name: tokenizationResult.full_name,
            operation_type: validatedCard.type,
            expiration: {
              year: tokenizationResult.year,
              month: tokenizationResult.month,
            },
          },
        };

        addNewPaymentInstrument(
          {
            vaultProvider,
            newCardFormData: vaultData,
          },
          {
            onSuccess: (data) => {
              const actions = data?.actions;
              if (actions?.length) {
                logger.info(
                  '[NewCardExternalSuccessCallback][ADD_NEW_PAYMENT_INSTRUMENT_BFF]',
                  `Card added successfully, next actions: ${stringify(actions)}`,
                );
                executeBFFActions(actions);
              } else {
                const error = new Error('Add new payment instrument response do not have actions');
                onSubmitError(error);
              }
            },
            onError: (error) => {
              onSubmitError(error);
            },
          },
        );
      } catch (err) {
        onSubmitError(err);
      }
    },
    [addNewPaymentInstrument, executeBFFActions, onSubmitError],
  );

  useEffect(() => {
    if (
      isSuccessPayloadForNewInstrument &&
      data &&
      data.payloadForNewInstrument &&
      isIdleSubmitNewCardExternal
    ) {
      logger.info('[NewCardExternalSuccessCallback][PAYLOAD_FOR_NEW_INSTRUMENT]');
      handleSubmit(data.payloadForNewInstrument);
    }
  }, [data, handleSubmit, isIdleSubmitNewCardExternal, isSuccessPayloadForNewInstrument]);

  if (error) {
    return (
      <BrandErrorContainer
        fenixLayout
        secondaryLabel="Volver"
        onSecondaryAction={() => executeBFFActions([goExternalFormAction])}
        errorCode={error.response?.status}
      />
    );
  }

  return <PeyaLoader position="center" />;
};

export default NewCardExternalSuccessCallback;
