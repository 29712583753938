import React from 'react';
import PropTypes from 'prop-types';
import {
  Aside,
  InstrumentItemContainer,
  StyledRadioButton,
  ActionContainer,
  InfoInstrument,
  DeleteActionContainer,
} from './components';

function UserInstrumentItemBFF({
  id,
  button,
  selected,
  onSelect,
  deletable,
  onDeleteInstrument,
  ...props
}) {
  const { enabled } = props;

  return (
    <InstrumentItemContainer
      onClick={() => onSelect(id)}
      data-testid={id}
      role="radio"
      aria-checked={selected.toString()}
    >
      <Aside enabled={enabled}>
        <StyledRadioButton selected={selected} />
        <InfoInstrument {...props} />
      </Aside>
      {deletable ? <DeleteActionContainer onDelete={onDeleteInstrument} /> : null}
      {button ? <ActionContainer actionButton={button} /> : null}
    </InstrumentItemContainer>
  );
}

UserInstrumentItemBFF.propTypes = {
  id: PropTypes.string.isRequired,
  button: PropTypes.object,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  deletable: PropTypes.bool,
  onDeleteInstrument: PropTypes.func,
  enabled: PropTypes.bool,
};

export default UserInstrumentItemBFF;
