import { useAppState } from '../providers/mainContext/context';
import semverGte from 'semver/functions/gte';
import semverLte from 'semver/functions/lte';
import semverEq from 'semver/functions/eq';

export const useDeviceInfo = () => {
  const [{ context }] = useAppState();

  return context.deviceInfo;
};

export const useIsWeb = () => {
  const { isWebview } = useDeviceInfo();
  return !isWebview();
};

export const useIsWebview = () => {
  const { isWebview } = useDeviceInfo();

  return isWebview();
};

export const useIsAndroid = () => {
  const { isAndroid } = useDeviceInfo();

  return isAndroid();
};

export const useIsIos = () => {
  const { isIos } = useDeviceInfo();

  return isIos();
};

export const useIsWebAndroid = () => {
  const web = useIsWeb();
  const android = useIsAndroid();

  return web && android;
};

export const useIsWebIos = () => {
  const web = useIsWeb();
  const ios = useIsIos();

  return web && ios;
};

export const useIsWebviewAndroid = () => {
  const webview = useIsWebview();
  const android = useIsAndroid();

  return webview && android;
};

export const useIsWebviewIos = () => {
  const webview = useIsWebview();
  const ios = useIsIos();

  return webview && ios;
};

export const useAppVersion = ({ min, max, exact }) => {
  const { appVersion } = useDeviceInfo();

  if (!appVersion) return true;
  if (exact) return semverEq(exact, appVersion);

  const isGte = !min || semverGte(appVersion, min);
  const isLte = !max || semverLte(appVersion, max);

  return isLte && isGte;
};
