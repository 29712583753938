import React from 'react';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import CardContainer from '@pedidosya/web-fenix/molecules/CardContainer';

import styled from 'styled-components';
import card_error_icon from '@app/assets/images/card_error.svg';

const Container = styled(CardContainer)`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) =>
    `${theme.space('spacing-component-4xlarge')} ${theme.space('spacing-component-xlarge')}`};
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  height: 100vh;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: ${({ theme }) => `${theme.space('spacing-component-medium')}`};
  h1,
  p {
    margin: 0;
  }
`;

const StyledImg = styled.img`
  width: 115px;
  height: 90px;
  padding: ${({ theme }) => `${theme.space('spacing-10')} ${theme.space('spacing-04')}`};
`;

const DisabledInstrumentsError = () => {
  return (
    <Container withBorder={false} withShadow={false}>
      <StyledImg src={card_error_icon} />
      <TextContainer>
        <Typography component="h1" token="text-title-small">
          {'Lo sentimos'}
        </Typography>
        <Typography component="p" token="text-base-large">
          {'No pudimos cargar los medios de pago no disponibles.'}
        </Typography>
      </TextContainer>
    </Container>
  );
};

export default DisabledInstrumentsError;
