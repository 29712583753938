import parse from '../../../commons/utils/string/parse';
import stringify from '../../../commons/utils/object/stringify';

class SessionStorage {
  get(key) {
    try {
      return parse(sessionStorage.getItem(key));
    } catch (error) {
      return undefined;
    }
  }

  set(key, value) {
    try {
      sessionStorage.setItem(key, stringify(value));
      return true;
    } catch (error) {
      return false;
    }
  }

  remove(key) {
    try {
      sessionStorage.removeItem(key);
      return true;
    } catch (error) {
      return false;
    }
  }
}

export default SessionStorage;
