import React from 'react';
import { Tooltip } from '@appscore/web-components';
import { useTooltipContext } from '@app/providers/mainContext/selectors/useAppTooltip';

const BackendDrivenTooltip = () => {
  const { show, text, target, pointerPosition, ...customProps } = useTooltipContext();
  if (!target || !show) return null;

  return (
    <Tooltip
      target={target.current}
      content={text}
      pointerPosition={pointerPosition}
      {...customProps}
    />
  );
};

export default BackendDrivenTooltip;
