import { getPlainNumber } from '../../../../../../../../utils/number';
import { isOnlyDigitString } from '../../../../../../../../../commons/utils/string/isOnlyDigitString';

const MAX_MONTH_NUMBER = 12;
const MAX_MMYY_FORMAT_LENGTH = 5;

/**
 * Applies a proper format to the card expiration input MM/YY.
 * Adds 0 padding for one digit month, adds automatic slash
 *
 * @param {String} cardExpiration expiration input, digits and one slash maybe
 * @returns {string} A card expiration following the tentative format of MM/YY.
 */
export default function formatCardExpiration(cardExpiration) {
  const cardExpirationStr = String(cardExpiration);

  // if last character is not a digit dismiss it
  if (cardExpiration && !isOnlyDigitString(getPlainNumber(cardExpiration))) {
    return cardExpiration.slice(0, -1);
  }

  // is first character
  if (cardExpirationStr.length === 1) {
    // Month between 2 and 12
    if (Number(cardExpirationStr) >= 2) {
      return `0${cardExpirationStr}/`;
    }
    return cardExpirationStr;
  }

  // is second character
  if (cardExpirationStr.length === 2) {
    // is valid month
    if (Number(cardExpirationStr) <= MAX_MONTH_NUMBER) {
      return `${cardExpirationStr}/`;
    }
  }

  // is filling the year, only check that does not exceed
  if (cardExpirationStr.length > MAX_MMYY_FORMAT_LENGTH) return cardExpirationStr.slice(0, -1);

  return cardExpirationStr;
}
