import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import card from '../../assets/images/card.svg';

const StyledErrorContainer = styled.div`
  display: flex;
`;

function CardErrorIcon({ icon }) {
  const iconImage = icon || card;
  const [imgIcon, setImgIcon] = useState(iconImage);

  return (
    <StyledErrorContainer>
      <img
        src={imgIcon}
        alt="card error"
        onError={() => {
          setImgIcon(card);
        }}
      />
    </StyledErrorContainer>
  );
}

CardErrorIcon.propTypes = {
  icon: PropTypes.string,
};
export default CardErrorIcon;
