import { defineMessages } from 'react-intl';

export default defineMessages({
  submitErrorTitle: {
    id: 'app.paymentMethod.spreedlyForm.submitError.title',
    defaultMessage: 'No pudimos guardar la tarjeta',
  },
  submitErrorDescription: {
    id: 'app.paymentMethod.spreedlyForm.submitError.description',
    defaultMessage: 'Por favor, vuelve a ingresar los datos e intenta nuevamente',
  },
  submitErrorActionLabel: {
    id: 'app.paymentMethod.spreedlyForm.submitError.actionLabel',
    defaultMessage: 'Probar de nuevo',
  },
});
