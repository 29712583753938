import { defineMessages } from 'react-intl';

export default defineMessages({
  label: {
    id: 'app.paymentMethod.inputs.cardNumberInput.label',
    defaultMessage: 'Número de tarjeta',
  },
  errorMessage: {
    id: 'app.paymentMethod.inputs.cardNumberInput.errorMessage',
    defaultMessage: 'El número de tarjeta ingresado no es válido',
  },
});
