import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { InputText } from '@appscore/web-components';
import { getPlainNumber } from '../../../../../../../utils/number';
import formatCardNumber from '../utils/formatters/cardNumber';
import getCardLength from '../utils/getCardLength';
import messages from './messages';

const CardNumberInput = ({
  value,
  onChange,
  error = false,
  cardNumberFormat,
  disabled = false,
  verified = false,
  ...rest
}) => {
  const isValid = () => {
    if (!value) return true;

    return !error && verified;
  };

  const handleChange = (event) => {
    const inputValue = event?.target?.value;
    const plainValue = getPlainNumber(inputValue);

    onChange(plainValue.slice(0, getCardLength(cardNumberFormat)));
  };

  const intl = useIntl();
  const { formatMessage } = intl;
  const errorMessage = isValid() ? '' : formatMessage(messages.errorMessage);
  return (
    <InputText
      {...rest}
      pattern="[3-6][0-9 ]{15,18}"
      type="tel"
      label={formatMessage(messages.label)}
      onChange={handleChange}
      value={formatCardNumber(value, cardNumberFormat)}
      helpText={errorMessage}
      error={Boolean(errorMessage)}
      disabled={disabled}
      inputProps={{
        'data-testid': rest?.name || 'card-number-input',
      }}
    />
  );
};

CardNumberInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  cardNumberFormat: PropTypes.array,
  disabled: PropTypes.bool,
  verified: PropTypes.bool,
};

export default CardNumberInput;
