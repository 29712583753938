import { useBFFActionsExecutor, useBFFNavigate } from '@checkout-ui/backend-driven';
import { useEffect, useState } from 'react';
import { BFF_ACTION_TYPES } from '../../../providers/mainContext/constants';
import { getComponentByType, hasOpenDialogAction } from '../../../utils/BFF';

export const useRedirectToPaymentMethod = (isLoading, error, data) => {
  const [isCheckingMethods, setIsCheckingMethods] = useState(true);
  const { navigate } = useBFFNavigate();
  const actionDefinitions = {
    [BFF_ACTION_TYPES.NAVIGATE]: (payload) => {
      navigate(payload, { replace: true });
    },
  };
  const { executeBFFActions } = useBFFActionsExecutor(actionDefinitions);

  useEffect(() => {
    if (!isLoading) {
      if (!error) {
        const paymentMethodsComponents = getComponentByType(data, 'SECTION')?.data.components;
        if (
          paymentMethodsComponents?.length === 1 &&
          !hasOpenDialogAction(paymentMethodsComponents[0])
        ) {
          executeBFFActions(paymentMethodsComponents[0]?.data?.actions);
        }
      }
      setIsCheckingMethods(false);
    }
  }, [data, error, executeBFFActions, isLoading, setIsCheckingMethods]);

  return { isCheckingMethods };
};
