import styled from 'styled-components';
import { InputText } from '@appscore/web-components';

const InputNumber = styled(InputText)`
  margin-top: ${({ theme }) => theme.spacing(10)};
  width: ${({ width }) => width};

  /* Remove Arrows from Input Number */

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export default InputNumber;
