import LoadAppError from '@app/components/LoadAppError';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
import { setEnvironment } from '@app/utils/environment';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import pkg from '../../../package.json';
import getAxiosInstance from '../../services/axios';

const PublicEnvContext = createContext();

export const getPublicEnv = async (appShellBasePath, axios = getAxiosInstance()) => {
  const publicEnvWithoutPrefix = `/api/${pkg.name}/env`;
  const publicEnvURL = appShellBasePath
    ? `${appShellBasePath}${publicEnvWithoutPrefix}`
    : publicEnvWithoutPrefix;

  const { data } = await axios.get(publicEnvURL);
  return data;
};

export const usePublicEnv = () => {
  return useContext(PublicEnvContext);
};

export const PublicEnvProvider = ({ children, appShellBasePath, getPublicEnv }) => {
  const [publicEnv, setPublicEnv] = useState();
  const [error, setError] = useState(null);

  useEffect(() => {
    if (publicEnv) return;
    getPublicEnv(appShellBasePath)
      .then((data) => {
        const { environment } = data;
        setEnvironment(environment);
        setPublicEnv(data);
      })
      .catch(setError);
  }, [appShellBasePath, getPublicEnv, publicEnv]);

  if (error) return <LoadAppError error={error} />;

  if (!publicEnv) return <PeyaLoader position="center" />;

  return <PublicEnvContext.Provider value={publicEnv}>{children}</PublicEnvContext.Provider>;
};

export const publicEnvShape = {
  locale: PropTypes.string.isRequired,
  device: PropTypes.string.isRequired,
  environment: PropTypes.string.isRequired,
  country: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};
