import React from 'react';
import styled from 'styled-components';

const Container = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing(8)};
`;

const TextContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(6)};
`;

const Title = styled.p`
  ${({ theme }) => theme.fontFamily.mainFont.mediumExtraBold}
  color: ${({ theme }) => theme.colors.night_blue_1};
  margin: 0;
  line-height: ${({ theme }) => `${theme.spacing(12)}`};
`;

const Subtitle = styled.p`
  ${({ theme }) => theme.fontFamily.secondaryFont.xSmallRegular}
  color: ${({ theme }) => theme.colors.night_blue_2};
  margin: 0;
  line-height: ${({ theme }) => `${theme.spacing(8)}`};
`;

const Section = ({ title, subtitle, children }) => {
  return (
    <Container>
      <TextContainer>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </TextContainer>
      {children}
    </Container>
  );
};

export default Section;
