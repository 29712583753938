import React from 'react';
import PropTypes from 'prop-types';
import {
  InstrumentItemContainer as Container,
  StyledRadioButton,
  InstrumentLogo as CardLogo,
  Description,
  Title,
  Subtitle,
  Aside,
} from './components';
/*
 TODO: remove when Checkout migrates to new BFF structure
 https://peyaplanning.atlassian.net/browse/PAYME-480?atlOrigin=eyJpIjoiNGQ2ZmJjNzYxZDE1NDc5MWEzYTBiOWViYTEzNzViNWQiLCJwIjoiaiJ9
 */
function UserInstrumentItem({ id, title, subtitle, icon, selected, onSelect }) {
  return (
    <Container onClick={() => onSelect(id)} data-testid={id}>
      <Aside>
        <StyledRadioButton selected={selected} />
        <CardLogo src={icon} alt="card-logo" height={32} width={48} />
        <Description>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </Description>
      </Aside>
    </Container>
  );
}

UserInstrumentItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default UserInstrumentItem;
