export const DATADOG_CREDENTIALS = {
  webview: {
    applicationId: '177c5dd8-da1b-46f6-a732-2c6c4da6030c',
    clientToken: 'pub12c871beb026f3d34e6489ebd85f6f7a',
    service: 'checkout-payment-methods-webview',
  },
  web: {
    applicationId: '1ded9520-fcfd-44c2-87a3-db3ce2353a0c',
    clientToken: 'puba58e78c5a21528ebf14e28a59523a1e9',
    service: 'checkout-payment-methods-web',
  },
};
