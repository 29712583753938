import ChangeBodyBackgroundColor from '@app/components/ChangeBodyBackgroundColor';
import React from 'react';

export const withChangeBgColor = (WrappedComponent) => (props) => {
  return (
    <>
      <ChangeBodyBackgroundColor color="white" />
      <WrappedComponent {...props} />
    </>
  );
};
