import React from 'react';
import PropTypes from 'prop-types';
import { BrandError } from '@appscore/web-components';
import { ErrorModalContainer, ModalButton, BrandErrorModal } from './components';

/**
 * Dialog Brand Error
 * How to use it:
 *  <DialogBrandError
      open={true}
      onClose={() => null }
      subtitle="Some text"
    />
 * By default will take the props of BrandError Web Components
 */
const DialogBrandError = ({ open, onClose, buttonLabel, ...props }) => (
  <BrandErrorModal open={open} onClose={onClose}>
    <ErrorModalContainer>
      <BrandError {...props}>
        <ModalButton onClick={onClose} label={buttonLabel} color="primary" size="medium" />
      </BrandError>
    </ErrorModalContainer>
  </BrandErrorModal>
);

DialogBrandError.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string,
};

DialogBrandError.defaultProps = {
  buttonLabel: 'Entendido',
};

export { DialogBrandError }
