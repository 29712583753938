import React from 'react';
import { BrandErrorContainer } from '../../components/Error';
import { useAppChallenge } from '@app/providers/mainContext/selectors/useAppChallenge';
import logger from '@app/logger';
import { useBFFActionsExecutor } from '@checkout-ui/backend-driven';
import { BFF_ACTION_TYPES } from '@app/providers/mainContext/constants';
import useOnce from '@app/hooks/useOnce';
import { ERROR_CODE_TRACKING, EVENT_NAME_TRACKING, actionTracking } from '@app/utils/tracking';

const cancelChallengeFlow = [
  {
    type: BFF_ACTION_TYPES.CANCEL_FLOW,
    data: {
      query_params: {
        error: 'true',
      },
    },
  },
];

const ChallengesError = () => {
  const { executeBFFActions } = useBFFActionsExecutor();
  const { challenge } = useAppChallenge();

  useOnce(() => {
    const { challengeId, challengeType } = challenge || {};
    executeBFFActions(
      actionTracking(EVENT_NAME_TRACKING.CHALLENGE_FAILED, {
        challengeType: challengeType?.toLowerCase(),
        errorCode: ERROR_CODE_TRACKING.REDIRECT_URL_ERROR,
      }),
    );

    if (challengeId && challengeType) {
      logger.error(
        `[CHALLENGES_ERROR][${challengeType}]`,
        `Provider end challenge with an error - challengeId: ${challengeId}`,
      );
    } else {
      logger.error(
        `[CHALLENGES_ERROR][CHALLENGE_CALLBACK_ERROR]`,
        `Could not get challengeId: ${challengeId} or challengeType: ${challengeType} from session cache`,
      );
    }
  });

  return (
    <BrandErrorContainer
      fenixLayout
      secondaryLabel="Volver"
      onSecondaryAction={() => executeBFFActions(cancelChallengeFlow)}
      errorCode={400}
    />
  );
};

export default ChallengesError;
