/**
 * Generate the articles to set the default value for each input
 * @param {Number} totalNumberOfInputs total number of inputs to be created
 * @returns {Object}
 */
export function createDefaultItems(totalNumberOfInputs) {
  const fieldNames = Array(totalNumberOfInputs)
    .fill()
    .map((_, index) => `codeInput${index + 1}`);

  const items = fieldNames.reduce(
    (data, value) => ({
      ...data,
      [value]: '',
    }),
    {},
  );

  return { fieldNames, items };
}
