const ONLY_DIGIT_REGEX = /^\d+$/;
const ONLY_NUMBER_REGEX = /^([0-9]*\.?[0-9]+|\.[0-9]+)$/;

/**
 * @description Returns true if the string contains only digits and is castable as Number
 * @param {String} str Value to be tested
 */
export function isOnlyDigitString(str) {
  return ONLY_DIGIT_REGEX.test(String(str));
}

/**
 * @description Returns true if the string is a number and is castable as Number
 * @param {String} str Value to be tested
 */
export function isOnlyNumberString(str) {
  return ONLY_NUMBER_REGEX.test(String(str));
}
