import { useCallback } from 'react';
import { useQueryParamValue } from '@app/utils/domRouter';
import { QUERY_PARAM } from '@commons/constants';
import logger from '@app/logger';
import messages from '../messages';
import { useIntl } from 'react-intl';

const POSSIBLE_RESULT = {
  SUCCESS: 'success',
  FAILURE: 'failure',
  CANCEL: 'cancel',
};

export const useBruceHandler = () => {
  const { formatMessage } = useIntl();
  const { PAYMENT_RESULT, EXCEPTION_TYPE } = QUERY_PARAM.CHALLENGE_CALLBACK.BRUCE;
  const paymentResult = useQueryParamValue(PAYMENT_RESULT);
  const exceptionType = useQueryParamValue(EXCEPTION_TYPE);

  const bruceHandler = useCallback(
    (onSuccess, onError, onCancel) => {
      const successFlow = {
        loggerTag: 'SUCCESS_FLOW',
        validation: () => paymentResult === POSSIBLE_RESULT.SUCCESS,
        action: onSuccess,
        isError: false,
      };
      const failureFlow = {
        loggerTag: 'FAILURE_FLOW',
        validation: () => paymentResult === POSSIBLE_RESULT.FAILURE,
        action: () => onError(formatMessage(messages.bruceFailureFlowMessage)),
        isError: true,
      };
      const cancelFlow = {
        loggerTag: 'CANCEL_FLOW',
        validation: () => paymentResult === POSSIBLE_RESULT.CANCEL,
        action: onCancel,
        isError: false,
      };
      const unknownFlow = {
        loggerTag: 'UNKNOWN_FLOW',
        validation: () => true,
        action: () => onError(formatMessage(messages.bruceUnknownFlowMessage)),
        isError: true,
      };
      const possibleEndOfFlows = [successFlow, failureFlow, cancelFlow, unknownFlow];

      for (const flow of possibleEndOfFlows) {
        const { validation, isError, loggerTag, action } = flow;
        if (validation()) {
          const loggerType = isError ? logger.error : logger.info;
          loggerType(
            `[useProviderHandlerCallback][BRUCE][${loggerTag}]`,
            `${PAYMENT_RESULT}: ${paymentResult} - ${EXCEPTION_TYPE}: ${exceptionType}`,
          );
          action();
          return;
        }
      }
    },
    [EXCEPTION_TYPE, PAYMENT_RESULT, exceptionType, formatMessage, paymentResult],
  );

  return { bruceHandler };
};
