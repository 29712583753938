import isString from '../../commons/utils/string/isString';
import { isEmpty } from './string';

export function roundToTwoDecimals(number) {
  return Math.round(number * 100) / 100;
}

export function roundToOneDecimal(number) {
  return Math.round(number * 10) / 10;
}

export function calcDiscount(discount, applyDiscount, price) {
  if (discount > 0 && applyDiscount) {
    const priceWithDiscount = price - (price / 100) * discount;

    // round to two decimals if it's necessary
    return roundToTwoDecimals(priceWithDiscount);
  }

  return price;
}

export function getRandom(min, max) {
  return Math.random() * (max - min) + min;
}

/**
 * Removes non-numeric characters from input parameter.
 *
 * @param {string | undefined | null} input Alphanumeric and non-alphanumeric characters.
 * @returns {string} Numeric characters only.
 */
export function getPlainNumber(number) {
  //  ignore everything except digits
  return String(number).replace(/\D+/g, '');
}

export const isNumber = (value) => {
  if (!value) return value === 0;

  return typeof value === 'number' || (isString(value) && !isNaN(value) && !isEmpty(value));
};

// Params could be numbers or number parsed to string. Ex: 123 or "123".
export function comparePossibleNumbersInString(first, second) {
  if (
    !first ||
    !second ||
    (isString(first) && isString(second)) ||
    (!isString(first) && !isString(second)) ||
    (!isNumber(first) && !isNumber(second))
  ) {
    return first === second;
  }

  // At this point, both are numbers or parsed numbers to string.
  return first.toString() === second.toString();
}
