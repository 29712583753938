import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Typography from '@pedidosya/web-fenix/atoms/Typography';
import Tag from '@pedidosya/web-fenix/atoms/Tag';

const Container = styled.section`
  padding: ${({ theme }) => theme.space('spacing-layout-small')};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  h3 {
    margin: 0;
  }
`;

const ContainerLeft = styled.section`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space('spacing-06')};
`;

const ContainerEnd = styled.section`
  margin-right: 0;
`;

const StyledText = ({ value, style: { token, color } }) => (
  <Typography component="h3" token={token} color={color}>
    {value}
  </Typography>
);

const StyledTag = ({ category, label }) => <Tag category={category} label={label} size="medium" />;

const PriceBoxRow = ({ label, tag, price }) => {
  return (
    <Container>
      <ContainerLeft>
        <StyledText {...label} />
        <StyledTag {...tag} />
      </ContainerLeft>
      <ContainerEnd>
        <StyledText {...price} />
      </ContainerEnd>
    </Container>
  );
};

PriceBoxRow.propTypes = {
  label: PropTypes.shape({
    value: PropTypes.string,
    style: PropTypes.object,
  }),
  tag: PropTypes.shape({
    category: PropTypes.string,
    slabel: PropTypes.string,
  }),
  price: PropTypes.shape({
    value: PropTypes.string,
    style: PropTypes.object,
  }),
};

export default PriceBoxRow;
