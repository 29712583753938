import { BFF_INSTRUMENT_TYPES } from '@app/constants/bffComponents';
import { useAppNeedChange } from '@app/providers/mainContext/selectors/useAppNeedChange';
import stringify from '@commons/utils/object/stringify';
import { useCallback } from 'react';
import { QUERY_PARAM } from '@commons/constants';
import { invalidateSessionCache } from '../providers/mainContext/actions/invalidateSessionCache';
import { useAppCallbacks } from '../providers/mainContext/selectors/useAppCallbacks';
import { useAppDispatch } from '../providers/mainContext/selectors/useAppDispatch';
import { useCallbackNavigation } from './useCallbackNavigation';

export const useSelectPaymentInstruments = ({ replaceNavigation = false } = {}) => {
  const { successCallback } = useAppCallbacks();
  const dispatch = useAppDispatch();
  const { callbackNavigate } = useCallbackNavigation();
  const { amountValue: amountValueFromContext } = useAppNeedChange();

  const selectPaymentInstruments = useCallback(
    (paymentInstrumentIds = []) => {
      const params = new URLSearchParams();
      paymentInstrumentIds.forEach(({ id }) => {
        params.append(QUERY_PARAM.PAYMENT_INSTRUMENTS_SELECTED, id);
      });
      paymentInstrumentIds.forEach(({ id, type }) => {
        params.append(
          QUERY_PARAM.SELECTED_INSTRUMENTS,
          stringify({
            id,
            amount:
              type === BFF_INSTRUMENT_TYPES.OFFLINE_INSTRUMENT ? amountValueFromContext : null,
          }),
        );
      });
      const queryString = params.toString();
      const url = queryString ? `${successCallback}?${queryString}` : successCallback;

      invalidateSessionCache(dispatch);
      callbackNavigate(url, undefined, replaceNavigation);
    },
    [successCallback, dispatch, callbackNavigate, amountValueFromContext],
  );

  return { selectPaymentInstruments };
};
