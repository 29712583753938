import logger from '@app/logger';
import { BrandErrorContainer } from '@app/components/Error';
import { useAppChallenge } from '@app/providers/mainContext/selectors/useAppChallenge';
import { useSaveChallenge } from '@app/services/mutations';
import stringify from '@commons/utils/object/stringify';
import { useBFFActionsExecutor } from '@checkout-ui/backend-driven';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { BFF_ACTION_TYPES } from '@app/providers/mainContext/constants';
import { useProviderHandlerCallback } from './hooks/useProviderHandlerCallback';
import {
  ATTRIBUTES_TRACKING,
  ERROR_CODE_TRACKING,
  EVENT_NAME_TRACKING,
  actionTracking,
} from '@app/utils/tracking';

const cancelChallengeFlow = [
  {
    type: BFF_ACTION_TYPES.CANCEL_FLOW,
    data: {
      query_params: {
        error: 'true',
      },
    },
  },
];

const ProviderChallengeCallback = () => {
  const { provider } = useParams();
  const { executeBFFActions } = useBFFActionsExecutor();
  const { challenge } = useAppChallenge();
  const [challengeError, setChallengeError] = useState(false);

  const {
    isError: submitErrorChallenge,
    reset: resetSubmitChallenge,
    mutate: submitChallenge,
  } = useSaveChallenge(challenge?.challengeType);

  const confirmChallenge = () => {
    const { challengeId, challengeType } = challenge || {};
    if (!challengeId || !challengeType) {
      logger.error(
        '[PROVIDER_CHALLENGE_CALLBACK][SAVE_NO_CHALLENGE_IN_SESSION]',
        `Not found challengeId: ${challengeId} or challengeType: ${challengeType} in session cache`,
      );
      setChallengeError(true);
      return;
    }

    executeBFFActions(
      actionTracking(EVENT_NAME_TRACKING.CHALLENGE_SUBMIT, {
        [ATTRIBUTES_TRACKING.CHALLENGE_TYPE]: challengeType.toLowerCase(),
      }),
    );

    submitChallenge(
      { challenge_id: challengeId },
      {
        onSuccess: (data) => {
          const actions = data?.actions;
          if (actions?.length) {
            logger.info(
              '[PROVIDER_CHALLENGE_CALLBACK][SAVE_SUCCESS]',
              `challengeId: ${challengeId} - challengeType: ${challengeType}`,
              stringify(actions),
            );
            executeBFFActions(actions);
          } else {
            executeBFFActions(
              actionTracking(EVENT_NAME_TRACKING.CHALLENGE_FAILED, {
                [ATTRIBUTES_TRACKING.CHALLENGE_TYPE]: challengeType.toLowerCase(),
                [ATTRIBUTES_TRACKING.ERROR_CODE]: ERROR_CODE_TRACKING.SUBMIT_ERROR,
              }),
            );
            logger.error(
              '[PROVIDER_CHALLENGE_CALLBACK][SAVE_NO_ACTION]',
              `challengeId: ${challengeId} - challengeType: ${challengeType}.`,
              'Submit challenge response do not have actions',
            );
            setChallengeError(true);
          }
        },
        onError: (error) => {
          executeBFFActions(
            actionTracking(EVENT_NAME_TRACKING.CHALLENGE_FAILED, {
              [ATTRIBUTES_TRACKING.CHALLENGE_TYPE]: challengeType.toLowerCase(),
              [ATTRIBUTES_TRACKING.ERROR_CODE]: ERROR_CODE_TRACKING.SUBMIT_ERROR,
            }),
          );
          logger.error(
            '[PROVIDER_CHALLENGE_CALLBACK][SAVE_ERROR]',
            `challengeId: ${challengeId} - challengeType: ${challengeType}.`,
            'Fail saving Provider challenge:',
            error.message,
          );
        },
      },
    );
  };

  const onError = (errorMessage) => {
    executeBFFActions(
      actionTracking(EVENT_NAME_TRACKING.CHALLENGE_FAILED, {
        [ATTRIBUTES_TRACKING.CHALLENGE_TYPE]: challenge?.challengeType?.toLowerCase() || 'UNKNOWN',
        [ATTRIBUTES_TRACKING.ERROR_CODE]: ERROR_CODE_TRACKING.PROVIDER_ERROR,
      }),
    );
    setChallengeError(true);
  };

  const onCancel = () => executeBFFActions([{ type: BFF_ACTION_TYPES.CANCEL_FLOW }]);

  useProviderHandlerCallback(provider, { onSuccess: confirmChallenge, onError, onCancel });

  const cancelChallengeWithError = () => executeBFFActions(cancelChallengeFlow);

  if (submitErrorChallenge) {
    return (
      <BrandErrorContainer
        fenixLayout
        onPrimaryAction={() => {
          resetSubmitChallenge();
          setChallengeError(false);
          confirmChallenge();
        }}
        secondaryLabel="Volver"
        onSecondaryAction={cancelChallengeWithError}
      />
    );
  }

  if (challengeError) {
    return (
      <BrandErrorContainer
        fenixLayout
        secondaryLabel="Volver"
        onSecondaryAction={cancelChallengeWithError}
        errorCode={400}
      />
    );
  }

  return <PeyaLoader position="center" />;
};

export default ProviderChallengeCallback;
