import getAxiosInstance from './axios';
import { URLS } from './constants';
import serviceErrorWrapper from './serviceErrorWrapper';

async function getNeedChange(totalAmount, currencySymbol, axios = getAxiosInstance()) {
  return serviceErrorWrapper('getNeedChange', async () => {
    const searchParams = new URLSearchParams();
    if (totalAmount) searchParams.append('totalAmount', totalAmount);
    if (currencySymbol) searchParams.append('currencySymbol', currencySymbol);
    const { data } = await axios.get(`${URLS.NEED_CHANGE}?${searchParams.toString()}`);
    return data;
  });
}

export default getNeedChange;
