import React from 'react';
import { useGoBack } from '@app/hooks/useGoBack';
import Error from '@pedidosya/web-fenix/pages/Error';

const NotFound = () => {
  const { goBack } = useGoBack();

  return (
    <Error errorCode={404} primaryActionLabel="Ir al inicio" primaryActionClick={() => goBack()} />
  );
};

export default NotFound;
