import { STATE_KEYS } from '../constants';
import { useAppState } from '../context';

export const useAppNeedChange = () => {
  const [state] = useAppState();
  return {
    [STATE_KEYS.AMOUNT_NEED_CHANGE]: state[STATE_KEYS.AMOUNT_NEED_CHANGE],
    [STATE_KEYS.CURRENCY_SYMBOL_NEED_CHANGE]: state[STATE_KEYS.CURRENCY_SYMBOL_NEED_CHANGE],
  };
};
