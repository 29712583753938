import React, { useEffect } from 'react';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';

import { BrandErrorContainer } from '@app/components/Error';

import { useDisableUserScale } from '@app/hooks/useDisableUserScale';
import { useGoBack } from '@app/hooks/useGoBack';
import { useHardNavigate } from '@app/hooks/useHardNavigate';
import { useIsEntryPointPage } from '@app/hooks/useIsEntryPointPage';
import { useConsumerIsMicrosite } from '@app/hooks/useMicrositeConsumer';
import useOnce from '@app/hooks/useOnce';

import logger from '@app/logger';
import { QUERY_PARAM } from '@commons/constants';
import { useAppCallbacks } from '@app/providers/mainContext/selectors/useAppCallbacks';
import { publishChangeKeyboardBehaviorEvents } from '@app/utils/changeKeyboardBehaviorEvents';
import { useQueryParamValue } from '@app/utils/domRouter';
import { NEW_PCI_PATHS, URIS_FRONT } from '@commons/services/routes.service';
import { useContextForPMCard } from '@app/services/queries';
import { CALLBACK_TYPES } from '../NewCardExternal/constants';

import { updateSecurityCode } from '@app/providers/mainContext/actions/updateSecurityCode';
import { useAppDispatch } from '@app/providers/mainContext/selectors/useAppDispatch';

const SecurityCodeExternal = () => {
  const instrumentId = useQueryParamValue(QUERY_PARAM.INSTRUMENT_ID);
  const challengeId = useQueryParamValue(QUERY_PARAM.CHALLENGE_ID);

  const dispatch = useAppDispatch();
  const { cancelCallback } = useAppCallbacks();
  const hardNavigate = useHardNavigate();
  const isConsumedByMicrosite = useConsumerIsMicrosite();
  const isEntryPointPage = useIsEntryPointPage();
  const { goBack } = useGoBack();
  const { error, data, refetch } = useContextForPMCard();

  // COMMENT: Keep this useEffect at the beginning to avoid scrolling in iOS devices.
  useOnce(() => publishChangeKeyboardBehaviorEvents());

  useDisableUserScale();

  useOnce(() => logger.info('[SECURITY_CODE_EXTERNAL][INTENT_REDIRECT]'));

  useEffect(() => {
    if ((!challengeId && !instrumentId) || !data) return;

    updateSecurityCode(dispatch, { challengeId, instrumentId });
    const frontParams = challengeId ? { challengeId } : { instrumentId };
    let queryParams = {
      ...data.queryParams,
      ...frontParams,
      callbackType: CALLBACK_TYPES.REDIRECT,
      successCallback: URIS_FRONT.CVV_EXTERNAL_SUCCESS,
    };

    if (!isConsumedByMicrosite && isEntryPointPage) {
      queryParams = { ...queryParams, cancelCallback };
    }

    const pciOrigin = data.origin || window.location.origin;

    const pciUrl = `${pciOrigin}${NEW_PCI_PATHS.SECURITY_CODE}`;

    hardNavigate(pciUrl, queryParams, { replace: true });
  }, [
    cancelCallback,
    challengeId,
    data,
    data?.queryParams,
    dispatch,
    hardNavigate,
    instrumentId,
    isConsumedByMicrosite,
    isEntryPointPage,
  ]);

  if (!challengeId && !instrumentId) {
    logger.error(
      '[SECURITY_CODE_EXTERNAL][MISSING_PARAMS]',
      'Missing challengeId and instrumentId params',
    );
    return (
      <BrandErrorContainer
        fenixLayout
        secondaryLabel="Volver"
        onSecondaryAction={() => goBack()}
        errorCode={400}
      />
    );
  }

  if (error) {
    logger.error(
      '[SECURITY_CODE_EXTERNAL][SERVICE_ERROR]',
      `status: ${error.response?.status} - message: ${error.message}`,
    );
    return (
      <BrandErrorContainer
        fenixLayout
        onPrimaryAction={refetch}
        secondaryLabel="Volver"
        onSecondaryAction={() => goBack()}
        errorCode={error.response?.status}
      />
    );
  }

  return <PeyaLoader position="center" />;
};

export default SecurityCodeExternal;
