import { PROVIDERS_NAMES } from './providers/constants';
import { createTokenForNewCard } from './providers/spreedly/spreedlyTokenization';

export const createTokenForCard = (providerName, { ...params }) => {
  // TODO: Do dynamic imports only for the corresponding provider
  switch (providerName) {
    case PROVIDERS_NAMES.SPREEDLY:
    default:
      return createTokenForNewCard({ ...params });
  }
};
