export const ACTION_TYPE = {
  SET_CHECKOUT_ID: 'SET_CHECKOUT_ID',
  REMOVE_CHECKOUT_ID: 'REMOVE_CHECKOUT_ID',
  UPDATE_SUCCESS_CALLBACK: 'UPDATE_SUCCESS_CALLBACK',
  UPDATE_CANCEL_CALLBACK: 'UPDATE_CANCEL_CALLBACK',
  INVALIDATE_SESSION_CACHE: 'INVALIDATE_SESSION_CACHE',
  OPEN_DIALOG: 'OPEN_DIALOG',
  DISMISS_DIALOG: 'DISMISS_DIALOG',
  SHOW_TOOLTIP: 'SHOW_TOOLTIP',
  HIDE_TOOLTIP: 'HIDE_TOOLTIP',
  UPDATE_ENTRY_POINT_PAGE: 'UPDATE_ENTRY_POINT_PAGE',
  UPDATE_TERMS_AND_CONDITIONS_STATUS: 'UPDATE_TERMS_AND_CONDITIONS_STATUS',
  SELECT_INSTRUMENTS: 'SELECT_INSTRUMENTS',
  UPDATE_NEED_CHANGE: 'UPDATE_NEED_CHANGE',
  UPDATE_CHALLENGE: 'UPDATE_CHALLENGE',
  UPDATE_SECURITY_CODE: 'UPDATE_SECURITY_CODE',
};
