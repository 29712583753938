import React, { useEffect } from 'react';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
import { BrandErrorContainer } from '../../components/Error';
import { useGoBack } from '@app/hooks/useGoBack';
import { useContextForPMCard } from '@app/services/queries';
import logger from '@app/logger';
import { PCI_PATHS, URIS_FRONT } from '@commons/services/routes.service';
import { useAppCallbacks } from '@app/providers/mainContext/selectors/useAppCallbacks';
import { useConsumerIsMicrosite } from '@app/hooks/useMicrositeConsumer';
import { useHardNavigate } from '@app/hooks/useHardNavigate';
import { useIsEntryPointPage } from '@app/hooks/useIsEntryPointPage';
import { CALLBACK_TYPES } from './constants';

const NewCardExternal = () => {
  const { goBack } = useGoBack();
  const { cancelCallback } = useAppCallbacks();
  const { isLoading, error, data, refetch } = useContextForPMCard();
  const hardNavigate = useHardNavigate();
  const isConsumedByMicrosite = useConsumerIsMicrosite();
  const isEntryPointPage = useIsEntryPointPage();

  useEffect(() => {
    if (!data) return;
    let queryParams = {
      ...data?.queryParams,
      callbackType: CALLBACK_TYPES.REDIRECT,
      successCallback: URIS_FRONT.NEW_CARD_EXTERNAL_FORM_SUCCESS_CALLBACK,
    };
    if (!isConsumedByMicrosite && isEntryPointPage) {
      queryParams = { ...queryParams, cancelCallback };
    }
    const pciOrigin = data.origin || window.location.origin;
    hardNavigate(`${pciOrigin}${PCI_PATHS.NEW_CARD_FORM}`, queryParams, { replace: true });
  }, [
    cancelCallback,
    data,
    data?.queryParams,
    hardNavigate,
    isConsumedByMicrosite,
    isEntryPointPage,
  ]);

  if (isLoading) return <PeyaLoader position="center" />;
  if (error) {
    logger.error(
      '[NEW_CARD_EXTERNAL][CONTEXT_FOR_PM_CARD_ERROR]',
      `status: ${error.response?.status} - message: ${error.message}`,
    );
    return (
      <BrandErrorContainer
        fenixLayout
        onPrimaryAction={refetch}
        secondaryLabel="Volver"
        onSecondaryAction={() => goBack()}
        errorCode={error.response?.status}
      />
    );
  }

  return <PeyaLoader position="center" />;
};

export default NewCardExternal;
