import styled from 'styled-components';
import CardContainerFenix from '@pedidosya/web-fenix/molecules/CardContainer';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 84px;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.borderRadius('border-radius-large')};
  border: ${({ theme, border = true }) =>
    border &&
    `solid ${theme.borderWidth('border-width-thin')} ${theme.color('shape-color-stroke-primary')}`};
`;

export const StyledCard = styled(CardContainerFenix)`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  padding: ${({ theme }) =>
    `${theme.space('spacing-component-4xlarge')} ${theme.space('spacing-component-xlarge')}`};
  gap: ${({ theme }) => theme.space('spacing-component-2xlarge')};
  box-sizing: border-box;
  overflow-y: auto;
`;
