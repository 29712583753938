import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
import { ROUTES } from './constants/routes';
import { usePaymentMethodsCallbacksUpdate } from './hooks/usePaymentMethodsCallbacksUpdate';
import { useEntryPointPageUpdate } from './hooks/useEntryPointPageUpdate';
import CallbackExternalProviders from './pages/CallbackExternalProviders';
import ExternalProviders from './pages/ExternalProviders';
import PaymentMethods from './pages/PaymentMethods';
import UserInstrumentsBFF from './pages/UserInstruments';
import { NewDebitOrCreditCardBFF } from './pages/NewDebitOrCreditCard';
import NotFound from './pages/NotFound';
import { Challenges, ChallengesError } from './pages/Challenges';
import TigoOTPChallenge from './pages/Challenges/challengesType/TigoOTPChallenge';
import TermsAndConditionsDetail from './pages/TermsAndConditionsDetail';
import BuyNowPayLaterChallenge from './pages/Challenges/challengesType/BuyNowPayLaterChallenge';
import NeedChange from './pages/NeedChange';
import NewCardExternal from './pages/NewCardExternal';
import SecurityCodeExternal from '@app/pages/SecurityCodeExternal';
import NewCardExternalSuccessCallback from '@app/pages/NewCardExternalSuccessCallback';
import { ChinChinChallenge } from './pages/Challenges/challengesType/ChinChinChallenge';
import { ChinChinChallengeSuccess } from '@app/pages/Challenges/challengesType/ChinChinChallenge';
import ProviderChallenge from './pages/Challenges/challengesType/ProviderChallenge';
import ProviderChallengeCallback from './pages/Challenges/challengeCallbacks/ProviderChallengeCallback/ProviderChallengeCallback';
import SecurityCodeExternalSuccessCallback from './pages/SecurityCodeExternalSuccessCallback';
import DisabledUserInstruments from './pages/DisabledUserInstruments';

const Router = () => {
  usePaymentMethodsCallbacksUpdate();
  useEntryPointPageUpdate();
  return (
    <Routes>
      <Route path={ROUTES.USER_PAYMENT_INSTRUMENTS} element={<UserInstrumentsBFF />} />
      <Route path={ROUTES.DISABLED_USER_INSTRUMENTS} element={<DisabledUserInstruments />} />
      <Route path={ROUTES.NEW_PAYMENT_METHOD_SELECTION} element={<PaymentMethods />} />
      <Route path={ROUTES.EXTERNAL_PROVIDER} element={<ExternalProviders />} />
      <Route path={ROUTES.CALLBACK_EXTERNAL_PROVIDER} element={<CallbackExternalProviders />} />
      <Route path={ROUTES.NEW_CARD_EXTERNAL_FORM}>
        <Route path={ROUTES.NEW_CARD_EXTERNAL_FORM} element={<NewCardExternal />} />
        <Route
          path={ROUTES.NEW_CARD_EXTERNAL_FORM_SUCCESS_CALLBACK}
          element={<NewCardExternalSuccessCallback />}
        />
      </Route>
      <Route path={ROUTES.NEW_CARD} element={<NewDebitOrCreditCardBFF />} />
      <Route path={ROUTES.CHALLENGES}>
        <Route path={ROUTES.CHALLENGES} element={<Challenges />} />
        <Route path={ROUTES.TIGO_OTP_CHALLENGE} element={<TigoOTPChallenge />} />
        <Route path={ROUTES.BUY_NOW_PAY_LATER_CHALLENGE} element={<BuyNowPayLaterChallenge />} />
        <Route path={ROUTES.CVV_EXTERNAL} element={<SecurityCodeExternal />} />
        <Route
          path={ROUTES.CVV_EXTERNAL_SUCCESS}
          element={<SecurityCodeExternalSuccessCallback />}
        />
        <Route path={ROUTES.CHIN_CHIN_CHALLENGE} element={<ChinChinChallenge />} />
        <Route path={ROUTES.CHIN_CHIN_CHALLENGE_SUCCESS} element={<ChinChinChallengeSuccess />} />
        <Route path={ROUTES.PROVIDER_CHALLENGE} element={<ProviderChallenge />} />
        <Route path={ROUTES.PROVIDER_CHALLENGE_CALLBACK} element={<ProviderChallengeCallback />} />
      </Route>
      <Route path={ROUTES.CHALLENGE_ERROR} element={<ChallengesError />} />
      {/* Routes for pre-defined callbacks between Mobile & Web */}
      {/* Shows only a Spinner meanwhile Webview is closed */}
      <Route
        path={ROUTES.PAYMENT_INSTRUMENT_SELECTION_SUCCESS}
        element={<PeyaLoader position="center" />}
      />
      <Route
        path={ROUTES.PAYMENT_INSTRUMENT_SELECTION_CANCEL}
        element={<PeyaLoader position="center" />}
      />
      <Route path={ROUTES.CHALLENGE_SUCCESS} element={<PeyaLoader position="center" />} />
      <Route path={ROUTES.CHALLENGE_CANCEL} element={<PeyaLoader position="center" />} />
      <Route path={ROUTES.TERMS_AND_CONDITIONS_DETAIL} element={<TermsAndConditionsDetail />} />
      <Route path={ROUTES.CASH_PAYMENT_NEED_CHANGE} element={<NeedChange />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Router;
