import { useMemo } from 'react';
import { useAppCallbacks } from '../providers/mainContext/selectors/useAppCallbacks';

const POSSIBLE_CONSUMERS = {
  OTHER_MICROSITE: 'microsite',
  MOBILE_APP: 'mobile',
};

/** TODO: Consumer should not be determined by the existance of cancelCallback */
const useMicrositeConsumer = () => {
  const { cancelCallback } = useAppCallbacks();

  if (cancelCallback) {
    return POSSIBLE_CONSUMERS.MOBILE_APP;
  }

  return POSSIBLE_CONSUMERS.OTHER_MICROSITE;
};

export const useConsumerIsMicrosite = () => {
  const consumer = useMicrositeConsumer();
  return useMemo(() => consumer === POSSIBLE_CONSUMERS.OTHER_MICROSITE, [consumer]);
};
