import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppEntryPointPage } from '../providers/mainContext/selectors/useAppEntryPointPage';

export const useIsEntryPointPage = () => {
  const [isEntryPointPage, setIsEntryPointPage] = useState(false);
  const { pathname: currentPath } = useLocation();
  const { entryPointPage } = useAppEntryPointPage();

  useEffect(() => {
    setIsEntryPointPage(entryPointPage === currentPath);
  }, [currentPath, entryPointPage]);

  return isEntryPointPage;
};
