import React from 'react';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
import { useQueryParamValue } from '@app/utils/domRouter';
import { QUERY_PARAM } from '@commons/constants';
import { QUERIES_NAMES } from '@app/services/queries';
import { useSelectPaymentInstruments } from '@app/hooks/useSelectPaymentInstruments';
import { useInvalidateQueriesAndRefetchInactive } from '@app/hooks/useInvalidateQueriesAndRefetchInactive';
import useOnce from '@app/hooks/useOnce';
import { useAppTracker } from '@app/providers/mainContext/selectors/useAppTracker';

const EVENT_NAME_SUCCESS = 'online_payment_add.succeeded';

const CallbackExternalProviders = () => {
  const { tracker } = useAppTracker();
  const { selectPaymentInstruments } = useSelectPaymentInstruments();
  const { invalidateQueriesAndRefetchInactive } = useInvalidateQueriesAndRefetchInactive();
  const instrumentId = useQueryParamValue(QUERY_PARAM.PAYMENT_INSTRUMENTS_SELECTED);
  const providerName = useQueryParamValue(QUERY_PARAM.PROVIDER_NAME);

  useOnce(() => {
    tracker.track(EVENT_NAME_SUCCESS, {
      orderPaymentMethod: providerName,
    });
    invalidateQueriesAndRefetchInactive([QUERIES_NAMES.USER_INSTRUMENTS]);
    selectPaymentInstruments([{ id: instrumentId }]);
  });

  return <PeyaLoader position="center" />;
};

export default CallbackExternalProviders;
