import React from 'react';
import styled from 'styled-components';
import buildFont from './buildFont';

const StyledParagraph = styled.p`
  ${({ weight }) => buildFont(weight)}
  color: ${({ color }) => color};
  margin: 0;
`;

const Text = ({ value, style: { weight, color } }) => {
  return (
    <StyledParagraph weight={weight} color={color}>
      {value}
    </StyledParagraph>
  );
};

export default Text;
