/* eslint-disable no-unused-expressions */
import { useEffect } from 'react';

// on loading el theme es light

const payload = {
  backgroundColor: '#00FFFFFF',
  theme: 'light',
  showActionButton: true,
  showHamburgerButton: false,
};

const iOSMessage = {
  command: 'webview-event',
  event: 'updateHeader',
};

const publishiOS = (event) =>
  window.webkit?.messageHandlers?.ListenEventsInterface?.postMessage({
    ...iOSMessage,
    payload: { ...event },
  });

const publishAndroid = (event) =>
  window.NavigationHeaderInterface?.updateHeader(JSON.stringify(event));

const publishEventsOnDevice = (event) => {
  publishAndroid({ ...payload, ...event });
  publishiOS({ ...payload, ...event });
};

export const useHeaderConfiguration = ({
  isWebView = false,
  isError,
  showHamburgerButton = false,
} = {}) => {
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isWebView) {
      if (isError) {
        publishEventsOnDevice({
          ...payload,
          showHamburgerButton: true,
          theme: 'light',
          showActionButton: false,
        });
      }
      publishEventsOnDevice({
        ...payload,
        showHamburgerButton,
      });

      return () => {
        publishEventsOnDevice({
          ...payload,
          showActionButton: false,
        });
      };
    }
  }, [isError, isWebView, showHamburgerButton]);
};
