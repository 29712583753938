import {
  parseMMYYtoMonthAndYear,
  getCurrentYear,
  getCurrentMonth,
} from '../../../../../../../../utils/date';

/**
 * Validates card expiration input with format MM/YY
 * The card expiration should be in the future to be valid
 *
 * @param {String} input Tentative card expiration input with format MM/YY.
 * @returns {boolean} true or false whether the input parameter is a valid card expiration.
 */
function isCardExpirationValid(input) {
  if (!input) return false;

  const [month, year] = parseMMYYtoMonthAndYear(input);
  if (!month || !year) return false;

  const currentYear = getCurrentYear();

  if (year < currentYear) return false;

  if (year > currentYear) return true;

  // same year, check month
  return month > getCurrentMonth();
}

export default isCardExpirationValid;
