export const NOT_SET_VALUE = '(not set)';

export const ATTRIBUTES_TRACKING = {
  ERROR_CODE: 'errorCode',
  ADD_CARD_OUTCOME: 'addCardOutcome',
  INSTRUMENT_ID: 'instrumentId',
  ORDER_PAYMENT_METHOD: 'orderPaymentMethod',
  SCREEN_TYPE: 'screenType',
  CHALLENGE_TYPE: 'challengeType',
};

export const ERROR_CODE_TRACKING = {
  GET_SCREEN_ERROR: 'get_screen_error',
  SUBMIT_ERROR: 'submit_error',
  REDIRECT_URL_ERROR: 'redirect_url_error',
  PAYMENT_ERROR: 'payment_error',
  PROVIDER_ERROR: 'provider_error',
};

export const EVENT_NAME_TRACKING = {
  CHALLENGE_LOADED: 'payment_challenge.loaded',
  CHALLENGE_FAILED: 'payment_challenge.failed',
  CHALLENGE_SUBMIT: 'payment_challenge.submit',
  NEW_CARD: 'online_payment_add_card_proceeded',
};

export const ADD_CARD_OUTCOME = {
  FAILED: 'failed',
};

export const actionTracking = (event, attributes) => [
  {
    type: 'TRACKING',
    data: {
      event,
      attributes,
    },
  },
];
