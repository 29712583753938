import { QUERY_PARAM } from '@commons/constants';
import { sessionCache, KEYS as CACHE_KEYS } from '../services/cache';
import { useQueryParamValue } from '../utils/domRouter';
import { useAppSelectedInstruments } from '@app/providers/mainContext/selectors/useAppSelectedInstruments';
import logger from '@app/logger';
import { parseInstrumentsFromQuery } from '@app/pages/UserInstruments/utils';

/**
 * This hook try to recover instrument ids from context, session or query params
 */
export const usePaymentInstrumentsSelected = () => {
  const instrumentsFromQuery = useQueryParamValue(QUERY_PARAM.SELECTED_INSTRUMENTS);
  const { selectedInstruments } = useAppSelectedInstruments();

  if (selectedInstruments?.length > 0) {
    logger.debug(
      '[USER_INSTRUMENTS][SELECT_INSTRUMENT]',
      `Get instrument from context: ${JSON.stringify(selectedInstruments)}`,
    );
    return selectedInstruments;
  }

  const selectedInstrumentsCache = sessionCache.get(CACHE_KEYS.SELECTED_INSTRUMENTS);
  if (selectedInstrumentsCache) {
    logger.debug(
      '[USER_INSTRUMENTS][SELECT_INSTRUMENT]',
      `Get instrument from cache: ${JSON.stringify(selectedInstrumentsCache)}`,
    );
    return selectedInstrumentsCache;
  }

  const parsedInstrumentsFromQuery = parseInstrumentsFromQuery(instrumentsFromQuery);

  return parsedInstrumentsFromQuery;
};
