import { ACTION_TYPE } from './actionsType';
import { INITIAL_STATE, STATE_KEYS } from './constants';

export const reducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPE.SET_CHECKOUT_ID: {
      return {
        ...state,
        [STATE_KEYS.CHECKOUT_ID]: payload[STATE_KEYS.CHECKOUT_ID],
      };
    }
    case ACTION_TYPE.REMOVE_CHECKOUT_ID: {
      return {
        ...state,
        [STATE_KEYS.CHECKOUT_ID]: '',
      };
    }
    case ACTION_TYPE.UPDATE_SUCCESS_CALLBACK: {
      return {
        ...state,
        [STATE_KEYS.SUCCESS_CALLBACK]: payload[STATE_KEYS.SUCCESS_CALLBACK],
      };
    }
    case ACTION_TYPE.UPDATE_CANCEL_CALLBACK: {
      return {
        ...state,
        [STATE_KEYS.CANCEL_CALLBACK]: payload[STATE_KEYS.CANCEL_CALLBACK],
      };
    }
    case ACTION_TYPE.INVALIDATE_SESSION_CACHE: {
      return {
        ...state,
        [STATE_KEYS.CANCEL_CALLBACK]: undefined,
        [STATE_KEYS.SUCCESS_CALLBACK]: undefined,
        [STATE_KEYS.ENTRY_POINT_PAGE]: undefined,
        [STATE_KEYS.AMOUNT_NEED_CHANGE]: undefined,
        [STATE_KEYS.CURRENCY_SYMBOL_NEED_CHANGE]: undefined,
        [STATE_KEYS.CHALLENGE]: undefined,
        [STATE_KEYS.SECURITY_CODE]: undefined,
      };
    }
    case ACTION_TYPE.OPEN_DIALOG: {
      return {
        ...state,
        [STATE_KEYS.DIALOG_CONTEXT]: { ...payload[STATE_KEYS.DIALOG_CONTEXT], open: true },
      };
    }
    case ACTION_TYPE.DISMISS_DIALOG: {
      return {
        ...state,
        [STATE_KEYS.DIALOG_CONTEXT]: { open: false },
      };
    }
    case ACTION_TYPE.SHOW_TOOLTIP: {
      return {
        ...state,
        [STATE_KEYS.TOOLTIP_CONTEXT]: { ...payload[STATE_KEYS.TOOLTIP_CONTEXT], show: true },
      };
    }
    case ACTION_TYPE.HIDE_TOOLTIP: {
      return {
        ...state,
        [STATE_KEYS.TOOLTIP_CONTEXT]: { show: false },
      };
    }
    case ACTION_TYPE.UPDATE_ENTRY_POINT_PAGE: {
      return {
        ...state,
        [STATE_KEYS.ENTRY_POINT_PAGE]: payload[STATE_KEYS.ENTRY_POINT_PAGE],
      };
    }
    case ACTION_TYPE.UPDATE_TERMS_AND_CONDITIONS_STATUS: {
      return {
        ...state,
        [STATE_KEYS.TERMS_AND_CONDITIONS_STATUS]: payload[STATE_KEYS.TERMS_AND_CONDITIONS_STATUS],
      };
    }
    case ACTION_TYPE.SELECT_INSTRUMENTS: {
      return {
        ...state,
        [STATE_KEYS.SELECTED_INSTRUMENTS]: payload[STATE_KEYS.SELECTED_INSTRUMENTS],
      };
    }
    case ACTION_TYPE.UPDATE_NEED_CHANGE: {
      return {
        ...state,
        [STATE_KEYS.AMOUNT_NEED_CHANGE]: payload[STATE_KEYS.AMOUNT_NEED_CHANGE],
        [STATE_KEYS.CURRENCY_SYMBOL_NEED_CHANGE]: payload[STATE_KEYS.CURRENCY_SYMBOL_NEED_CHANGE],
      };
    }
    case ACTION_TYPE.UPDATE_CHALLENGE: {
      return {
        ...state,
        [STATE_KEYS.CHALLENGE]: payload[STATE_KEYS.CHALLENGE],
      };
    }
    case ACTION_TYPE.UPDATE_SECURITY_CODE: {
      return {
        ...state,
        [STATE_KEYS.SECURITY_CODE]: payload[STATE_KEYS.SECURITY_CODE],
      };
    }
    default:
      return state;
  }
};
