import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
import { useBFFActionDefinitions } from '@app/hooks/useBFFActionDefinitions';
import { initClientLogger } from '@app/logger';
import { useTrackerLoader } from '@app/providers/tracker';
import { getQueryParam } from '@app/utils/domRouter';
import { BackendDrivenProvider } from '@checkout-ui/backend-driven';
import { QUERY_PARAM } from '@commons/constants';
import { ROUTES } from '@commons/services/routes.service';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import semverCoerce from 'semver/functions/coerce';
import semverValid from 'semver/functions/valid';
import { usePublicEnv } from '../PublicEnv';
import { INITIAL_STATE } from './constants';
import { AppProvider } from './context';
import { reducer } from './reducer';

const useTrackerLoaderSetup = (trackerPromise) => {
  const trackingOrigin = getQueryParam(window?.location, QUERY_PARAM.ORIGIN);

  const getTracker = useCallback(() => trackerPromise, [trackerPromise]);
  const value = useTrackerLoader({
    getTracker,
    origin: trackingOrigin,
  });

  return value;
};

const useClientLoggerSetup = (clientLoggerConfig) => {
  const [logger, setLogger] = useState(null);

  useEffect(() => {
    const clientLogger = initClientLogger(clientLoggerConfig);
    setLogger(clientLogger);
  }, [clientLoggerConfig]);

  return logger;
};

const BDUIProvider = ({ children, logger, deviceInfo }) => {
  const { actionDefinitions: defaultActionDefinitions } = useBFFActionDefinitions();
  const navigate = useNavigate();

  return (
    <BackendDrivenProvider
      navigate={navigate}
      logger={logger}
      ROUTES={ROUTES}
      deviceInfo={deviceInfo}
      defaultActionDefinitions={defaultActionDefinitions}
    >
      {children}
    </BackendDrivenProvider>
  );
};

export const AppProviderWithResources = ({ children, trackerPromise, initialState }) => {
  const context = usePublicEnv();
  const checkoutId = getQueryParam(window?.location, QUERY_PARAM.CHECKOUT_ID);
  const purchaseId = getQueryParam(window?.location, QUERY_PARAM.PURCHASE_ID);
  const requestId = getQueryParam(window?.location, QUERY_PARAM.REQUEST_ID);
  const { country, environment, deviceInfo, userId } = context;
  const clientLoggerConfig = useMemo(
    () => ({ country, environment, deviceInfo, userId, purchaseId, checkoutId, requestId }),
    [country, environment, deviceInfo, userId, purchaseId, checkoutId, requestId],
  );

  const clientLogger = useClientLoggerSetup(clientLoggerConfig);
  const { tracker, origin: trackingOrigin } = useTrackerLoaderSetup(trackerPromise);

  if (!clientLogger || !tracker) return <PeyaLoader position="center" />;

  const finalDeviceInfo = {
    ...deviceInfo,
    isWebview: () => deviceInfo.isWebview,
    isAndroid: () => deviceInfo.os === 'android',
    isIos: () => deviceInfo.os === 'ios',
    appVersion: semverValid(semverCoerce(deviceInfo.appVersion)),
  };

  return (
    <AppProvider
      initialState={{
        ...INITIAL_STATE,
        context: {
          ...context,
          deviceInfo: finalDeviceInfo,
        },
        trackerContext: { tracker, origin: trackingOrigin },
        ...initialState,
      }}
      reducer={reducer}
    >
      <BDUIProvider logger={clientLogger} deviceInfo={finalDeviceInfo}>
        {children}
      </BDUIProvider>
    </AppProvider>
  );
};
