import logger from '../../../../logger';
import defaultAxios from 'axios';
import { isProduction } from '@app/utils/environment';
import CardTokenizationError from '../../errors/cardTokenizationError';

const HTTP_STATUS_CODE_BAD_REQUEST = 400;

const SPREEDLY_URL = 'https://core.spreedly.com';

const SPREEDLY_KEY_DEV = 'NTmDXmQgoLjYAdoX8iUensIsBVx';
const SPREEDLY_KEY_PROD = 'I8mmcHW3PLQk5V2nJG7Jw9X6W3v';

const publicKey = () => (isProduction() ? SPREEDLY_KEY_PROD : SPREEDLY_KEY_DEV);

const parseSpreedlyErrors = (response) => {
  const errors = response?.data?.errors || [];
  const errorMessages = errors.map((e) => e.message);
  const errorMsg = errorMessages.join('|');
  return errorMsg || JSON.stringify(response);
};

const validateSpreedlyResponse = (response) => {
  if (
    response?.status >= HTTP_STATUS_CODE_BAD_REQUEST ||
    response?.data?.status >= HTTP_STATUS_CODE_BAD_REQUEST
  ) {
    const errorMessage = parseSpreedlyErrors(response);
    logger.error('[SPREEDLY_TOKENIZATION_ERROR]', 'Spreedly tokenization error', errorMessage);
    throw new CardTokenizationError(`spreedly tokenization error: ${errorMessage}`);
  }
};

export const createTokenForNewCard = async ({
  cardHolderName,
  cardNumber,
  cardSecurityCode,
  cardExpirationMonth,
  cardExpirationYear,
  axios = defaultAxios,
}) => {
  const response = await axios.post(
    `${SPREEDLY_URL}/v1/payment_methods.json?environment_key=${publicKey()}`,
    {
      payment_method: {
        credit_card: {
          full_name: cardHolderName,
          number: cardNumber,
          verification_value: cardSecurityCode,
          month: cardExpirationMonth,
          year: cardExpirationYear,
        },
      },
    },
  );

  validateSpreedlyResponse(response);

  return response;
};
