import React from 'react';
import { BackendDrivenComponent, useOnAppearActions } from '@checkout-ui/backend-driven';

import CardInstrument from './components/CardInstrument';
import BrandedInstrument from './components/BrandedInstrument';
import CardContainer from './components/CardContainer';
import SkeletonLoader from './components/SkeletonLoader';
import { StyledCard } from './components/StyledComponents';
import DisabledInstrumentsError from './components/DisabledInstrumentsError';

import { useDisabledUserInstruments } from '@app/services/queries';
import { useQueryParamValue } from '@app/utils/domRouter';
import { QUERY_PARAM } from '@commons/constants';
import { withChangeBgColor } from '@app/HOCs/withChangeBgColor';
import MultiBrandedInstrument from './components/MultiBrandedInstrument';

const DisabledUserInstruments = () => {
  const numberOfInstruments = useQueryParamValue(QUERY_PARAM.NUMBER_OF_INSTRUMENTS);
  const { data, error, isFetching } = useDisabledUserInstruments();

  const actions = isFetching ? [] : data?.on_appear?.actions;
  useOnAppearActions(actions);

  const componentResolvers = {
    CARD: ({ data: { direction, border = false } }) => ({
      component: StyledCard,
      props: { direction: direction.toLowerCase(), withShadow: false, withBorder: border },
    }),
    CARD_CONTAINER: ({ data: { enabled, error_message, tag_color } }) => ({
      component: CardContainer,
      props: {
        enabled: enabled,
        errorMessage: error_message,
        tagColor: tag_color,
      },
    }),
    CARD_INSTRUMENT: ({
      data: {
        issuer_name: issuerName,
        holder_name: holderName,
        card_number: cardNumber,
        background_color: backgroundColor,
        brand,
      },
    }) => ({
      component: CardInstrument,
      props: {
        issuerName,
        holderName,
        cardNumber,
        backgroundColor,
        brand,
      },
    }),
    BRANDED_INSTRUMENT: ({ data: { icon, image, background_color: backgroundColor } }) => ({
      component: BrandedInstrument,
      props: {
        icon,
        image,
        backgroundColor,
      },
    }),
    MULTIBRANDED_INSTRUMENT: ({
      data: {
        icon,
        image,
        payment_method_name: paymentMethodName,
        background_color: backgroundColor,
      },
    }) => ({
      component: MultiBrandedInstrument,
      props: {
        icon,
        image,
        paymentMethodName,
        backgroundColor,
      },
    }),
  };

  if (error) {
    const DisabledInstrumentsErrorWithBg = withChangeBgColor(DisabledInstrumentsError);
    return <DisabledInstrumentsErrorWithBg />;
  }
  if (isFetching) {
    const SkeletonLoaderWithBg = withChangeBgColor(SkeletonLoader);
    return <SkeletonLoaderWithBg numberOfInstruments={numberOfInstruments} />;
  }

  const BackendDrivenComponentWithBg = withChangeBgColor(BackendDrivenComponent);
  return (
    <BackendDrivenComponentWithBg
      componentResolvers={componentResolvers}
      components={data?.components}
    />
  );
};

export default DisabledUserInstruments;
