import { defineMessages } from 'react-intl';

export default defineMessages({
  label: {
    id: 'app.paymentMethod.inputs.cardHolderNameInput.label',
    defaultMessage: 'Nombre en la tarjeta',
  },
  errorMessage: {
    id: 'app.paymentMethod.inputs.cardHolderNameInput.errorMessage',
    defaultMessage: 'El nombre ingresado no es válido',
  },
});
