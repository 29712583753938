import React, { useState } from 'react';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
import { BrandErrorContainer } from '@app/components/Error';
import { useSaveChallenge } from '@app/services/mutations';
import { useAppChallenge } from '@app/providers/mainContext/selectors/useAppChallenge';
import logger from '@app/logger';
import stringify from '@commons/utils/object/stringify';
import { useBFFActionsExecutor } from '@checkout-ui/backend-driven';
import { CHALLENGE_TYPES } from '@app/pages/Challenges/constants';
import useOnce from '@app/hooks/useOnce';
import { BFF_ACTION_TYPES } from '@app/providers/mainContext/constants';
import { ERROR_CODE_TRACKING, EVENT_NAME_TRACKING, actionTracking } from '@app/utils/tracking';

const cancelChallengeFlow = [
  {
    type: BFF_ACTION_TYPES.CANCEL_FLOW,
    data: {
      query_params: {
        error: 'true',
      },
    },
  },
];

const ChinChinChallengeSuccess = () => {
  const { executeBFFActions } = useBFFActionsExecutor();
  const { challenge } = useAppChallenge();
  const [challengeError, setChallengeError] = useState(false);

  const {
    isError: submitErrorChallenge,
    reset: resetSubmitChallenge,
    mutate: submitChallenge,
  } = useSaveChallenge(CHALLENGE_TYPES.CHIN_CHIN);

  const cancelChallenge = () => executeBFFActions(cancelChallengeFlow);

  const confirmChallenge = () => {
    const { challengeId, challengeType } = challenge || {};
    if (challengeId && challengeType) {
      submitChallenge(
        { challenge_id: challengeId },
        {
          onSuccess: (data) => {
            const actions = data?.actions;
            if (actions?.length) {
              logger.info(
                '[CHIN_CHIN_CHALLENGE][CHIN_CHIN_SAVE_SUCCESS]',
                `challengeId: ${challengeId} - challengeType: ${challengeType}`,
                stringify(actions),
              );
              executeBFFActions(actions);
            } else {
              executeBFFActions(
                actionTracking(EVENT_NAME_TRACKING.CHALLENGE_FAILED, {
                  challengeType: CHALLENGE_TYPES.CHIN_CHIN.toLowerCase(),
                  errorCode: ERROR_CODE_TRACKING.SUBMIT_ERROR,
                }),
              );
              logger.error(
                '[CHIN_CHIN_CHALLENGE][CHIN_CHIN_SAVE_NO_ACTION]',
                'Submit challenge response do not have actions',
              );
              setChallengeError(true);
            }
          },
          onError: (error) => {
            executeBFFActions(
              actionTracking(EVENT_NAME_TRACKING.CHALLENGE_FAILED, {
                challengeType: CHALLENGE_TYPES.CHIN_CHIN.toLowerCase(),
                errorCode: ERROR_CODE_TRACKING.SUBMIT_ERROR,
              }),
            );
            logger.error(
              '[CHIN_CHIN_CHALLENGE][CHIN_CHIN_SAVE_ERROR]',
              'Fail saving ChinChin challenge:',
              error.message,
            );
          },
        },
      );
    } else {
      logger.error(
        '[CHIN_CHIN_CHALLENGE][CHIN_CHIN_SAVE_NO_CHALLENGE_IN_SESSION]',
        `Not found challengeId: ${challengeId} or challengeType: ${challengeType} in session cache`,
      );
      setChallengeError(true);
    }
  };

  useOnce(() => {
    logger.info('[CHIN_CHIN_CHALLENGE][NEW_INTENT_SUBMIT]');
    executeBFFActions(
      actionTracking(EVENT_NAME_TRACKING.CHALLENGE_SUBMIT, {
        challengeType: CHALLENGE_TYPES.CHIN_CHIN.toLowerCase(),
      }),
    );
    confirmChallenge();
  });

  if (submitErrorChallenge) {
    return (
      <BrandErrorContainer
        fenixLayout
        onPrimaryAction={() => {
          resetSubmitChallenge();
          setChallengeError(false);
          confirmChallenge();
        }}
        secondaryLabel="Volver"
        onSecondaryAction={cancelChallenge}
      />
    );
  }

  if (challengeError) {
    return (
      <BrandErrorContainer
        fenixLayout
        secondaryLabel="Volver"
        onSecondaryAction={cancelChallenge}
        errorCode={400}
      />
    );
  }

  return <PeyaLoader position="center" />;
};

export default ChinChinChallengeSuccess;
