import format from '../../commons/utils/string/format';
import { URLS } from './constants';
import getAxiosInstance from './axios';
import serviceErrorWrapper from './serviceErrorWrapper';

async function getNewUserInstrumentForm(vault, axios = getAxiosInstance()) {
  return serviceErrorWrapper('getNewUserInstrumentForm', async () => {
    const url = format(URLS.NEW_PAYMENT_INSTRUMENT_FORM, vault);
    const { data } = await axios.get(url);
    return data;
  });
}

export default getNewUserInstrumentForm;
