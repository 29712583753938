import React from 'react';
import PropTypes from 'prop-types';

import { BrandLoader } from '@appscore/web-components';
import styled, { css } from 'styled-components';

const StyledSpinner = styled.div`
  ${(props) =>
    props.fullScreen &&
    css`
      right: 50%;
      bottom: 50%;
      transform: translate(50%, 50%);
      position: absolute;
      text-align: center;
    `}

  ${(props) =>
    !props.fullScreen &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
    `}
`;

function Spinner({ fullScreen }) {
  return (
    <StyledSpinner fullScreen={fullScreen} role="progressbar">
      <BrandLoader platform="pedidosya" />
    </StyledSpinner>
  );
}

Spinner.propTypes = {
  fullScreen: PropTypes.bool,
};

Spinner.defaultProps = {
  fullScreen: true,
};

export default Spinner;
