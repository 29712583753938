import { useState, useContext, useEffect } from 'react';

import logger from '@app/logger';
import FwfFlagsContext from '@app/providers/funWithFlags';

export const useFlag = (flagKey, fallback = false) => {
  const { fwf } = useContext(FwfFlagsContext);

  const [flagValue, setFlagValue] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!fwf) {
      setIsLoading(false);
      return;
    }
    fwf
      .getFlag(flagKey, fallback)
      .then((value) => {
        logger.info(
          '[useFlag][SUCCESS]',
          `flagKey: ${flagKey} - flagResult: ${JSON.stringify(value)}`,
        );
        setFlagValue(value.variation);
      })
      .catch((error) => {
        logger.error('FWF_GETTING_FLAG_ERROR', {
          hook: 'useFlag',
          flagKey,
          error,
        });
      })
      .finally(() => setIsLoading(false));
  }, [fwf, flagKey, fallback]);

  return { flagValue, isLoading };
};
