import { GO_BACK } from '../../../constants/routes';
import { invalidateSessionCache } from './invalidateSessionCache';

export const goBack = (
  navigate,
  externalNavigate,
  cancelCallback,
  dispatch,
  isEntryPointPage,
  isConsumedByMicrosite,
) => {
  if (isEntryPointPage) {
    invalidateSessionCache(dispatch);

    if (isConsumedByMicrosite || !cancelCallback) {
      navigate(GO_BACK);
    } else {
      externalNavigate(cancelCallback);
    }
  } else {
    navigate(GO_BACK);
  }
};
