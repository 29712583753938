import React, { useEffect, useState } from 'react';
import { BrandError } from '@appscore/web-components';
import Error from '@pedidosya/web-fenix/pages/Error';
import isBadRequestStatus from '@app/utils/axios/isBadRequestStatus';
import { ErrorContainer } from './components/ErrorContainer';

const BrandErrorContainer = ({
  onPrimaryAction,
  onSecondaryAction,
  primaryLabel = 'Reintentar',
  secondaryLabel = 'Volver',
  fenixLayout,
  ...rest
}) => {
  const { errorCode } = rest;
  const [actionProps, setActionProps] = useState({
    primaryActionClick: onPrimaryAction,
    primaryActionLabel: primaryLabel,
    secondaryActionClick: onSecondaryAction,
    secondaryActionLabel: secondaryLabel,
  });

  useEffect(() => {
    const { primaryActionClick, secondaryActionLabel, secondaryActionClick } = actionProps;

    if (!primaryActionClick || isBadRequestStatus(errorCode)) {
      if (secondaryActionClick) {
        setActionProps((prevState) => {
          const newState = {
            ...prevState,
            primaryActionLabel: secondaryActionLabel,
            primaryActionClick: secondaryActionClick,
          };
          delete newState.secondaryActionLabel;
          delete newState.secondaryActionClick;
          return newState;
        });
      }
    }
  }, [actionProps, errorCode]);

  if (fenixLayout) {
    return <Error {...rest} {...actionProps} />;
  }

  return (
    <ErrorContainer>
      <BrandError
        onPrimaryAction={onPrimaryAction}
        onSecondaryAction={onSecondaryAction}
        primaryLabel={primaryLabel}
        secondaryLabel={secondaryLabel}
        {...rest}
      />
    </ErrorContainer>
  );
};

export default BrandErrorContainer;
