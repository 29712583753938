import pkg from '../../package.json';

export const QUERY_PARAM = {
  ORIGIN: 'origin',
  URL: 'url',
  PROVIDER_NAME: 'provider_name',
  PROVIDER_TITLE: 'title',
  CHECKOUT_ID: 'checkoutId',
  PURCHASE_ID: 'purchaseId',
  VAULT_PROVIDER: 'vaultProvider',
  PROVIDER_CARD_ID: 'providerCardId',
  PAYMENT_INSTRUMENTS_SELECTED: 'paymentInstrumentsSelected',
  CALLBACKS: {
    SUCCESS: 'successCallback',
    CANCEL: 'cancelCallback',
  },
  REQUEST_ID: 't',
  CHALLENGE_ID: 'challenge_id',
  CHALLENGE: {
    ID: 'challenge_id',
    TYPE: 'challenge_type',
    TRANSACTION_TOKEN: 't',
  },
  CHALLENGE_CALLBACK: {
    WEBPAY: {
      TBK_TOKEN: 'TBK_TOKEN',
      TBK_ID_SESION: 'TBK_ID_SESION',
      TBK_ORDEN_COMPRA: 'TBK_ORDEN_COMPRA',
      TOKEN_WS: 'token_ws',
    },
    BRUCE: {
      PAYMENT_RESULT: 'paymentResult',
      EXCEPTION_TYPE: 'exceptionType',
    },
  },
  INSTRUMENT_ID: 'instrumentId',
  SELECTED_INSTRUMENTS: 'selectedInstruments',
  TOTAL_AMOUNT_NEED_CHANGE: 'totalAmount',
  CURRENCY_SYMBOL_NEED_CHANGE: 'currencySymbol',
  MOCK_HEADER_MSW: 'mockHeader',
  BUSSINES_CONTEXT: 'businessType',
  NUMBER_OF_INSTRUMENTS: 'numberOfInstruments',
};

export const API_BASE_URI = `/api/${pkg.name}`;
