import pkg from '../../../../package.json';
import { datadogLogs } from '@datadog/browser-logs';
import { STG_ENVIRONMENT, PROD_ENVIRONMENT } from '../../../../commons/constants/environments';
import { DATADOG_CREDENTIALS } from '../constants';
import { setInstance } from './instance';

const DATADOG_SITE = 'datadoghq.com';
const LOGGER_NAME = 'checkout-payment-methods-web';

export const DATADOG_LOG_LEVEL = {
  debug: 'debug',
  info: 'info',
  warn: 'warn',
  warning: 'warn',
  error: 'error',
  fatal: 'error',
};

export const initDataDogLogger = ({
  country,
  environment,
  deviceInfo,
  userId,
  purchaseId,
  checkoutId,
  requestId,
}) => {
  const isWebview = deviceInfo?.isWebview;
  const credentials = isWebview ? DATADOG_CREDENTIALS.webview : DATADOG_CREDENTIALS.web;
  datadogLogs.init({
    clientToken: credentials.clientToken,
    service: credentials.service,
    site: DATADOG_SITE,
    forwardErrorsToLogs: true,
    sampleRate: 100,
    env: environment,
    version: pkg.version,
  });

  const handlers = ['console'];
  handlers.push('http');

  const logLevel =
    environment === PROD_ENVIRONMENT ? DATADOG_LOG_LEVEL.info : DATADOG_LOG_LEVEL.debug;

  const instance = datadogLogs.createLogger(LOGGER_NAME, {
    level: logLevel,
    handler: handlers,
    context: {
      isWebview,
      country,
      environment,
      userId,
      purchaseId,
      checkoutId,
      requestId,
    },
  });
  setInstance(instance);
};
