import format from '../../commons/utils/string/format';
import { URLS } from './constants';
import getAxiosInstance from './axios';
import serviceErrorWrapper from './serviceErrorWrapper';

async function addNewPaymentInstrument(vault, vaultData, axios = getAxiosInstance()) {
  return serviceErrorWrapper('addNewPaymentInstrument', async () => {
    const url = format(URLS.NEW_PAYMENT_INSTRUMENT_FORM, vault);
    const { data } = await axios.post(url, {
      vaultData,
    });
    return data;
  });
}

export default addNewPaymentInstrument;
