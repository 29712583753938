import { defineMessages } from 'react-intl';

export default defineMessages({
  label: {
    id: 'app.paymentMethod.inputs.cardSecurityCodeInput.label',
    defaultMessage: 'Cód. de seguridad',
  },
  errorMessage: {
    id: 'app.paymentMethod.inputs.cardSecurityCodeInput.errorMessage',
    defaultMessage: 'El código de seguridad no es válido',
  },
});
