import React from 'react';
import styled from 'styled-components';
import deviceError from '../../assets/images/device_error.svg';
import { Image } from '@appscore/web-components';

const StyledErrorContainer = styled.div`
  display: flex;
`;

function DeviceErrorIcon() {
  return (
    <StyledErrorContainer>
      <Image src={deviceError} alt="device error" />
    </StyledErrorContainer>
  );
}

export default DeviceErrorIcon;
