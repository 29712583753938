import React, { useState } from 'react';
import styled from 'styled-components';
import { camelToKebab } from '@pedidosya/web-fenix/utils/tokens';
import Image from '@pedidosya/web-fenix/foundations/Image';

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background: ${({ theme, backgroundColor }) => theme.color(backgroundColor)};
`;

const BrandedInfo = styled.div`
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.space('spacing-component-small')};
  width: 100%;
  padding: ${({ theme }) =>
    `${theme.space('spacing-component-medium')} ${theme.space('spacing-component-large')}`};
`;

export const ImageWithFallback = ({ image, defaultHeight }) => {
  const [showImage, setShowImage] = useState(true);
  const [height, setHeight] = useState(defaultHeight ?? 24);

  const handleImageLoad = (event) => {
    const { naturalWidth, naturalHeight } = event.target;
    if (!defaultHeight && naturalHeight >= naturalWidth) setHeight(32);
  };

  const onImgError = (event) => {
    setShowImage(false);
  };

  if (image && showImage)
    return (
      <Image
        src={image.url}
        alt={image.accessibilityHint}
        height={height}
        onError={onImgError}
        onLoad={handleImageLoad}
      />
    );

  return null;
};

export const BrandedInstrumentContainer = ({ backgroundColor, children }) => {
  return (
    <Container
      data-testid="branded-instrument-container"
      backgroundColor={camelToKebab(backgroundColor)}
    >
      <BrandedInfo>{children}</BrandedInfo>
    </Container>
  );
};

const BrandedInstrument = ({ backgroundColor, image }) => {
  return (
    <BrandedInstrumentContainer backgroundColor={backgroundColor}>
      <ImageWithFallback image={image} />
    </BrandedInstrumentContainer>
  );
};

export default BrandedInstrument;
