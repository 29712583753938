import { css } from 'styled-components';

const NAME = {
  MAIN: 'Segma',
  SECONDARY: 'Segma',
};

const SIZE = {
  XXX_SMALL: 10,
  XX_SMALL: 11,
  X_SMALL: 12,
  SMALL: 14,
  MEDIUM: 16,
  LARGE: 18,
  X_LARGE: 20,
  XX_LARGE: 24,
};

const WEIGHT = {
  REGULAR: 400,
  EXTRA_REGULAR: 500,
  SEMI_BOLD: 600,
  BOLD: 700,
  EXTRA_BOLD: 800,
};

const buildFont = (family, size, weight) => css`
  font-family: ${family};
  font-size: ${size}px;
  font-weight: ${weight};
`;

export const paymentsTheme = {
  payments: {
    fontFamily: {
      xxxSmallExtraRegular: buildFont(NAME.MAIN, SIZE.XXX_SMALL, WEIGHT.EXTRA_REGULAR),
      largeExtraRegular: buildFont(NAME.MAIN, SIZE.LARGE, WEIGHT.EXTRA_REGULAR),
    },
  },
};
