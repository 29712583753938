import { useEffect } from 'react';
import logger from '@app/logger';
import { publishAndroid } from '@app/utils/newCardAnimatedFlowEvents';

export const useDispatchDeviceEvents = () => {
  useEffect(() => {
    logger.debug('PUBLISH_SHOW_KEYBOARD_EVENT_ANDROID');
    publishAndroid();
  }, []);
};
