export const BOOLEAN_FLAGS = {
  WEB_CDE_PE_NEW_FLOW_REDIRECT: {
    KEY: 'web-cde-pe-new-flow-redirect',
  },
};

const FLAGS_KEYS = Object.values(BOOLEAN_FLAGS).map((x) => x.KEY);

export async function initializeInstance(fwfPromise, userContext) {
  const fwf = await fwfPromise;

  if (!fwf) return null;

  await fwf.setContext(userContext);
  await fwf.subscribeFeatures(FLAGS_KEYS);

  return fwf;
}
