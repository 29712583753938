import { getPlainNumber } from '../../../../../../../../utils/number';
import luhnCheck from '../luhnCheck';

/**
 * Validates input parameter against luhn algorithm.
 * Non-numeric characters will be ignored.
 *
 * @param {string | undefined | null} input Alphanumeric and non-alphanumeric characters.
 * @param {Number[]} cardNumberFormat List of numbers describing the amount of digits of each chunk
 * @param {Boolean} shouldDoLuhnValidation boolean to determine if Luhn check must be executed
 * @returns {boolean} true or false whether the input parameter is a valid card.
 */
function isCardNumberValid(input, cardNumberFormat, shouldDoLuhnValidation) {
  const value = getPlainNumber(input);
  const luhnIsOk = shouldDoLuhnValidation ? luhnCheck(value) : true;

  return (
    value.length >= 13 &&
    value.length <= 19 &&
    luhnIsOk &&
    (!cardNumberFormat || value.length === cardNumberFormat.reduce((a, b) => a + b, 0))
  );
}

export default isCardNumberValid;
