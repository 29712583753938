import { useCallback } from 'react';
import { addQueryParamsToUrlString } from '@app/utils/queryString';

export const useHardNavigate = () => {
  const hardNavigate = useCallback((url, queryParams, { replace = false } = {}) => {
    const urlWithParms = addQueryParamsToUrlString(url, queryParams);

    if (replace) {
      window?.location?.replace(urlWithParms);
    } else {
      window?.location?.assign(urlWithParms);
    }
  }, []);

  return hardNavigate;
};
