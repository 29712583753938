/* eslint-disable no-console */
import { useEffect, useMemo, useState } from 'react';

export const noop = { track: (...args) => console.log('No tracker implementation', ...args) };

export const useTrackerLoader = ({ getTracker, origin }) => {
  const [tracker, setTracker] = useState(null);

  const setNoop = () => setTracker(noop);

  useEffect(() => {
    getTracker().then(setTracker).catch(setNoop);
  }, [getTracker]);

  const value = useMemo(() => ({ origin, tracker }), [origin, tracker]);

  return value;
};
