import getAxiosInstance from './axios';
import { URLS } from './constants';
import serviceErrorWrapper from './serviceErrorWrapper';

async function getContextForPMCard(axios = getAxiosInstance()) {
  return serviceErrorWrapper('getContextForPMCard', async () => {
    const { data } = await axios.get(URLS.PAYMENT_METHODS_CARD_CONTEXT);
    return data;
  });
}

export default getContextForPMCard;
