import getAxiosInstance from '../axios';
import { URLS } from '../constants';
import serviceErrorWrapper from '../serviceErrorWrapper';

async function validateHTML(url, axios = getAxiosInstance()) {
  return serviceErrorWrapper('validateHTML', async () => {
    if (!url) {
      throw new Error(`invalid URL for HTML fetching: ${url}`);
    }
    const { data } = await axios.get(URLS.VALIDATE_HTML, {
      params: {
        url,
      },
    });

    return data;
  });
}

export default validateHTML;
