import { useAppState } from '../providers/mainContext/context';
import {
  TEST_ENVIRONMENT,
  DEV_ENVIRONMENT,
  STG_ENVIRONMENT,
  PROD_ENVIRONMENT,
  LOCAL_ENVIRONMENT,
} from '../../commons/constants/environments';

let ENV;

export function setEnvironment(env) {
  ENV = env;
}

export function getEnvironment() {
  return ENV || 'unknown';
}

export const isLocal = () => getEnvironment() === LOCAL_ENVIRONMENT;
export const isDevelopment = () => getEnvironment() === DEV_ENVIRONMENT;
export const isStaging = () => getEnvironment() === STG_ENVIRONMENT;
export const isProduction = () => getEnvironment() === PROD_ENVIRONMENT;
export const isTest = () => getEnvironment() === TEST_ENVIRONMENT;

export const useEnvironment = () => {
  const [{ context }] = useAppState();
  return context.environment;
};

export const useIsLocal = () => useEnvironment() === LOCAL_ENVIRONMENT;
export const useIisDevelopment = () => useEnvironment() === DEV_ENVIRONMENT;
export const useIsStaging = () => useEnvironment() === STG_ENVIRONMENT;
export const useIsProduction = () => useEnvironment() === PROD_ENVIRONMENT;
export const useIsTest = () => useEnvironment() === TEST_ENVIRONMENT;
