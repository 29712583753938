import { URLS } from './constants';
import getAxiosInstance from './axios';
import serviceErrorWrapper from './serviceErrorWrapper';

async function getUserInstruments(axios = getAxiosInstance()) {
  return serviceErrorWrapper('getUserInstruments', async () => {
    const { data } = await axios.get(URLS.PAYMENT_INSTRUMENTS);
    return data;
  });
}

export default getUserInstruments;
