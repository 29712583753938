export const KEYS = {
  SUCCESS_CALLBACK: 'PAYMENT_METHODS_SUCCESS_CALLBACK',
  CANCEL_CALLBACK: 'PAYMENT_METHODS_CANCEL_CALLBACK',
  ENTRY_POINT_PAGE: 'PAYMENT_METHODS_ENTRY_POINT_PAGE',
  SELECTED_INSTRUMENTS: 'PAYMENT_METHODS_SELECTED_INSTRUMENTS',
  CHALLENGE: 'PAYMENT_METHODS_CHALLENGE',
  SECURITY_CODE: 'PAYMENT_METHODS_SECURITY_CODE',
};

export const TTLS = {
  WITHOUT: -1,
};
