import { isLocal } from '@app/utils/environment';

let BASENAME_PREFIX;
const PAYMENT_METHODS_BASENAME = '/payment-methods-mfe';

export const getBasename = () => {
  return BASENAME_PREFIX
    ? `${BASENAME_PREFIX}${PAYMENT_METHODS_BASENAME}`
    : PAYMENT_METHODS_BASENAME;
};

export const setBasenamePrefix = (appShellBasePath) => {
  BASENAME_PREFIX = appShellBasePath;
};

/**
 *
 * @param {String} route from the ROUTES object
 * @param {Object} pathParams key-value pairs to format the route
 * with the path params and the corresponding values
 * @returns {String} formatted route with params injected
 */
export const formatRouteWithParams = (route, pathParams = {}) =>
  Object.keys(pathParams).reduce(
    (currentRoute, currentParamKey) =>
      currentRoute.replace(`:${currentParamKey}`, pathParams[currentParamKey]),
    route,
  );

/**
 * ROUTES identifies the react-router partial (without
 * basename) routes of the pages
 */
export const ROUTES = {
  USER_PAYMENT_INSTRUMENTS: '/user-instruments',
  DISABLED_USER_INSTRUMENTS: '/user-instruments/disabled',
  CHANGE_USER_PAYMENT_INSTRUMENT: '/user-instruments/change',
  NEW_PAYMENT_METHOD_SELECTION: '/new',
  NEW_CARD: '/new/card',
  NEW_CARD_EXTERNAL_FORM: '/new/external-form',
  NEW_CARD_EXTERNAL_FORM_SUCCESS_CALLBACK: '/new/external-form/success-callback',
  NEW_CARD_LEGACY: '/new/card/:vaultProvider/legacy',
  EXTERNAL_PROVIDER: '/external-providers',
  CALLBACK_EXTERNAL_PROVIDER: '/callback-external-providers',
  CASH_PAYMENT_NEED_CHANGE: '/user-instruments/need-change',
  CHALLENGES: '/challenges',
  TIGO_OTP_CHALLENGE: '/challenges/tigo-otp',
  BUY_NOW_PAY_LATER_CHALLENGE: '/challenges/bnpl',
  TERMS_AND_CONDITIONS_DETAIL: '/terms-and-conditions',
  CVV_EXTERNAL: '/challenges/cvv-external',
  CVV_EXTERNAL_SUCCESS: '/challenges/cvv-external/success',
  CHIN_CHIN_CHALLENGE: '/challenges/chin-chin',
  CHIN_CHIN_CHALLENGE_SUCCESS: '/challenges/chin-chin/success',
  PROVIDER_CHALLENGE: '/challenges/provider',
  PROVIDER_CHALLENGE_CALLBACK: '/challenges/callbacks/:provider',
  CHALLENGE_ERROR: '/challenges-error',
  // Callbacks defined for webview
  PAYMENT_INSTRUMENT_SELECTION_SUCCESS: '/instrument-selection-success',
  PAYMENT_INSTRUMENT_SELECTION_CANCEL: '/instrument-selection-cancel',
  CHALLENGE_SUCCESS: '/challenges-success',
  CHALLENGE_CANCEL: '/challenges-cancel',

  NOT_FOUND: '/not-found',
};

/** URIS_FRONT identifies the full resource identifier for the pages
 * including the full basename and uses placeholders for path-params
 * in order to be used with format() utility
 * NOTE: URIS_FRONT and ROUTES keys are expected to be the same
 */
export const URIS_FRONT = {
  get USER_PAYMENT_INSTRUMENTS_LEGACY() {
    return `${getBasename()}/`;
  },
  get USER_PAYMENT_INSTRUMENTS() {
    return `${getBasename()}/user-instruments`;
  },
  get NEW_PAYMENT_METHOD_SELECTION() {
    return `${getBasename()}/new`;
  },
  get NEW_CARD() {
    return `${getBasename()}/new/card`;
  },
  get NEW_CARD_LEGACY() {
    return `${getBasename()}/new/card/{0}/legacy`;
  },
  get NEW_CARD_EXTERNAL_FORM() {
    return `${getBasename()}/new/external-form`;
  },
  get NEW_CARD_EXTERNAL_FORM_SUCCESS_CALLBACK() {
    return `${getBasename()}/new/external-form/success-callback`;
  },
  get EXTERNAL_PROVIDER() {
    return `${getBasename()}/external-providers`;
  },
  get CALLBACK_EXTERNAL_PROVIDER() {
    return `${getBasename()}/callback-external-providers`;
  },
  get CASH_PAYMENT_NEED_CHANGE() {
    return `${getBasename()}/user-instruments/need-change`;
  },
  get CHALLENGES() {
    return `${getBasename()}/challenges`;
  },
  get TIGO_OTP_CHALLENGE() {
    return `${getBasename()}/challenges/tigo-otp`;
  },
  get BUY_NOW_PAY_LATER_CHALLENGE() {
    return `${getBasename()}/challenges/bnpl`;
  },
  get CVV_EXTERNAL_SUCCESS() {
    return `${getBasename()}/challenges/cvv-external/success`;
  },
  get NOT_FOUND() {
    return `${getBasename()}/not-found`;
  },
  get TERMS_AND_CONDITIONS_DETAIL() {
    return `${getBasename()}/terms-and-conditions`;
  },
};

const PCI_BASENAME = '/cde/payment-method-card';
const NEW_PCI_BASENAME = '/cde-pe/cde-payment-experience';

export const PCI_PATHS = {
  NEW_CARD_FORM: `${PCI_BASENAME}/new/card`,
  PROVIDER_CHALLENGE: `${PCI_BASENAME}/provider-challenge`,
};

export const NEW_PCI_PATHS = {
  SECURITY_CODE: `${NEW_PCI_BASENAME}/security-code`,
  PROVIDER_CHALLENGE: `${NEW_PCI_BASENAME}/provider-challenge`,
};

export const isValidOriginForPCIMessage = (origin) => {
  if (isLocal()) return true;
  return origin === window.location.origin;
};
