import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Image } from '@appscore/web-components';

const PromoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const PromoIcon = styled.i`
  margin-right: ${({ theme }) => theme.spacing(4)};
`;

const PromoLabel = styled.span`
  ${({ theme }) => theme.fontFamily.secondaryFont.smallBold};
  color: ${({ theme }) => theme.colors.blue_darker_3};
  line-height: ${({ theme }) => theme.spacing(10)};
`;

const Promo = ({ icon, label }) => {
  return (
    <PromoContainer>
      <PromoIcon>
        <Image src={icon} alt="promo-icon" height={15} width={15} />
      </PromoIcon>
      <PromoLabel>{label}</PromoLabel>
    </PromoContainer>
  );
};

Promo.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default Promo;
