import BackendDrivenPage from '@app/components/BackendDrivenPage';
import { useBFFActionsExecutor } from '@checkout-ui/backend-driven';
import React from 'react';
import styled from 'styled-components';
import { BrandErrorContainer } from '../../components/Error';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
import { useGoBack } from '@app/hooks/useGoBack';
import { usePaymentMethods } from '@app/services/queries';
import PaymentMethodCard from './components/PaymentMethodCard';
import { useRedirectToPaymentMethod } from './hooks/useRedirectToPaymentMethod';

const Section = styled.section`
  padding-bottom: 16px;
`;

const StyledPaymentMethodCard = styled(PaymentMethodCard)`
  margin-bottom: 16px;
`;

const PaymentMethods = () => {
  const { executeBFFActions } = useBFFActionsExecutor();
  const { isLoading, error, data, refetch } = usePaymentMethods();
  const { goBack } = useGoBack();
  const { isCheckingMethods } = useRedirectToPaymentMethod(isLoading, error, data);

  if (isLoading || isCheckingMethods) return <PeyaLoader position="center" />;
  if (error) {
    return (
      <BrandErrorContainer
        fenixLayout
        onPrimaryAction={refetch}
        secondaryLabel="Volver"
        onSecondaryAction={() => goBack()}
        errorCode={error.response?.status}
      />
    );
  }

  const componentResolvers = {
    SECTION: () => ({
      component: Section,
    }),
    ACTION_ADD_PAYMENT_INSTRUMENT: ({
      data: { text, left_icon, description, enabled, actions },
    }) => ({
      component: StyledPaymentMethodCard,
      props: {
        text: text,
        leftIcon: left_icon,
        description,
        enabled,
        onClick: () => executeBFFActions(actions),
      },
    }),
  };

  const onAppearPageActions = data?.on_appear?.actions;
  const header = data?.bar;
  const components = data?.components;

  return (
    <>
      <BackendDrivenPage
        fenixLayout={false}
        componentResolvers={componentResolvers}
        header={header}
        components={components}
        onAppearActions={onAppearPageActions}
      />
    </>
  );
};

export default PaymentMethods;
