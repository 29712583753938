import { useExternalRouterNavigation } from '@checkout-ui/backend-driven';
import { useCallback } from 'react';
import { useIsWebview } from './useDeviceInfo';
import { useHardNavigate } from './useHardNavigate';

// If consumer is native app, we need to hard navigate for the app to detect the callback
export const useCallbackNavigation = () => {
  const { externalRouterNavigation } = useExternalRouterNavigation();
  const hardNavigate = useHardNavigate();
  const isWebview = useIsWebview();

  const callbackNavigate = useCallback(
    (url, queryParams, replace) => {
      // Some versions send an incorrect callback
      if (isWebview || replace) hardNavigate(url, queryParams, { replace });
      else externalRouterNavigation(url, { queryParams });
    },
    [isWebview, hardNavigate, externalRouterNavigation],
  );

  return { callbackNavigate };
};
