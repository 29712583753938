import { SimpleButton } from '@appscore/web-components';
import { useBFFActionsExecutor } from '@checkout-ui/backend-driven';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

const StyledSimpleButton = styled(SimpleButton)`
  padding: ${({ theme }) => `${theme.spacing(4)} ${theme.spacing(8)}`};
`;

const ActionContainer = ({ actionButton }) => {
  const { executeBFFActions } = useBFFActionsExecutor();
  const { style, text, actions } = actionButton;

  return (
    <Container>
      <StyledSimpleButton
        size={style === 'PRIMARY' ? 'full' : 'medium'}
        label={text}
        color={style?.toLowerCase()}
        onClick={() => executeBFFActions(actions)}
      />
    </Container>
  );
};

ActionContainer.propTypes = {
  actionButton: PropTypes.object,
};
export default ActionContainer;
