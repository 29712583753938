import React from 'react';
import FenixIconWrapper from './FenixIconWrapper';
import { Text } from './utils';
import { BrandedInstrumentContainer, ImageWithFallback } from './BrandedInstrument';

const ImageWithPaymentMethodName = ({ image, paymentMethodName }) => {
  return (
    <>
      <ImageWithFallback image={image} defaultHeight={24} />
      <Text label={paymentMethodName} />
    </>
  );
};

const IconWithPaymentMethodName = ({ icon, paymentMethodName }) => {
  return (
    <>
      <FenixIconWrapper {...icon} />
      <Text label={paymentMethodName} />
    </>
  );
};

const MultiBrandedInstrument = ({ backgroundColor, ...props }) => {
  const { image, paymentMethodName } = props;
  let Component = null;

  if (image && paymentMethodName) {
    Component = ImageWithPaymentMethodName;
  } else Component = IconWithPaymentMethodName;

  return (
    <BrandedInstrumentContainer backgroundColor={backgroundColor}>
      <Component {...props} />
    </BrandedInstrumentContainer>
  );
};

export default MultiBrandedInstrument;
