import axios from 'axios';
import axiosRetry from 'axios-retry';
import { getQueryParam } from '../utils/domRouter';
import { QUERY_PARAM } from '../../commons/constants';
import { headerKeys } from '@commons/utils/mocks/msw/constants';
// import isRetryAllowed from 'is-retry-allowed'; // Throw ERR_REQUIRE_ESM error

let instance = null;

function isNetworkError(error) {
  return (
    !error.response &&
    Boolean(error.code) && // Prevents retrying cancelled requests
    error.code !== 'ECONNABORTED' // Prevents retrying timed out requests
    // isRetryAllowed(error) // Prevents retrying unsafe errors
  );
}

function errorResponseHandler(error) {
  try {
    const { message } = error;
    const data = error.response?.data || {};
    const url = error.config?.url;
    const status = error.response?.status || 'unknown';

    if (isNetworkError(error)) {
      return Promise.reject(error);
    }

    const dataToLog = JSON.stringify({
      status,
      url,
      message,
      data,
    });

    // console.log(`[AXIOS_ERROR_INTERCEPTOR] ${dataToLog}`);

    return Promise.reject(error);
  } catch {
    return Promise.reject(error);
  }
}

const errorInterceptor = (axiosInstance) => {
  axiosInstance.interceptors.response.use((response) => response, errorResponseHandler);
};

const retryInterceptor = (axiosInstance) =>
  axiosRetry(axiosInstance, {
    retries: 1,
    // retryCondition: By default, it retries if it is a network error or a 5xx error on an idempotent request (GET, HEAD, OPTIONS, PUT or DELETE).
    retryDelay: axiosRetry.exponentialDelay,
  });

const micrositeContextIdInterceptor = (axiosInstance) => {
  const checkoutId = getQueryParam(window?.location, QUERY_PARAM.CHECKOUT_ID);
  const purchaseId = getQueryParam(window?.location, QUERY_PARAM.PURCHASE_ID);
  const contextId = getQueryParam(window?.location, QUERY_PARAM.BUSSINES_CONTEXT);

  const bussinessKey = purchaseId
    ? 'purchaseId'
    : checkoutId
    ? 'checkoutId'
    : contextId
    ? 'contextId'
    : undefined;

  const bussinessValue = purchaseId || checkoutId || contextId || undefined;

  axiosInstance.interceptors.request.use((config) => {
    if (bussinessKey) {
      config.params = {
        ...config.params,
        [bussinessKey]: bussinessValue,
      };
    }
    return config;
  });
};

const mockHeaderInterceptor = (axiosInstance) => {
  const mockHeaderMSW = getQueryParam(window?.location, QUERY_PARAM.MOCK_HEADER_MSW);
  if (!mockHeaderMSW) return;
  const mockHeadersParsed = JSON.parse(decodeURIComponent(mockHeaderMSW));

  const possibleMockHeaders = Object.values(headerKeys);
  const mockHeadersFiltered = Object.keys(mockHeadersParsed)
    .filter((mockHeader) => possibleMockHeaders.includes(mockHeader))
    .reduce((cur, key) => Object.assign(cur, { [key]: mockHeadersParsed[key] }), {});

  axiosInstance.defaults.headers.common = {
    ...mockHeadersFiltered,
    ...axiosInstance.defaults.headers.common,
  };
};

export const exportedForTesting = {
  mockHeaderInterceptor,
};

export function initialize(props) {
  instance = (props && axios.create(props)) || axios.create();
  micrositeContextIdInterceptor(instance);
  retryInterceptor(instance);
  errorInterceptor(instance);
  mockHeaderInterceptor(instance);

  return instance;
}

const getInstance = (props) => instance || initialize(props);

export default getInstance;
