import useOnce from '@app/hooks/useOnce';
import logger from '@app/logger';
import { useWebpayHandler } from './useWebpayHandler';
import { useIntl } from 'react-intl';
import messages from '../messages';
import { PROVIDERS } from '../constants';
import { useBruceHandler } from './useBruceHandler';

export const useProviderHandlerCallback = (provider, { onSuccess, onError, onCancel }) => {
  const { formatMessage } = useIntl();
  const { webpayHandler } = useWebpayHandler();
  const { bruceHandler } = useBruceHandler();

  const providerHandlers = {
    [PROVIDERS.WEBPAY]: webpayHandler,
    [PROVIDERS.BRUCE]: bruceHandler,
  };

  useOnce(() => {
    const providerHandler = providerHandlers[provider];
    if (!providerHandler) {
      logger.error(`[useProviderHandlerCallback][${provider}][UNKNOWN_PROVIDER]`);
      onError(formatMessage(messages.unknownProviderMessage));
      return;
    }
    providerHandler(onSuccess, onError, onCancel);
  });
};
