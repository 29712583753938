import format from '../../commons/utils/string/format';
import { URLS } from './constants';
import getAxiosInstance from './axios';
import serviceErrorWrapper from './serviceErrorWrapper';

async function verifyPaymentInstrument(vault, bin, axios = getAxiosInstance()) {
  return serviceErrorWrapper('verifyPaymentInstrument', async () => {
    const { data } = await axios.post(format(URLS.VERIFY_PAYMENT_INSTRUMENT, vault), {
      bin,
    });
    return data;
  });
}

export default verifyPaymentInstrument;
