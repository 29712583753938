import getAxiosInstance from './axios';
import format from '../../commons/utils/string/format';
import { URLS } from './constants';
import serviceErrorWrapper from './serviceErrorWrapper';

async function getChallengeScreen(challengeType, challengeId, axios = getAxiosInstance()) {
  return serviceErrorWrapper('getChallengeScreen', async () => {
    const url = format(URLS.CHALLENGE_SCREEN, challengeType, challengeId);
    const { data } = await axios.get(url);
    return data;
  });
}

export default getChallengeScreen;
