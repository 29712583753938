import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { InputText } from '@appscore/web-components';
import messages from './messages';
import formatCardHolder from '../utils/formatters/cardHolder';

const CardHolderNameInput = ({ value, onChange, error = false, disabled = false, ...rest }) => {
  const intl = useIntl();
  const isValid = () => {
    if (!value) return true;
    return !error;
  };

  const handleChange = (event) => {
    const inputValue = event?.target?.value;
    const newValue = formatCardHolder(inputValue);
    onChange(newValue);
  };

  const { formatMessage } = intl;

  const errorMessage = isValid() ? '' : formatMessage(messages.errorMessage);
  return (
    <InputText
      {...rest}
      type="text"
      label={formatMessage(messages.label)}
      onChange={handleChange}
      value={value}
      helpText={errorMessage}
      error={Boolean(errorMessage)}
      disabled={disabled}
      inputProps={{
        'data-testid': rest?.name || 'card-holder-name-input',
      }}
    />
  );
};

CardHolderNameInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default CardHolderNameInput;
