import { defineMessages } from 'react-intl';

export default defineMessages({
  webpayTimeoutFlowMessage: {
    id: 'app.providerChallengeCallback.webpay.timeoutFlowMessage',
    defaultMessage: 'No se pudo completar la orden debido al tiempo expirado',
  },
  webpayErrorFlowMessage: {
    id: 'app.providerChallengeCallback.webpay.errorFlowMessage',
    defaultMessage: 'No se pudo completar la orden',
  },
  webpayUnknownFlowMessage: {
    id: 'app.providerChallengeCallback.webpay.unknownFlowMessage',
    defaultMessage: 'No se pudo completar la orden',
  },
  bruceFailureFlowMessage: {
    id: 'app.providerChallengeCallback.bruce.failureFlowMessage',
    defaultMessage: 'No se pudo completar la orden',
  },
  bruceUnknownFlowMessage: {
    id: 'app.providerChallengeCallback.bruce.unknownFlowMessage',
    defaultMessage: 'No se pudo completar la orden',
  },
  unknownProviderMessage: {
    id: 'app.providerChallengeCallback.unknownProviderMessage',
    defaultMessage: 'No se pudo completar la orden',
  },
});
