import { useEffect } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';
import { updateEntryPointPage } from '../providers/mainContext/actions/updateEntryPointPage';
import { useAppDispatch } from '../providers/mainContext/selectors/useAppDispatch';
import { KEYS as CACHE_KEYS, sessionCache } from '../services/cache';

/**
 * This hook updates the cache with the route of the first page loaded by this microsite if:
 *    1. There's nothing in the cache (avoid updating this value on reload)
 *    2. The last navigation was done with "replace" and is the only page in the history
 *        * This happens when the entry point page automatically redirects the user
 */
export const useEntryPointPageUpdate = () => {
  const navigationType = useNavigationType();
  const { pathname: entryPointPageFromRoute } = useLocation();
  const entryPointPageFromCache = sessionCache.get(CACHE_KEYS.ENTRY_POINT_PAGE);
  const dispatch = useAppDispatch();
  const history = window?.history;
  const isReplaceAndEntryPoint = navigationType === 'REPLACE' && history.length === 1;

  useEffect(() => {
    if (entryPointPageFromRoute && (!entryPointPageFromCache || isReplaceAndEntryPoint)) {
      updateEntryPointPage(dispatch, entryPointPageFromRoute);
    }
  }, [dispatch, entryPointPageFromCache, entryPointPageFromRoute, isReplaceAndEntryPoint]);
};
