import format from '@commons/utils/string/format';
import getAxiosInstance from './axios';
import { URLS } from './constants';
import serviceErrorWrapper from './serviceErrorWrapper';

async function getPayloadForNewInstrument(requestId, axios = getAxiosInstance()) {
  return serviceErrorWrapper('getPayloadForNewInstrument', async () => {
    const url = format(URLS.PAYLOAD_FOR_NEW_INSTRUMENT, requestId);
    const { data } = await axios.get(url);
    return data;
  });
}

export default getPayloadForNewInstrument;
