import { getPlainNumber } from '../../../../../../../../utils/number';

/**
 * Applies a proper format to the input based on the configured cards.
 * If no card matches the input parameter, a default format is applied: **** **** **** ****.
 * Non-numeric characters will be ignored.
 *
 * @param {string | undefined | null} cardNumber Alphanumeric and non-alphanumeric characters.
 * @param {Array(Number)} cardFormat Steps to format the card number
 * @returns {string} A spaced string following the correct format.
 */
export default function formatCardNumber(cardNumber, cardFormat = [4, 4, 4, 4]) {
  let value = getPlainNumber(cardNumber);
  let result = '';

  for (let i = 0; i < cardFormat.length && value.length > 0; i += 1) {
    const section = value.substr(0, cardFormat[i]);
    result += `${section} `;
    value = value.replace(section, '');
  }

  return result.trim();
}
