import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '@appscore/web-components';
import styled from 'styled-components';

const CARD_IMAGE_WIDTH_PX = 32;
const CARD_IMAGE_HEIGHT_PX = 20;

const Container = styled.div`
  padding: ${({ theme }) => `${theme.spacing(8)} 0`};
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(4)};
`;

const CardLogo = styled(Image)`
  border-radius: 6px;
  border: ${({ theme }) => `1px solid ${theme.colors.platinum}`};
  opacity: ${({ isHighlighted }) => (isHighlighted ? 1 : 0.5)};
`;

function SupportedCards({ cards, selectedCard = null }) {
  return (
    <Container>
      {cards?.map((card) => (
        <CardLogo
          key={card.brand}
          alt={card.brand}
          src={card.icon}
          height={CARD_IMAGE_HEIGHT_PX}
          width={CARD_IMAGE_WIDTH_PX}
          isHighlighted={!selectedCard || selectedCard?.brand === card.brand}
        />
      ))}
    </Container>
  );
}

SupportedCards.propTypes = {
  cards: PropTypes.array,
  selectedCard: PropTypes.shape({
    brand: PropTypes.string,
  }),
};

export default SupportedCards;
